import React, { Component } from "react";
import {
  NavBar,
    Icon,
    InputItem,
    List,
    Picker,
    Button,
    WhiteSpace,
    WingBlank,
    Checkbox,
    DatePickerView,
    Modal,
    TextareaItem,
    Toast,
    Accordion,
    Badge,
    ActionSheet
} from "antd-mobile";
import { createForm } from "rc-form";
import moment from "moment";
import "./userAdd.scss";
import axios from "../../../../service/axios";
import http from "../../../../utils/request";
import { connect } from "dva";
import UserSelectGroups from "./userSelectGroup";
import DefaultGroup from "./defaultGroup";
const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;
const selectedContainer = {
  position: "fixed",
  width: "100%",
  top: "0px",
  background: "#f5f5f5",
  height: "100%",
  zIndex: "11",
  transition: "left 0.4s"
};
const GpsInterval = [
  {
    label: <div key="1">0s</div>,
    value: 0
  },
  {
    label: <div key="2">1分钟</div>,
    value: 60
  },
  {
    label: <div key="3">5分钟</div>,
    value: 300
  },
  {
    label: <div key="4">10分钟</div>,
    value: 600
  }
];
const userMsg = 
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg"));

export class newAddUser extends Component {
  state = {
    userInfo: {
      gpsInterval: 0,
      deviceInfo:{
        iccid: '',
        imei: '',
        version: ''
      },
    },
    type: this.props.match.params.type,
    billList: [],
    voice: true, //会话权限
    switchs: true, //切组
    single: true, //单呼
    groupSingle: true, //跨组单呼
    drawerLeft: document.body.clientWidth,
    drawerLeftDefault: document.body.clientWidth,
    showDivDefaultGroup: "block",
    funcsList: [],
    funcValueList: [0,1,3,9],
    preValidateMonth: -1,
    funcs2: [],
    renewType: 0,
    funcGidArr: [],
    isOne: true,
    isMore: false,
    hideDeleteBtn: true
  };
  async componentDidMount() {
    console.log(this.props);
    this.props.emptyGroups();
    await this.props.getFuncList();
    Toast.loading("加载数据中...", 0);
    console.log('type!',this.props.match.params.type)
    if (this.props.match.params.type == "edit") {
      await this.getUserIsIngroup()
      await this.getUserInfo()
    } else {
      this.getPreValidateMonth()
    }
  }
  //获取上级管理员的时间
  getPreValidateMonth() {
    return new Promise((resolve, reject) => {
      http.get("/api/user/admin/preferentialMonths/get",{},'new').then(res => {
        if (res.status == 200 && res.data) {
          if (res.data >= 0) {
            this.setState({
              preValidateMonth: res.data,
            });
          }
        }
      });
    });
  }
  //确定提交
  submitBtn() {
    this.props.form.validateFields({ force: true }, error => {
      if (!error) {
        const fildsValue = this.props.form.getFieldsValue();
        const preferentialMonths = this.state.preValidateMonth;
        const { validMonths } = fildsValue
        // 创建的账号类型    0 为三年账号, 3为一年账号
        let newUserTyep = 3
        if(this.state.isMore){
          newUserTyep = 0
        }
        let params = {
          ...fildsValue,
          defaultGid: this.props.defaultGroup.gid,
          joinGids: this.props.groupsGidArr,
          gpsInterval: fildsValue.gpsInterval[0],
          remarks: fildsValue.remarks || "",
          name: fildsValue.name || "",
          imei: fildsValue.imei || "",
          uid: this.props.match.params.id,
          validMonths: preferentialMonths > 0 ? preferentialMonths : validMonths,
          funcValueList: this.state.funcValueList,
          type: newUserTyep
        };
        
        Toast.loading("加载中...", 0);
        let type = this.props.match.params.type == "edit" ? "update2" : "add2";
        console.log('提交参数~', params,'类型: ', type)
        http.post("/api/user/ptt/" + type, {
          ...fildsValue,
          defaultGid: this.props.defaultGroup.gid,
          joinGids: this.props.groupsGidArr,
          gpsInterval: fildsValue.gpsInterval[0],
          remarks: fildsValue.remarks || "",
          name: fildsValue.name || "",
          imei: fildsValue.imei || "",
          uid: this.props.match.params.id,
          validMonths: preferentialMonths > 0 ? preferentialMonths : validMonths,
          funcValueList: this.state.funcValueList,
          type: newUserTyep,
        },'new').then(res => {
          console.log(res);
          if (res) {
            if (res.status == 200) {
              this.props.emptyGroups();
              Toast.success(res.message, 2, () => {
                  window.history.go(-1);
              });
            } else if (res.status == 392) {
              Toast.fail("虚拟币不足");
            }
            else {
              Toast.fail(res.message);
            }
          }
        })
      }
    })
  }
  //权限转换
  funcsListExchange() {
    const { voice, switchs, single, groupSingle } = this.state;
    let str = "";
    if (voice) {
      str = "0";
    }
    if (switchs) {
      str += ",1";
    }
    if (single) {
      str += ",3";
    }
    if (groupSingle) {
      str += ",9";
    }
    return str;
  }
  //获取用户所属群组
  getUserIsIngroup() {
    return new Promise(rev => {
      http.post("/api/user/ptt/groups", {
          uid: this.props.match.params.id,
          page: {pageNo: 1, pageSize: 2500}
        },'new')
        .then(res => {
          rev()
          if (res && res.status == 200 && res.data) {
            this.props.setGroups(res.data.resultList);
          }
        }).catch(()=>{
          Toast.hide()
        })
    })
  }
  //编辑用户时候获取用户的信息
  getUserInfo() {
    return new Promise(rev => {
      http.get("/api/user/ptt/find2/id", {
          uid: this.props.match.params.id
        },'new').then(res => {
          console.log(res);
          rev();
          if (res && res.status == 200 && res.data) {
            let data = {
              ...res.data,
              defaultGroup: {
                name: res.data.defaultGroupName,
                gid: res.data.defaultGid
              }
            };
            this.props.addDefaultGroup({
              name: res.data.defaultGroupName,
              gid: res.data.defaultGid
            });
            this.props.form.setFieldsValue({
              funcsList: data.funcsList,
            });
            //判断是否为1年折扣用户
            const isBefore = moment(data.validDate).isBefore(data.maxValidDate);
            this.setState({
              userInfo: data,
              voice: data.funcs && data.funcs.indexOf(0) > -1,
              switchs: data.funcs && data.funcs.indexOf(1) > -1,
              single: data.funcs && data.funcs.indexOf(2) > -1,
              groupSingle: data.funcs && data.funcs.indexOf(9) > -1,
              renewType: data.oldType == 6 && isBefore ? 1 : 0,
            });
            //折扣账号7天内可以删除
            if (userMsg && userMsg.permissionCodeList.includes(202)
              && !moment(data.createDate).isBefore(moment(new Date()).subtract('days', 7))) {
              this.setState({
                hideDeleteBtn: false,
              });
            }
            this.setState({
                funcValueList: data.funcValueList || [],
            });
            //录音定位套餐
            const funcs22 = [{ id: -1, code: 4, name: "录音", remarks: "录音记录保存15天" }, { id: -2, code: 101, name: "定位", remarks: "定位数据保存15天" }]
            this.setState({
                funcs2: funcs22.filter(i => data.includes(i.code)),
            })
          }
        }).catch(() => {
          Toast.hide();
        })
      http.get("/api/user/ptt/functions/get",{
        uid: this.props.match.params.id,
      },'new').then(res => {
        rev()
        if (res && res.status == 200 && res.data) {
          //录音定位套餐
          const funcs22 = [{ id: -1, code: 4, name: "录音", remarks: "录音记录保存15天" }, { id: -2, code: 101, name: "定位", remarks: "定位数据保存15天" }]
          this.setState({
              funcs2: funcs22.filter(i => res.data.includes(i.code)),
          })
        }
      }).catch(()=>{})
    })
  }

  // 删除事件
  deleteBtn() {
    const BUTTONS = ["确定", "取消"];
    ActionSheet.showActionSheetWithOptions(
        {
            options: BUTTONS,
            message: "是否删除选中的用户？",
            maskClosable: true,
            "data-seed": "logId",
        },
        async buttonIndex => {
            if (!buttonIndex) {
                this.deleteUser();
            }
        },
    );
  }

  //删除用户
  deleteUser() {
    http
        .post("/api/user/ptt/delete2", {
            uids: Array.of(this.props.match.params.id),
        },'new')
        .then(res => {
            console.log(res);
            if (res.status == 200) {
                Toast.success('删除成功', 2, () => {
                    // this.props.history.replace("/userlist");
                    window.history.go(-1);
                });
            } else {
                Toast.fail(res.message);
            }
        })
        .catch((err) => {
            Toast.fail(err);
        });
}
  onChangeCheckBox = (i, type) => {
    if (type == 1) {
      this.setState({
        voice: i.target.checked
      });
    } else if (type == 2) {
      this.setState({
        switchs: i.target.checked
      });
    } else if (type == 3) {
      this.setState({
        single: i.target.checked
      });
    } else {
      this.setState({
        groupSingle: i.target.checked
      });
    }
  };
  validateAccount = (rule, value, callback) => {
    if (value) {
      if (value.trim()) {
        if (!/^[0-9a-zA-z\*]+$/.test(value)) {
          callback(new Error("账号只能是字母,数字和 * 组合"));
        } else {
          if (value.length < 2) {
            callback(new Error("账号长度不能少于2"));
          } else {
            callback();
          }
        }
      } else {
        callback(new Error("请输入账号"));
      }
    } else {
      callback(new Error("请输入账号"));
    }
  };

  //验证账单号
  validateBillId = (rule, value, callback) => {
    console.log(value);
    if (value[0]) {
      callback();
    } else {
      callback(new Error("请选择账单号"));
    }
  };
  //验证密码
  validatePassword = (rule, value, callback) => {
    if (value) {
      if (
        /^[A-Za-z0-9]+$/gi.test(value) &&
        value.length <= 12 &&
        value.length >= 6
      ) {
        callback();
      } else {
        callback(new Error("请输入6-12位由数字和字母组成的密码"));
      }
    } else {
      callback(new Error("请输入密码"));
    }
  };
  //验证gps
  validateGpsinterval = (rule, value, callback) => {
    if (typeof value[0] == "number") {
      callback();
    } else {
      callback(new Error("请选择GPS上报周期"));
    }
  };
  validateInGroups = (rule, value, callback) => {
    if (this.props.groups.length < 1) {
      callback(new Error("请选择用户所在群组"));
    } else {
      callback();
    }
  };
  //验证账号数
  validateCreateNumber = (rule, value, callback) => {
    if (/^\d{1,3}$/.test(value) && value > 0 && value <= 500) {
      callback();
    } else {
      callback(new Error("请输入1~500的数字"));
    }
  };
  validateDefaultGid = (rule, value, callback) => {
    if (this.props.defaultGroup) {
      callback();
    } else {
      callback(new Error("请选择用户默认群组"));
    }
  };
  validateCheckboxItem = (rule, value, callback) => {
    const { voice, switchs, single, groupSingle } = this.state;
    if (!voice && !switchs && !single && !groupSingle) {
      callback(new Error("请至少勾选一个权限"));
    } else {
      callback();
    }
  };
  // 验证功能组
  validateFgidList = (rule, value, callback) => {
    if (this.props.match.params.type != "edit") {
        if (value) {
            if (value.length < 1) {
                callback(new Error("请选择功能组"));
            } else {
                callback();
            }
        } else {
            callback(new Error("请选择功能组"));
        }
    } else {
        callback();
    }
  }

  render() {
    const { getFieldProps, getFieldError, setFieldsValue} = this.props.form;
    const {
      type,
      billList,
      drawerLeft,
      drawerLeftDefault,
      userInfo,
      funcs2,
      renewType,
      isOne,
      isMore,
      hideDeleteBtn,
    } = this.state;
    const {
      form,
      groupsGidArr,
      groups,
      addDefaultGroup,
      defaultGroup,
      match,
      history,
      funcGroupList,
    } = this.props;
    const {
      account,
      name,
      password,
      imei,
      gpsInterval,
      billid,
      remarks,
      hasExpired,
      usrType,
      deviceInfo,
      funcsList
    } = this.state.userInfo;
    const userMsg =
      sessionStorage.getItem("loginMsg") &&
      JSON.parse(sessionStorage.getItem("loginMsg"));
    // 勾选功能组
    const onChange = (e, id, item) => {
      console.log(e.target.checked, id);
      let arr = this.state.funcGidArr;
      if (e.target.checked) {
        arr.push(item);
      } else {
        arr.splice(arr.map(item => item.id).indexOf(id), 1);
      }
      this.setState({
        checkedFuncGidArr: arr,
      }, () => { 
        sumTotalPrice();
      });
      setFieldsValue({
        funcsList: arr.map(item => item.id),
      });
    }
    // 统计
    const sumTotalPrice = () => {
      let months = form.getFieldValue('validMonths');
      let count = form.getFieldValue('count');
      months = months ? months : 0;
      const preferentialMonths = this.state.preValidateMonth;
      if (preferentialMonths > 0) {
          months = 36 / preferentialMonths;
      }
      count = count ? count : 1;
      let sumTotalPrice = 0;
      this.state.checkedFuncGidArr.filter(item => {
          sumTotalPrice += item.pttAmount;
      });
      sumTotalPrice *= months * count
      this.setState({
          totalPrice: sumTotalPrice.toString(),
      })
    }
    const checkOneUserType = () => {this.setState({ isOne: true, isMore: false})}
    const checkMoreUserType = () => {this.setState({ isOne: false, isMore: true})}
    return (
      <div className="adduser">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => history.goBack()}
          >
            {type == "edit" ? "修改用户" : "添加用户"}
          </NavBar>
        </div>
        <div className="sub-container">
          <form>
            <List
              renderFooter={() =>
                getFieldError("account") ||
                getFieldError("password") ||
                getFieldError("gpsInterval") ||
                getFieldError("count") ||
                getFieldError("in_groups") ||
                getFieldError("defaultGid") ||
                getFieldError("defaultCheckboxItem") ||
                getFieldError("funcsList")
              }
            >
              <InputItem
                {...getFieldProps("account", {
                  rules: [
                    {
                      validator: this.validateAccount
                    }
                  ],
                  initialValue: account
                })}
                clear
                placeholder="最长11位,支持*,字母,数字"
                key="2"
                maxLength="11"
                minLength="2"
              >
                <i className="red-star">*</i>
                账号
              </InputItem>

              <InputItem
                {...getFieldProps("name", {
                  initialValue: name
                })}
                clear
                placeholder="最长11位,支持*,字母,数字和中文"
                key="3"
                maxLength="11"
                minLength="2"
              >
                名字
              </InputItem>
              {/* <div className="am-list-item" style={{ color: '#6c6c6c', fontSize: '12px' }}>PS: 可用 * 代替序号位置进行批量创建</div> */}
              {this.props.match.params.type == "edit" && userMsg && userMsg.userType == 4 && !userMsg.permissionCodeList.includes(203) ? ("") : (
                <InputItem
                  {...getFieldProps("password", {
                    rules: [{ validator: this.validatePassword }],
                    initialValue: password
                  })}
                  clear
                  error={!!getFieldError("password")}
                  placeholder="6-12位数字、字母组合"
                  key="4"
                  maxLength="12"
                  type={type == "edit" ? "text" : "password"}
                >
                  <i className="red-star">*</i>
                  密码
              </InputItem>
              )}
              {this.props.match.params.type == "edit" ? (''):(
                <Item>
                  <CheckboxItem checked={isOne} onChange={checkOneUserType}>创建一年期账号</CheckboxItem>
                  <WingBlank></WingBlank>
                  <CheckboxItem checked={isMore} onChange={checkMoreUserType}>创建三年期账号</CheckboxItem>
                </Item>
              )}
              
              <InputItem
                {...getFieldProps("imei", {
                  initialValue: imei
                })}
                clear
                key="6"
                disabled={usrType == 3}
              >
                IMEI号
              </InputItem>
              <InputItem
                {...getFieldProps("iccid", {initialValue: deviceInfo.iccid})}
                clear
                maxLength={16}
                disabled
              >
                ICCID
              </InputItem>
              <Picker
                {...getFieldProps("gpsInterval", {
                  rules: [
                    {
                      validator: this.validateGpsinterval
                    }
                  ],
                  initialValue: [gpsInterval]
                })}
                data={GpsInterval}
                cols={1}
                key="7"
                error={!!getFieldError("gpsInterval")}
              >
                <Item key="1" arrow="horizontal">
                  <i className="red-star">*</i>
                  GPS周期
                </Item>
              </Picker>
              {this.props.match.params.type == "edit" ? (
                ""
              ) : (
                  <div>
                    <InputItem
                      {...getFieldProps("count", {
                        rules: [
                          {
                            validator: this.validateCreateNumber,
                          },
                        ],
                        initialValue: 1,
                      })}
                      clear
                      placeholder="用于批量添加，范围:1~500"
                      disabled={this.state.type == "edit"}
                      type="number"
                      max="500"
                      maxLength="3"
                    >
                      账号数
                  </InputItem>
                    <InputItem
                      {...getFieldProps("initNo", {
                      })}
                      key="8"
                      type="number"
                      maxLength="9"
                      clear
                    >
                      开始账号
                  </InputItem>
                  </div>
                )}

              <InputItem
                {...getFieldProps("in_groups", {
                  rules: [
                    {
                      validator: this.validateInGroups
                    }
                  ],
                  initialValue: groupsGidArr
                })}
                className="hide-input"
                value={groupsGidArr}
                arrow="horizontal"
                extra={
                  <span onClick={() => this.setState({ drawerLeft: 0 })}>
                    {groupsGidArr.length < 1
                      ? `点击选择群组`
                      : `已选择${groupsGidArr.length}个群组`}
                  </span>
                }
                key="15"
                onClick={() => this.setState({ drawerLeft: 0 })}
                editable="false"
              >
                <i className="red-star">*</i>
                所属群组
              </InputItem>
              <InputItem
                {...getFieldProps("defaultGid", {
                  rules: [
                    {
                      validator: this.validateDefaultGid
                    }
                  ],
                  initialValue: defaultGroup.gid
                })}
                className="hide-input"
                value={defaultGroup}
                arrow="horizontal"
                extra={
                  <div
                    style={{ padding: " 0 20px" }}
                    onClick={() => {
                      if (groupsGidArr.length < 1) {
                        Toast.info("请先选择所属群组", 1.5);
                      } else {
                        this.setState({
                          drawerLeftDefault: 0
                        });
                      }
                    }}
                  >
                    {defaultGroup && defaultGroup.name ? defaultGroup.name : `请选择默认群组`}
                  </div>
                }
                key="14"
                editable="false"
              >
                <i className="red-star">*</i>
                默认群组
              </InputItem>
              <TextareaItem
                {...getFieldProps("remarks", {
                  initialValue: remarks
                })}
                title="备注"
                placeholder=""
                autoHeight
                maxLength="100"
              />
              <List renderHeader="权限">
                <CheckboxItem
                  {...getFieldProps("defaultCheckboxItem", {
                    rules: [
                      {
                        validator: this.validateCheckboxItem
                      }
                    ]
                  })}
                  onChange={i => this.onChangeCheckBox(i, 1)}
                  checked={this.state.voice}
                >
                  会话
                </CheckboxItem>
                <CheckboxItem
                  onChange={i => this.onChangeCheckBox(i, 2)}
                  checked={this.state.switchs}
                >
                  切组
                </CheckboxItem>
                <CheckboxItem
                  onChange={i => this.onChangeCheckBox(i, 3)}
                  checked={this.state.single}
                >
                  单呼
                </CheckboxItem>
                <CheckboxItem
                  onChange={i => this.onChangeCheckBox(i, 4)}
                  disabled={!this.state.single}
                  checked={this.state.groupSingle && this.state.single}
                >
                  跨组单呼
                </CheckboxItem>
              </List>
              <List renderHeader="功能组">
                <Accordion>
                  <Accordion.Panel
                    header={<div><span className="red-star">*</span>功能组</div>}
                    {...getFieldProps("funcsList", {
                      rules: [{validator: this.validateFgidList}],
                    })}
                  >
                    <List>
                        {[...funcGroupList, ...funcs2].map(item => {
                          if (this.props.match.params.type == "edit") {
                            if ((funcsList && funcsList.indexOf(String(item.id)) >= 0) ||item.id < 0) {
                              return ( <List.Item key={item.id}>{item.name}</List.Item> )
                            }
                          } else {
                            return (
                                <CheckboxItem
                                  key={item.id}
                                  onChange={e => onChange( e, item.id, item )}
                                >
                                  {item.name}
                                  <List.Item.Brief> {item.remarks} </List.Item.Brief>
                                </CheckboxItem>
                            )
                          }
                        })}
                    </List>
                  </Accordion.Panel>
                </Accordion>
              </List>
            </List>
            <WhiteSpace />
            <WhiteSpace />
            <WingBlank>
              {this.props.match.params.type == "edit" ? (
                this.props.match.params.searchMy != 1 && userMsg && userMsg.userType >= 1 && userMsg.userType <= 3 ? ("") : (
                  <div>
                    <Button
                      type="warning"
                      disabled={usrType == 3}
                      onClick={() =>
                        history.push(`/newRemotewrite/${match.params.id}`)
                      }
                    >
                      远程放号
                    </Button>
                    <WhiteSpace size="xl" />
                    {userMsg.userType == 4 && !userMsg.permissionCodeList.includes(201) ? '' : (
                      <div>
                        <Button type="primary" onClick={() => this.submitBtn()}>
                          修改
                        </Button>
                        <WhiteSpace size="xl" />
                      </div>)}
                    <Button
                      onClick={() =>
                        history.push(`/newUserfriends/${userInfo.uid}`)
                      }
                    >
                      好友
                    </Button>
                    <WhiteSpace size="xl" />
                    {/* <Button
                      type="primary"
                      onClick={() =>
                        this.props.history.push(
                          `/newUserFuncRenew/${userInfo.uid}/${renewType}/${userInfo.oldType}`,
                        )
                      }
                    >
                      续费
                    </Button> */}
                    <WhiteSpace size="xl" />
                    {hideDeleteBtn ? ('') : (
                      <Button
                          type="warning"
                          onClick={() => this.deleteBtn()}
                      >
                          删除
                      </Button>
                    )}
                  </div>)
              ) : (
                  <Button type="primary" onClick={() => this.submitBtn()}>
                    确定
                </Button>
                )}
            </WingBlank>
            <WhiteSpace />
            <WhiteSpace />
            <WhiteSpace />
          </form>
        </div>
        <div
          style={{
            ...selectedContainer,
            left: drawerLeft + "px"
          }}
        >
          <UserSelectGroups
            defaultGroup={defaultGroup}
            addDefaultGroup={addDefaultGroup}
            uid={match.params.id}
            handleChildClose={() =>
              this.setState({
                drawerLeft: document.body.clientWidth
              })
            }
          />
        </div>
        <div
          style={{
            ...selectedContainer,
            left: drawerLeftDefault + "px",
            overflowY: "scroll",
            paddingBottom: "45"
          }}
        >
          <DefaultGroup
            selectList={groups}
            addDefaultGroup={addDefaultGroup}
            defaultGroup={defaultGroup}
            handleChildClose={() => {
              this.setState({
                drawerLeftDefault: document.body.clientWidth
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const newAddUserForm = createForm()(newAddUser);
const mapStateToProps = state => {
  console.log('mapStateToProps',state)
  return {
    groups: state.group.groups,
    groupsGidArr: state.group.groupsGidArr,
    defaultGroup: state.group.defaultGroup,
    funcGroupList: state.app.funcGroupList,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getFuncList: () => {
      dispatch({
          type: "app/getFuncGroupList",
          payload: {
            pageNo: 1,
            pageSize: 1000,
          },
      });
    },
    addDefaultGroup: group => {
      dispatch({
        type: "group/addDefaultGroup",
        group
      });
    },
    setGroups: data => {
      dispatch({
        type: "group/setGroups",
        data
      });
    },
    emptyGroups: () => {
      dispatch({
        type: "group/emptyGroups"
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(newAddUserForm);
