import axios from "./axios";
import http from '@/utils/request'

import api from "./api";

let baseURL = window.CONFIG_URL.baseURL
let baseURL2 = window.CONFIG_URL2.baseURL

const gen = params => {
    let url = params;
    let method = "GET";

    const paramsArray = params.split(" ");
    if (paramsArray.length === 2) {
        method = paramsArray[0];
        url = paramsArray[1];
    }
    if(method == 'POST') {
      return function(data) {
        return http.post(url,data,'new')
      }
    } else {
      return function(data) {
        return http.get(url,data,'new')
      }
    }
};
const APIFunction = {};
for (const key in api) {
    APIFunction[key] = gen(api[key]);
}
export default APIFunction;