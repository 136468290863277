import React, { Component } from 'react'

import Search from '../../../../components/search/search'
export class UserListSerach extends Component {
  render() {
    return (
      <div>
        <Search></Search>
      </div>
    )
  }
}

export default UserListSerach
