import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  Drawer,
  Button,
  WhiteSpace,
  WingBlank,
  Pagination,
  Toast,
  InputItem
} from "antd-mobile";
import { Link } from "react-router-dom";
import axios from "../../../service/axios";
import http from "../../../utils/request";
import "./admin.scss";
import { createForm } from "rc-form";
const Item = List.Item;
const Brief = Item.Brief;
const selectedContainer = {
  position: "fixed",
  width: "100%",
  top: "45px",
  background: "#f5f5f5",
  height: "100%",
  zIndex: "11",
  transition: "left 0.4s"
};
export class newAdminList extends Component {
  state = {
    adminListDatas: [],
    pageNo: 1,
    pageTotalNum: 0,
    drawerLeft: document.body.clientWidth,
    loginName: "",
    uid: ""
  };
  componentDidMount() {
    this.getAdminList(1);
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  getAdminList(page) {
    Toast.loading("加载中...", 0);
    http.post("/api/user/admin/list", {
        page: {
          pageNo: page,
          pageSize: 10,
        },
        loginName: this.state.loginName
      },'new')
      .then(res => {
        Toast.hide();
        if (res && res.status == 200 && res.data) {
          console.log(res.data.resultList,res.data.resultList.map(item => item.id))
          this.setState({
            adminListDatas: res.data.resultList,
            pageNo: res.data.pageNo,
            pageTotalNum: Math.ceil(res.data.totalCount / 10),
            drawerLeft: document.body.clientWidth
          });
        }
      })
  }
  
  onPageChange(e) {
    this.getAdminList(e);
  }
  onOpenChange = () => {
    this.setState({
      drawerLeft: 0
    });
  };
  onInfoAccountId = e => {
    this.setState({
      uid: e
    });
  };
  onInfoAccountName = e => {
    console.log(e);
    this.setState({
      loginName: e
    });
  };
  onSearch = () => {
    this.props.form.validateFields({ force: false }, error => {
      console.log(this.props.form.getFieldsValue());
      this.setState({
        loginName: this.props.form.getFieldsValue().loginName
      });
      setTimeout(() => {
        this.getAdminList(1);
      }, 0);
    });
  };
  onSearchAll = () => {
    this.setState({
      loginName: null,
      uid: null
    });
    setTimeout(() => {
      this.getAdminList(1);
    }, 0);
  };
  render() {
    const {
      adminListDatas,
      pageTotalNum,
      pageNo,
      drawerLeft,
      uid,
      loginName
    } = this.state;
    const { getFieldProps, getFieldError } = this.props.form;

    return (
      <div className="adminList">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
            rightContent={[
              <span
                key= '0'
                type= 'search'
                onClick= { this.onOpenChange }
                style={{ marginRight: '16px' ,fontSize:'12px'}}
              >查找</span>,
              <Link to="/newAddAdmin" key='1'  style={{color:'white',fontSize:'12px'}}>
                <span width="24">添加</span>
              </Link>
            ]}
          >
            管理员列表
          </NavBar>
        </div>
        <div className="sub-container">
          <List className="my-list">
            {adminListDatas.map((i, index) => (
              <Item
                key={index}
                arrow="horizontal"
                multipleLine
                onClick={() =>
                  this.props.history.push(`/newAdminDetail/${i.loginName}/${i.loginFlag}/${i.id}` )
                }
              >
                账号： {i.loginName} &ensp;&ensp;&ensp;ID:  {i.uid}
              </Item>
            ))}
          </List>
          <WhiteSpace />
          <WingBlank>
            {pageTotalNum > 0 ? (
              <Pagination
                total={pageTotalNum}
                current={pageNo}
                locale={{
                  prevText: "上一页",
                  nextText: "下一页"
                }}
                onChange={e => this.onPageChange(e)}
              />
            ) : (
              ""
            )}
          </WingBlank>
        </div>
        <div style={{ ...selectedContainer, left: drawerLeft }}>
          <List renderHeader="搜索管理员">
            <InputItem
              {...getFieldProps("loginName", {
                initialValue: loginName
              })}
              clear
              onChange={e => this.onInfoAccountName(e)}
              placeholder="请输入账号"
              key="1"
            >
              账号
            </InputItem>
            <Item>
              <Button type="primary" size="small" onClick={this.onSearch}>
                确定
              </Button>  
              <WhiteSpace size="lg" />  
              <Button type="primary" size="small" onClick={this.onSearchAll}>
                全部
              </Button>
              <WhiteSpace size="lg" />  
              <Button
                size="small" 
                onClick={() =>
                  this.setState({
                    drawerLeft: document.body.clientWidth
                  })
                }
              >
                返回
              </Button>
            </Item>
          </List>
        </div>
      </div>
    );
  }
}

export default createForm()(newAdminList);
