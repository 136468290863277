import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  WhiteSpace,
  InputItem,
  Button,
  WingBlank,
  TextareaItem,
  Toast
} from "antd-mobile";
import { connect } from "dva";
import "./addGroup.scss";
import SelectUser from "./selectUser";
import axios from "../../../service/axios";
import { createForm } from "rc-form";
const userMsg =
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg")).user;
let Item = List.Item;
const selectedContainer = {
  position: "fixed",
  width: "100%",
  top: "0",
  background: "#f5f5f5",
  height: "100%",
  zIndex: "11",
  transition: "left 0.4s"
};
export class AddGroup extends Component {
  state = {
    groupErr: "",
    drawerLeft: document.body.clientWidth,
    groupInfo: {}
  };
  componentDidMount() {
    if (this.props.match.params.type == "edit") {
      this.getGroupDetails();
      this.getGroupUserList();
    }
  }
  onAddGroup() {
    this.props.form.validateFields({ force: true }, error => {
      console.log(this.props.form.getFieldsValue());
      if (!error) {
        if (this.props.selectUserListUidArr.length > 2000) {
          Toast.fail("群组成员最多2000个");
          return;
        }
        // if (this.props.selectUserList.length < 1) {
        //   Toast.info("请选择群组成员",1);
        //   return;
        // }
        this.addGroup(this.props.form.getFieldsValue());
      }
    });
  }
  addGroup(data) {
    Toast.loading("加载中...", 0);
    let type = this.props.match.params.type == "edit" ? "edit" : "add";
    axios
      .post("/api/oa/group/" + type, {
        ...data,
        members: this.props.selectUserListUidArr.join(","),
        gid: this.props.match.params.gid
      })
      .then(res => {
        Toast.hide();
        if (res) {
          if (res.status == 1000 && res.data) {
            Toast.success(res.data, 1, () => {
              // this.props.history.goBack();
              this.props.setEmpty();
              // this.props.history.push(userMsg.userType == 4 ? "/grouplist":"/mygrouplist/1");
              this.props.history.goBack();
            });
          } else {
            Toast.fail(res.message);
          }
        }
      })
      .catch(res => {
        Toast.hide();
      });
  }
  getGroupDetails() {
    axios
      .get("/api/oa/group/list", {
        params: {
          gid: this.props.match.params.gid,
          searchMy: this.props.match.params.searchMy,
        }
      })
      .then(res => {
        if (res && res.status == 1000 && res.data) {
          this.setState({
            groupInfo: res.data.list[0]
          });
        }
      });
  }
  getGroupUserList() {
    axios
      .get("/api/oa/group/getMembersPriority", {
        params: {
          gid: this.props.match.params.gid
        }
      })
      .then(res => {
        if (res && res.status == 1000 && res.data) {
          console.log('res.data ===== ', res.data)
          let arr = res.data.list.map(item => item.tuser);
          this.props.setSelectUserList(arr);
        }
      });
  }
  validaetName = (rule, value, callback) => {
    if (value) {
      if (value.trim()) {
        if (/^[0-9a-zA-Z_\u4e00-\u9fa5]+$/.test(value)) {
          if (value.length < 2) {
            callback(new Error("群组名长度不能少于2"));
          } else {
            callback();
          }
        } else {
          callback(new Error("群组名不能包含特殊字符"));
        }
      } else {
        callback(new Error("请填写群组名"));
      }
    } else {
      callback(new Error("请填写群组名"));
    }
  };
  render() {
    const { selectUserList } = this.props;
    const { groupErr, drawerLeft } = this.state;
    const { getFieldProps, getFieldError } = this.props.form;
    const { name, remarks, usernum } = this.state.groupInfo;
    return (
      <div className="addgroup">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            {this.props.match.params.type == "edit" ? name : "群组添加"}
          </NavBar>
        </div>
        <div className="sub-container">
          <List renderFooter={() => getFieldError("name") || groupErr}>
            <InputItem
              {...getFieldProps("name", {
                rules: [
                  {
                    validator: this.validaetName
                  }
                ],
                initialValue: name
              })}
              clear
              ref={el => (this.autoFocusInst = el)}
              maxLength="11"
              placeholder="请填写群组名"
            >
              群组名字
            </InputItem>
            <Item
              ref={el => (this.autoFocusInst = el)}
              arrow="horizontal"
              onClick={() =>
                this.setState({
                  drawerLeft: 0
                })
              }
              extra={
                selectUserList.length > 0
                  ? `已选${selectUserList.length}个成员`
                  : "请选择组员"
              }
            >
              群组成员
            </Item>
            <TextareaItem
              {...getFieldProps("remarks", {
                initialValue: remarks
              })}
              title="备注"
              placeholder=""
              data-seed="logId"
              ref={el => (this.autoFocusInst = el)}
              autoHeight
            />
          </List>
          <WhiteSpace size="xl" />
          <WingBlank>
            {/* {userMsg.userType == 1 ? ( */}
            {userMsg.userType >= 1 && userMsg.userType <= 3 ? (
              ""
            ) : this.props.match.params.type == "edit" ? (
              <div>
                <Button type="primary" onClick={() => this.onAddGroup()}>
                  修改
                </Button>
                <WhiteSpace size="xl" />
                <Button
                  type="primary"
                  onClick={() =>
                    this.props.history.push(
                      `/groupuserlist/${this.props.match.params.gid}/${name}`
                    )
                  }
                >
                  成员优先级
                </Button>
              </div>
            ) : (
                  <Button type="primary" onClick={() => this.onAddGroup()}>
                    确定
              </Button>
                )}
          </WingBlank>
          <WhiteSpace size="xl" />
          <WingBlank>
            <Button onClick={() => this.props.history.goBack()}>返回</Button>
          </WingBlank>
          <WhiteSpace size="xl" />
        </div>
        <div
          style={{
            ...selectedContainer,
            left: drawerLeft + "px"
          }}
        >
          <SelectUser
            closeDrawer={() => {
              this.setState({
                drawerLeft: document.body.clientWidth
              });
            }}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    selectUserList: state.userSelectList.selectUserList,
    selectUserListUidArr: state.userSelectList.selectUserListUidArr
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSelectUserList: data => {
      dispatch({
        type: "userSelectList/setSelectUserList",
        data
      });
    },
    setEmpty: data => {
      dispatch({
        type: "userSelectList/deleteSelectUserList"
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createForm()(AddGroup));
