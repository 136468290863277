import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  Drawer,
  Button,
  WhiteSpace,
  WingBlank,
  Pagination,
  Toast,
  InputItem
} from "antd-mobile";
import { Link } from "react-router-dom";
import axios from "../../service/axios";
import "./admin.scss";
import { createForm } from "rc-form";
const Item = List.Item;
const Brief = Item.Brief;
const selectedContainer = {
  position: "fixed",
  width: "100%",
  top: "45px",
  background: "#f5f5f5",
  height: "100%",
  zIndex: "11",
  transition: "left 0.4s"
};
export class adminList extends Component {
  state = {
    adminListDatas: [],
    pageNo: 1,
    pageTotalNum: 0,
    drawerLeft: document.body.clientWidth,
    loginName: "",
    uid: ""
  };
  componentDidMount() {
    this.getAdminList(1);
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  getAdminList(page) {
    Toast.loading("加载中...", 0);
    const { loginName, uid } = this.state;
    axios
      .get("/api/oa/sysUser/list", {
        params: {
          pageNo: page,
          pageSize: 10,
          loginName,
          uid
        }
      })
      .then(res => {
        Toast.hide();
        if (res && res.status == 1000 && res.data) {
          this.setState({
            adminListDatas: res.data.list,
            pageNo: res.data.pageNo,
            pageTotalNum: Math.ceil(res.data.count / 10),
            drawerLeft: document.body.clientWidth
          });
        }
      })
      .catch(() => {
        this.setState({
          adminListDatas: [],
          pageNo: 1,
          pageTotalNum: 0,
          drawerLeft: document.body.clientWidth
        });
      });
  }
  onPageChange(e) {
    this.getAdminList(e);
  }
  onOpenChange = () => {
    this.setState({
      drawerLeft: 0
    });
  };
  onInfoAccountId = e => {
    this.setState({
      uid: e
    });
  };
  onInfoAccountName = e => {
    console.log(e);
    this.setState({
      loginName: e
    });
  };
  onSearch = () => {
    this.props.form.validateFields({ force: false }, error => {
      console.log(this.props.form.getFieldsValue());
      this.setState({
        ...this.props.form.getFieldsValue()
      });
      setTimeout(() => {
        this.getAdminList(1);
      }, 0);
    });
  };
  onSearchAll = () => {
    this.setState({
      loginName: null,
      uid: null
    });
    setTimeout(() => {
      this.getAdminList(1);
    }, 0);
  };
  render() {
    const {
      adminListDatas,
      pageTotalNum,
      pageNo,
      drawerLeft,
      uid,
      loginName
    } = this.state;
    const { getFieldProps, getFieldError } = this.props.form;

    return (
      <div className="adminList">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
            rightContent={[
              // <Icon
              //   key="0"
              //   type="search"
              //   onClick={this.onOpenChange}
              //   style={{ marginRight: "16px" }}
              // />,
              // <Link to="/addadmin" key="1">
              //   <img src={plus_icon} width="24" />,
              // </Link>,
              <span
                key= '0'
                type= 'search'
                onClick= { this.onOpenChange }
                style={{ marginRight: '16px' ,fontSize:'12px'}}
              >查找</span>,
              <Link to="/addadmin" key='1'  style={{color:'white',fontSize:'12px'}}>
                <span width="24">添加</span>
              </Link>
            ]}
          >
            管理员列表
          </NavBar>
        </div>
        <div className="sub-container">
          <List className="my-list">
            {adminListDatas.map((i, index) => (
              <Item
                key={index}
                arrow="horizontal"
                multipleLine
                onClick={() =>
                  this.props.history.push("/admindetail/" + i.user.id)
                }
              >
                登录账号： {i.user.loginName} &ensp;&ensp;&ensp;账号ID:  {i.user.uid}
                <Brief>
                  已使用额度:{i.user.totalAmount - i.user.availability}{" "}
                  剩余额度:
                  {i.user.availability} 待续费用户:{i.user.waitRenewCount}
                  {i.type == 0 ? "基础套餐" : ""}
                  {i.type == 1 ? "调度套餐" : ""}
                  {i.type == 2 ? "视频套餐" : ""}
                </Brief>
                <Brief>(可用/已用/总数)</Brief>
                <Brief>
                  基础额度:{i.user.availability}-
                  {i.user.totalAmount - i.user.availability}-
                  {i.user.totalAmount}
                </Brief>
                <Brief>
                  基础调度:{i.baseAvailInfo.dispatchLeftNum}-
                  {i.baseAvailInfo.dispatchCount -
                    i.baseAvailInfo.dispatchLeftNum}
                  -{i.baseAvailInfo.dispatchCount}
                </Brief>
                <Brief>
                  基础视频:{i.baseAvailInfo.videoLeftNum}-
                  {i.baseAvailInfo.videoCount - i.baseAvailInfo.videoLeftNum}-
                  {i.baseAvailInfo.videoCount}
                </Brief>
              </Item>
            ))}
          </List>
          <WhiteSpace />
          <WingBlank>
            {pageTotalNum > 0 ? (
              <Pagination
                total={pageTotalNum}
                current={pageNo}
                locale={{
                  prevText: "上一页",
                  nextText: "下一页"
                }}
                onChange={e => this.onPageChange(e)}
              />
            ) : (
              ""
            )}
          </WingBlank>
        </div>
        <div style={{ ...selectedContainer, left: drawerLeft }}>
          <List renderHeader="搜索管理员">
            <InputItem
              {...getFieldProps("uid", {
                initialValue: uid
              })}
              clear
              onChange={e => this.onInfoAccountId(e)}
              placeholder="请输入账号ID"
              key="2"
              type="number"
            >
              账号ID
            </InputItem>
            <InputItem
              {...getFieldProps("loginName", {
                initialValue: loginName
              })}
              clear
              onChange={e => this.onInfoAccountName(e)}
              placeholder="请输入账号名"
              key="1"
            >
              账号名
            </InputItem>
            <Item>
              <Button type="primary" onClick={this.onSearch}>
                确定
              </Button>
              <WhiteSpace size="lg" />
              <Button type="primary" onClick={this.onSearchAll}>
                全部
              </Button>
              <WhiteSpace size="lg" />
              <Button
                onClick={() =>
                  this.setState({
                    drawerLeft: document.body.clientWidth
                  })
                }
              >
                返回
              </Button>
            </Item>
          </List>
        </div>
      </div>
    );
  }
}

export default createForm()(adminList);
