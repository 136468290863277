import React, { Component } from 'react'
import {
  NavBar,
  Icon,
  InputItem,
  List,
  Picker,
  Button,
  WhiteSpace,
  WingBlank,
  Checkbox,
  TextareaItem,
  Toast,
  Accordion,
  Radio,
} from 'antd-mobile'
import { createForm } from 'rc-form'
import '../userAdd.scss'
import axios from '../../../service/axios'
import http from '../../../utils/request'
import { connect } from 'dva'
const Item = List.Item
const CheckboxItem = Checkbox.CheckboxItem
const RadioItem = Radio.RadioItem
const userMsg =
  sessionStorage.getItem('loginMsg') &&
  JSON.parse(sessionStorage.getItem('loginMsg')).user
const GpsInterval = [
  {
    label: <div key='1'>不开启</div>,
    value: 0,
  },
  {
    label: <div key='2'>1分钟</div>,
    value: 60,
  },
  {
    label: <div key='3'>5分钟</div>,
    value: 300,
  },
  {
    label: <div key='4'>10分钟</div>,
    value: 600,
  },
]
// 是否连号 参数
const Whether = [
  {
    label: <div key='1'>是</div>,
    value: 0,
  },
  {
    label: <div key='2'>否</div>,
    value: 1,
  },
]
export class BindingIccid extends Component {
  state = {
    userInfo: {
      gpsInterval: 0,
      whether: 0,
    },
    billList: [],
    groupDatas: [],
    voice: true, //会话权限 0,1,2,9
    switchs: true, //切组
    single: true, //单呼
    groupSingle: true, //跨组单呼
    radioNum: 1, // 0 为移动卡20位, 1 为电信/联通19位
    domArr: [{ iccid: '', imei: '' }],
    funcsList: [],
    funcGroupList: [],
		usertype: 3, // 默认显示一年期 3, 三年期 0
		isDef: true,
		isPrefix: false, 
  }
  //验证是否连号创建
  validateWhether = (rule, value, callback) => {
    if (typeof value[0]) {
      callback()
    } else {
      callback(new Error('请选择是否连号创建'))
    }
  }
  //验证账单号
  validateBillId = (rule, value, callback) => {
    if (value[0]) {
      callback()
    } else {
      callback(new Error('请选择账单号'))
    }
  }
  validateAccount = (rule, value, callback) => {
    if (value) {
      if (value.trim()) {
        if (!/^[0-9a-zA-z\*]+$/.test(value)) {
          callback(new Error('用户名只能是字母,数字和 * 组合'))
        } else {
          if (value.length < 2) {
            callback(new Error('用户名长度不能少于2'))
          } else {
            callback()
          }
        }
      } else {
        callback(new Error('请输入用户名'))
      }
    } else {
      callback(new Error('请输入用户名'))
    }
  }
  validatePassword = (rule, value, callback) => {
    if (value) {
      if (value.trim()) {
        if (!/^[0-9a-zA-z\*]+$/.test(value)) {
          callback(new Error('密码只能是字母,数字和 * 组合'))
        } else {
          if (value.length < 6) {
            callback(new Error('密码长度不能少于6'))
          } else {
            callback()
          }
        }
      } else {
        callback(new Error('请输入密码'))
      }
    } else {
      callback(new Error('请输入密码'))
    }
  }
  //验证gps
  validateGroup = (rule, value, callback) => {
    if (typeof value[0] == 'number') {
      callback()
    } else {
      callback(new Error('请选择默认群组'))
    }
  }
  validateGpsinterval = (rule, value, callback) => {
    if (typeof value[0] == 'number') {
      callback()
    } else {
      callback(new Error('请选择默认群组'))
    }
  }
  validateIccidstart1 = (rule, value, callback) => {
    if (value) {
      if (value.trim()) {
        if (!/^8986[a-zA-Z0-9]{9,10}[0-9]{6}$/.test(value)) {
          callback(new Error('ICCID只能是8986开头，6位数字结尾格式'))
        } else {
          if (value.length < 20) {
            callback(new Error('ICCID长度不能少于20位'))
          } else {
            callback()
          }
        }
      } else {
        callback(new Error('ICCID长度不能少于20位'))
      }
    } else {
      callback(new Error('请输入ICCID'))
    }
  }
  validateIccidstart2 = (rule, value, callback) => {
    if (value) {
      if (value.trim()) {
        if (!/^8986[a-zA-Z0-9]{9,10}[0-9]{6}$/.test(value)) {
          callback(new Error('ICCID只能是8986开头，6位数字结尾格式'))
        } else {
          if (value.length < 20) {
            callback(new Error('ICCID长度不能少于20位'))
          } else {
            callback()
          }
        }
      } else {
        callback(new Error('ICCID长度不能少于20位'))
      }
    } else {
      callback(new Error('请输入ICCID'))
    }
  }
  validateIccidstart3 = (rule, value, callback) => {
    if (value) {
      if (value.trim()) {
        if (!/^8986[a-zA-Z0-9]{8,9}[0-9]{6}$/.test(value)) {
          callback(new Error('ICCID只能是8986开头，6位数字结尾格式'))
        } else {
          if (value.length < 19) {
            callback(new Error('ICCID长度不能少于19位'))
          } else {
            callback()
          }
        }
      } else {
        callback(new Error('ICCID长度不能少于19位'))
      }
    } else {
      callback(new Error('请输入ICCID'))
    }
  }
  validateIccidstart4 = (rule, value, callback) => {
    if (value) {
      if (value.trim()) {
        if (!/^8986[a-zA-Z0-9]{8,9}[0-9]{6}$/.test(value)) {
          callback(new Error('ICCID只能是8986开头，6位数字结尾格式'))
        } else {
          if (value.length < 19) {
            callback(new Error('ICCID长度不能少于19位'))
          } else {
            callback()
          }
        }
      } else {
        callback(new Error('ICCID长度不能少于19位'))
      }
    } else {
      callback(new Error('请输入ICCID'))
    }
  }
  validateDealer = (rule, value, callback) => {
    if (value) {
      if (value.trim()) {
        callback()
      } else {
        callback(new Error('请输入经销商密码'))
      }
    } else {
      callback(new Error('请输入经销商密码'))
    }
  }
  validateCheckboxItem = (rule, value, callback) => {
    const { voice, switchs, single, groupSingle } = this.state
    if (!voice && !switchs && !single && !groupSingle) {
      callback(new Error('请至少勾选一个权限'))
    } else {
      callback()
    }
  }
  // 验证功能组
  validateFgidList = (rule, value, callback) => {
    if (value) {
      if (value.length < 1) {
        callback(new Error('请选择功能组'))
      } else {
        callback()
      }
    } else {
      callback(new Error('请选择功能组'))
    }
  }
  onChangeCheckBox = (i, type) => {
    if (type == 1) {
      this.setState({
        voice: i.target.checked,
      })
    } else if (type == 2) {
      this.setState({
        switchs: i.target.checked,
      })
    } else if (type == 3) {
      this.setState({
        single: i.target.checked,
      })
    } else {
      this.setState({
        groupSingle: i.target.checked,
      })
    }
  }
  //获取账单列表
  // getBillsByPttUser() {
  //     axios
  //     .get("/api/oa/pttBill/getBillsByPttUser", {
  //         params: {
  //         billid: this.state.userInfo.billid
  //         }
  //     })
  //     .then(res => {
  //         console.log(res);
  //         Toast.hide();
  //         if (res && res.status == 1000 && res.data) {
  //         let arr = [];
  //         res.data.map((item, index) => {
  //             arr.push({
  //             label: (
  //                 <div key={index} style={{ fontSize: "10px" }}>
  //                 {item.id +
  //                     `(${item.endDate.split(" ")[0]}到期,${
  //                     item.type == 0 ? "基础套餐" : ""
  //                     }${item.type == 1 ? "调度套餐" : ""}${
  //                     item.type == 2 ? "视频套餐" : ""
  //                     } ${item.leftnum}/${item.number})`}
  //                 </div>
  //             ),
  //             value: item.id
  //             });
  //         });
  //         console.log(arr);
  //         this.setState({
  //             billList: arr
  //         });
  //         } else {
  //         Toast.fail("获取账单号列表失败！");
  //         }
  //     });
  // }

  //权限转换
  funcsListExchange() {
		console.log('权限？',str)
    const { voice, switchs, single, groupSingle } = this.state
    let str = []
    if (voice) {
      str.push(0)
    }
    if (switchs) {
      str.push(1)
    }
    if (single) {
      str.push(3)
    }
    if (groupSingle) {
      str.push(9)
    }
    return str
  }
  // 提交ICCID放号
  submitBtn() {
    this.props.form.validateFields({ force: true }, (error) => {
      if (!error) {
        var dataMSg = this.props.form.getFieldsValue()
        // var formdata = new FormData();
        // formdata.append('seriesType',dataMSg.whether[0])
        // // formdata.append('billid',dataMSg.billid[0])
        // formdata.append('name',dataMSg.userName)
        // formdata.append('password',dataMSg.password)
        // formdata.append('gpsInterval',dataMSg.gpsInterval[0])
        // formdata.append('defaultGid',dataMSg.group[0])
        // formdata.append('agentpwd',dataMSg.dealer)
        // formdata.append('funcsList',this.funcsListExchange())

        let formdatas = {
          name: dataMSg.userName,
					nameNo: dataMSg.nameNo,
          password: dataMSg.password,
          gpsInterval: dataMSg.gpsInterval[0],
					pttUserEntity:{
						name: dataMSg.userName,
						nameNo: dataMSg.nameNo,
						password: dataMSg.password,
						gpsInterval: dataMSg.gpsInterval[0],
						defaultGids: dataMSg.group[0],
						funcsList: dataMSg.funcsList,
						funcValueList: this.funcsListExchange(),
						type: this.state.usertype,
						agentpwd: dataMSg.dealer,
					},
          agentpwd: dataMSg.dealer,
          funcValueList: this.funcsListExchange(),
          funcsList: dataMSg.funcsList,
					type: this.state.usertype
        }

        // 是否连号创建ICCID
        if (dataMSg.whether[0] == 0) {
          if (this.state.radioNum == 0) {
            formdatas.iccid = dataMSg.iccidstart1
						formdatas.iccidCount = dataMSg.iccidCount
          } else {
            formdatas.iccid = dataMSg.iccidstart3
						formdatas.iccidCount = dataMSg.iccidCount
          }
        } else {
          if (this.state.radioNum == 0) {
            for (var i = 0; i < this.state.domArr.length; i++) {
              if (
                !/^8986[a-zA-Z0-9]{8,10}[0-9]{6}$/.test(
                  this.state.domArr[i].iccid
                )
              ) {
                Toast.fail(
                  "第'" + (i + 1) + "'个ICCID只能是8986开头，6位数字结尾格式",
                  1
                )
                return
              }
              if (this.state.domArr[i].iccid.length != 20) {
                Toast.fail("第'" + (i + 1) + "'个ICCID长度不能少于20位", 1)
                return
              }
            }
            // formdatas.append('iccids', JSON.stringify(this.state.domArr))
						// 处理iccid
						let iccidArr = []
						this.state.domArr.map(item => {
							iccidArr.push(item.iccid)
						})
						formdatas.iccidList = iccidArr
          } else {
            for (var i = 0; i < this.state.domArr.length; i++) {
              if (
                !/^8986[a-zA-Z0-9]{8,9}[0-9]{6}$/.test(
                  this.state.domArr[i].iccid
                )
              ) {
                Toast.fail(
                  "第'" + (i + 1) + "'个ICCID只能是8986开头，6位数字结尾格式",
                  1
                )
                return
              }
              if (this.state.domArr[i].iccid.length != 19) {
                Toast.fail("第'" + (i + 1) + "'个ICCID长度不能少于19位", 1)
                return
              }
            }
            // formdatas.iccids = JSON.stringify(this.state.domArr)
						// 处理iccid
						let iccidArr = []
						this.state.domArr.map(item => {
							iccidArr.push(item.iccid)
						})
						formdatas.iccidList = iccidArr
          }
        }
        if (!this.funcsListExchange() || this.funcsListExchange() == ',9') {
          Toast.fail('请勾选权限', 1)
        }
        console.log('参数', formdatas)
        // axios.post("/api/oa/pttUser/saveSeriesIccid", formdata)
        // .then(res => {
        //     if (res) {
        //         if (res.status == 1000 && res.message=='ok') {
        //             // this.props.emptyGroups();
        //             Toast.success('成功', 2, () => {
        //                 this.props.history.goBack();
        //             });
        //         } else {
        //             Toast.fail(res.message,2,() => {
        //                 this.props.history.goBack();
        //             });
        //         }
        //     }
        //     else{
        //         Toast.fail('失败', 2, () => {
        //             this.props.history.goBack();
        //         });
        //     }
        // });

        http.post('/api/user/ptt/add/iccids',formdatas,'new').then(res => {
					if(res.status == 200){
						Toast.success('成功', 2, () => {
							this.props.history.goBack();
						});
					} else {
						Toast.fail(res.message,2,() => {
							this.props.history.goBack();
						});
					}
        })
      }
    })
  }
	// 连号/不连号 切换
  changeWhether(val) {
    let data = Object.assign({}, this.state.userInfo, { whether: val[0] })
    this.setState({
      userInfo: data,
      domArr: [{ iccid: '', imei: '' }],
    })
  }
	// 电信,联通/移动 切换
  changeInput(e) {
    this.setState({
      radioNum: Number(e.target.value),
      domArr: [{ iccid: '', imei: '' }],
    })
  }
	// 创建的用户年限 切换 --------------------------------------------------------------------
	changeInputUsertype(e) {
		// this.setState({
		// 	usertype: e
		// })
	}
	// 查询群组
  getGroupList() {
    http
      .get(
        '/api/user/ptt/no_groups',
        {
          pageNo: 1,
          pageSize: 2000,
        },
        'new'
      )
      .then((res) => {
        if (res.status == 200) {
          var arr = new Array()
          for (var i = 0; i < res.data.resultList.length; i++) {
            var obj = {
              label: <div key={i + 1}>{res.data.resultList[i].name}</div>,
              value: res.data.resultList[i].gid,
            }
            arr.push(obj)
          }
          console.log(arr)
          this.setState({
            groupDatas: arr,
          })
        } else {
          Toast.fail('群组查询失败')
        }
      })
    // axios
    //   .get("/api/oa/group/list", {
    //     params: {
    //       pageSize: 99999,
    //       pageNo: 1,
    //       searchKey:'',
    //       searchMy: userMsg && userMsg.userType != 4 ? 1 : null
    //     }
    //   })
    //   .then(res => {
    //       console.log(res)
    //     if (res && res.status == 1000 && res.data) {
    //         var arr = new Array();
    //         for(var i=0;i<res.data.list.length;i++){
    //             var obj = {
    //                 label: <div key={i+1}>{res.data.list[i].name}</div>,
    //                 value:res.data.list[i].gid
    //             }
    //             arr.push(obj)
    //         }
    //         console.log(arr)
    //         this.setState({
    //             groupDatas:arr
    //         });
    //     }
    //   })
    //   .catch(res => {
    //   });
  }
	// 查询功能组
  getFunctionGroupList() {
    http
      .get(
        '/api/func/group/list',
        {
          pageNo: 1,
          pageSize: 1000,
        },
        'new'
      )
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            funcGroupList: res.data,
          })
        } else {
          Toast.fail('查询功能组失败')
        }
      })
  }
  // 初始化
  async componentDidMount() {
    // await this.getBillsByPttUser();
    await this.getGroupList()
    await this.getFunctionGroupList()
  }
	// 添加无序ICCID输入框
  addDom() {
    var arr = this.state.domArr
    arr.push([])
    this.setState({
      domArr: arr,
    })
  }
	// 删除无序ICCID输入框
  delDom() {
    var arr = this.state.domArr
    arr.pop()
    this.setState({
      domArr: arr,
    })
  }
	// 非连续创建时iccid输入
  changeIccid = (key) => (e) => {
    const domArr = [...this.state.domArr]
		domArr.map((val, index) => 
					{
						console.log('item?',val,index)
					}
          // key == index ? { ...val, iccid: e } : val
        )
    this.setState(
      {
        domArr: domArr.map((val, index) => 
          key == index ? { ...val, iccid: e } : val
        ),
      },
      () => {
        console.log(this.state.domArr)
      }
    )
  }
  // changeImei = (key) => (e) => {
  //   const domArr = [...this.state.domArr]
  //   this.setState(
  //     {
  //       domArr: domArr.map((val, index) =>
  //         key == index ? { ...val, imei: e } : val
  //       ),
  //     },
  //     () => {
  //       console.log(this.state.domArr)
  //     }
  //   )
  // }
  render() {
    const { getFieldProps, getFieldError, setFieldsValue } = this.props.form
    const { billList, groupDatas, funcGroupList, checkedFuncGid, isDef, isPrefix} = this.state
    const { history } = this.props
    const {
      whether,
      billid,
      userName,
			nameNo,
      password,
      gpsInterval,
      group,
      iccidstart1,
      iccidstart3,
      dealerPassword,
      checkboxItem,
    } = this.state.userInfo
    // 勾选功能组
    const onChange = (e, item) => {
      let arr = []
      arr.push(item.id)
      this.setState(
        {
          checkedFuncGid: item.id,
        },
        () => {}
      )
      setFieldsValue({
        funcsList: arr,
      })
    }
		// 切换用户为三年期
		const checkUserType = () => {
			this.setState({
				isDef: false,
				isPrefix: true,
				usertype: 0
			})
		}
		// 切换用户为一年期
		const checkDefUserType = () => {
			this.setState({
				isDef: true,
				isPrefix: false,
				usertype: 3
			})
		}
    return (
      <div className='adduser'>
        <div className='header-sticky'>
          <NavBar
            icon={<Icon type='left' />}
            onLeftClick={() => history.goBack()}
          >
            ICCID放号
          </NavBar>
        </div>
        <div className='sub-container'>
          <form>
            <List
              renderFooter={() =>
                getFieldError('whether') ||
                getFieldError('userName') ||
                getFieldError('password') ||
                getFieldError('gpsInterval') ||
                getFieldError('iccidstart1') ||
                getFieldError('iccidstart3') ||
                getFieldError('dealer') ||
                getFieldError('group')
              }
            >
              <Picker
                {...getFieldProps('whether', {
                  rules: [
                    {
                      validator: this.validateWhether,
                    },
                  ],
                  initialValue: [whether],
                })}
                onChange={(this.changeWhether = this.changeWhether.bind(this))}
                data={Whether}
                cols={1}
                key='1'
                error={!!getFieldError('whether')}
              >
                <Item key='1' arrow='horizontal'>
                  <i className='red-star'>*</i>
                  是否连号创建
                </Item>
              </Picker>
							<Item>
								<Checkbox checked={isDef} onChange={checkDefUserType}> 一年期</Checkbox>
								<WingBlank></WingBlank>
								<Checkbox checked={isPrefix} onChange={checkUserType}> 三年期</Checkbox>
							</Item>
              <InputItem
                {...getFieldProps('userName', {
                  rules: [
                    {
                      validator: this.validateAccount,
                    },
                  ],
                  initialValue: userName,
                })}
                clear
                placeholder='请输入用户名前缀'
                key='3'
                maxLength='12'
                type='text'
                error={!!getFieldError('userName')}
              >
                <i className='red-star'>*</i>
                名称
              </InputItem>
							<InputItem
                {...getFieldProps('nameNo', {
                  
                  initialValue: nameNo,
                })}
                clear
                placeholder='请输入名称起始数'
                key='10'
                maxLength='3'
                type='digit'
              >
                名称起始数
              </InputItem>
              <InputItem
                {...getFieldProps('password', {
                  rules: [
                    {
                      validator: this.validatePassword,
                    },
                  ],
                  initialValue: password,
                })}
                clear
                placeholder='请输入密码'
                key='4'
                maxLength='12'
                type='password'
                error={!!getFieldError('password')}
              >
                <i className='red-star'>*</i>
                密码
              </InputItem>
              <Picker
                {...getFieldProps('gpsInterval', {
                  rules: [
                    {
                      validator: this.validateGpsinterval,
                    },
                  ],
                  initialValue: [gpsInterval],
                })}
                data={GpsInterval}
                cols={1}
                key='5'
                error={!!getFieldError('gpsInterval')}
              >
                <Item key='1' arrow='horizontal'>
                  <i className='red-star'>*</i>
                  GPS周期
                </Item>
              </Picker>
              <Picker
                {...getFieldProps('group', {
                  rules: [
                    { required: true, message: '请选择默认群组' },
                    { validator: this.validateGroup },
                  ],
                  initialValue: [group],
                })}
                cols={1}
                key='6'
                data={groupDatas}
                placeholder='请选择默认群组'
                error={!!getFieldError('group')}
              >
                <Item key='1' arrow='horizontal'>
                  <i className='red-star'>*</i>
                  默认群组
                </Item>
              </Picker>
              <div className='radioBox'>
                <input
                  checked={this.state.radioNum == 1}
                  onChange={(this.changeInput = this.changeInput.bind(this))}
                  id='name1'
                  type='radio'
                  value='1'
                  name='name'
                />
                <label for='name1'>联通/电信</label>
                <input
                  checked={this.state.radioNum == 0}
                  onChange={(this.changeInput = this.changeInput.bind(this))}
                  id='name'
                  type='radio'
                  value='0'
                  name='name'
                />
                <label for='name'>移动</label>
              </div>
              <div className='iccidBox'>
                {this.state.userInfo.whether == 0 ? 
								// whether = 0  连号创建
								(
                  this.state.radioNum == 0 ? (
										// 移动 iccidstart1
                    <div>
                      <InputItem
                        {...getFieldProps('iccidstart1', {
                          rules: [
                            {
                              validator: this.validateIccidstart1,
                            },
                          ],
                          initialValue: iccidstart1,
                        })}
                        error={!!getFieldError('iccidstart1')}
                        clear
                        placeholder='请输入20位ICCID起始号码'
                        key='7'
                        maxLength='20'
                      >
                        <i className='red-star'>*</i>
                        连号创建
                      </InputItem>
                      <InputItem
                        {...getFieldProps('iccidCount', {
                          rules: [
                            {
                              validator: this.validateCreateNumber,
                            },
                          ],
                          initialValue: '1',
                        })}
                        clear
                        type='number'
                        placeholder='请输入1~500个创建号码数'
                        key='8'
                        maxLength={3}
                      >
                        <i className='red-star'>*</i>
                        创建数量
                      </InputItem>
                    </div>
                	) : 
									(
										// 电/联 iccidstart3
                    <div>
                      <InputItem
                        {...getFieldProps('iccidstart3', {
                          rules: [
                            {
                              validator: this.validateIccidstart3,
                            },
                          ],
                          initialValue: iccidstart3,
                        })}
                        error={!!getFieldError('iccidstart3')}
                        clear
                        placeholder='请输入19位ICCID起始号码'
                        key='7'
                        maxLength='19'
                      >
                        <i className='red-star'>*</i>
                        连号创建
                      </InputItem>
                      <InputItem
                        {...getFieldProps('iccidCount', {
                          rules: [
                            {
                              validator: this.validateCreateNumber,
                            },
                          ],
                          initialValue: '1',
                        })}
                        clear
                        type='number'
                        placeholder='请输入1~500个创建号码数'
                        key='8'
                        maxLength={3}
                      >
                        <i className='red-star'>*</i>
                        创建数量
                      </InputItem>
                    </div>
                  )
                ) : 
								// whether = 1  非连号创建
								this.state.radioNum == 0 ? (
                  this.state.domArr.map((val, key) => {
                    return (
                      <div key={key}>
                        <InputItem
                          value={val.iccid}
                          clear
                          placeholder='请输入20位ICCID号码'
                          onChange={this.changeIccid(key)}
                          key='9'
                          maxLength='20'
                        >
                          <i className='red-star'>*</i>
                          非连号创建
                        </InputItem>
                      </div>
                    )
                  })
                ) : (
                  this.state.domArr.map((val, key) => {
                    return (
                      <div key={key}>
                        <InputItem
                          value={val.iccid}
                          clear
                          placeholder='请输入19位ICCID号码'
                          key='9'
                          onChange={this.changeIccid(key)}
                          maxLength='19'
                        >
                          <i className='red-star'>*</i>
                          非连号创建
                        </InputItem>
                      </div>
                    )
                  })
                )}
                {this.state.userInfo.whether == 1 ? (
                  <div
                    onClick={(this.addDom = this.addDom.bind(this))}
                    className='addDom'
                  >
                    +
                  </div>
                ) : null}
                {this.state.domArr.length > 1 &&
                this.state.userInfo.whether == 1 ? (
                  <div
                    onClick={(this.delDom = this.delDom.bind(this))}
                    className='delDom'
                  >
                    -
                  </div>
                ) : null}
              </div>
              <InputItem
                {...getFieldProps('dealer', {
                  rules: [
                    {
                      validator: this.validateDealer,
                    },
                  ],
                  initialValue: dealerPassword,
                })}
                clear
                placeholder='请输入经销商密码'
                key='11'
                maxLength='12'
                type='password'
                error={!!getFieldError('dealer')}
              >
                <i className='red-star'>*</i>
                经销商密码
              </InputItem>
              <List renderHeader='权限'>
                <CheckboxItem
                  {...getFieldProps('defaultCheckboxItem', {
                    rules: [
                      {
                        validator: this.validateCheckboxItem,
                      },
                    ],
                    initialValue: [checkboxItem],
                  })}
                  onChange={(i) => this.onChangeCheckBox(i, 1)}
                  checked={this.state.voice}
                >
                  会话
                </CheckboxItem>
                <CheckboxItem
                  onChange={(i) => this.onChangeCheckBox(i, 2)}
                  checked={this.state.switchs}
                >
                  切组
                </CheckboxItem>
                <CheckboxItem
                  onChange={(i) => this.onChangeCheckBox(i, 3)}
                  checked={this.state.single}
                >
                  单呼
                </CheckboxItem>
                <CheckboxItem
                  onChange={(i) => this.onChangeCheckBox(i, 4)}
                  disabled={!this.state.single}
                  checked={this.state.groupSingle && this.state.single}
                >
                  跨组单呼
                </CheckboxItem>
              </List>
              <List renderHeader='功能组'>
                <Accordion>
                  <Accordion.Panel
                    header={
                      <div>
                        <span className='red-star'>*</span>功能组
                      </div>
                    }
                    {...getFieldProps('funcsList', {
                      rules: [{ validator: this.validateFgidList }],
                    })}
                  >
                    <List>
                      {[...funcGroupList].map((item) => (
                        //   <CheckboxItem
                        //     key={item.id}
                        //     onChange={e => onChange( e, item.id, item )}
                        //   >
                        //     {item.name}
                        //     <List.Item.Brief> {item.remarks} </List.Item.Brief>
                        //   </CheckboxItem>

                        <RadioItem
                          key={item.id}
                          onChange={(e) => onChange(e, item)}
                          checked={checkedFuncGid == item.id}
                        >
                          {item.name}&nbsp;&nbsp;
                          <List.Item.Brief> {item.remarks} </List.Item.Brief>
                        </RadioItem>
                      ))}
                    </List>
                  </Accordion.Panel>
                </Accordion>
              </List>
            </List>
            <WhiteSpace />
            <WhiteSpace />
            <WingBlank>
              <Button type='primary' onClick={() => this.submitBtn()}>
                确定
              </Button>
            </WingBlank>
          </form>
        </div>
      </div>
    )
  }
}

const BindingIccidFrom = createForm()(BindingIccid)

export default connect()(BindingIccidFrom)
