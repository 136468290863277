import React, { Component } from "react";
import { NavBar, Icon, List, WhiteSpace, Button, WingBlank } from "antd-mobile";
import axios from "../../../service/axios";
import { formatTimeStamp } from "../../../utils/common";
const Item = List.Item;

export class BillDetail extends Component {
  state = {
    billDedailDatas: {}
  };
  componentDidMount() {
    axios
      .get("/api/oa/pttBill/list", {
        params: {
          id: this.props.match.params.id,
          pageNo: 1,
          pageSize: 10,
          searchMy: this.props.match.params.searchMy
        }
      })
      .then(res => {
        if (res && res.status && res.data) {
          this.setState({
            billDedailDatas: res.data.list[0]
          });
        }
      });
  }

  render() {
    const { billDedailDatas } = this.state;
    return (
      <div className="billDetails">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            账单详情
          </NavBar>
        </div>
        <div className="sub-container">
          <List renderHeader={() => "账单号  " + billDedailDatas.id}>
            <Item wrap extra={formatTimeStamp(billDedailDatas.createDate)}>
              开始时间
            </Item>
            <Item wrap extra={formatTimeStamp(billDedailDatas.endDate)}>
              结束时间
            </Item>
            <Item
              extra={
                billDedailDatas.type == 0
                  ? "基础套餐"
                  : billDedailDatas.type == 1
                  ? "调度套餐"
                  : billDedailDatas.type == 2
                  ? "视频套餐"
                  : ""
              }
            >
              套餐类型
            </Item>
            <Item
              extra={<span>{billDedailDatas.quotaType == 0 ? "基础额度" : ""}{billDedailDatas.quotaType == 1 ? "VIP额度" : ""}{billDedailDatas.quotaType == 2 ? "三年额度" : ""}{billDedailDatas.quotaType == 3 ? "续费额度" : ""}</span>}
            >
              额度类型
            </Item>
            <Item
              extra={billDedailDatas.billType == 0 ? "基础账单" : "续费账单"}
            >
              账单类型
            </Item>
            <Item extra={billDedailDatas.number}>额度值</Item>
            <Item extra={billDedailDatas.leftnum}>剩余数</Item>
          </List>
          <List renderHeader={() => "备注"}>
            <Item wrap>{billDedailDatas.remarks}</Item>
          </List>
          <WhiteSpace size="xl" />
          <WingBlank>
            <Button onClick={() => this.props.history.goBack()} type="primary">
              返回
            </Button>
          </WingBlank>
        </div>
      </div>
    );
  }
}

export default BillDetail;
