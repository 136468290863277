import React, { Component } from 'react'
import { Icon, List, WingBlank, WhiteSpace, Button, Radio } from 'antd-mobile'
const RadioItem = Radio.RadioItem

export class DefaultGroups extends Component {
  onChange = i => {
    this.props.addDefaultGroup(i)
  }
  render() {
    const { handleChildClose, selectList, defaultGroup } = this.props
    return (
      <div className="selected-container">
        <List renderHeader={() => '请选择默认群组'}>
          {selectList.map((i, index) => (
            <RadioItem
              checked={defaultGroup.gid === i.gid}
              key={index}
              extra={<Icon type="cross" />}
              onChange={() => this.onChange(i)}
            >
              {i.name}
            </RadioItem>
          ))}
          <WingBlank>
            <WhiteSpace size="xl" />
            <Button type="primary" onClick={() => handleChildClose()}>
              确定
            </Button>
            <WhiteSpace size="xl" />
            <Button type="" onClick={() => handleChildClose()}>
              返回
            </Button>
            <WhiteSpace size="xl" />
          </WingBlank>
        </List>
      </div>
    )
  }
}

export default DefaultGroups
