import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  DatePicker,
  Picker,
  InputItem,
  Button,
  WingBlank,
  WhiteSpace,
  TextareaItem,
  ActivityIndicator,
  Toast
} from "antd-mobile";
import { createForm } from "rc-form";
import "./billExchange.scss";
import axios from "../../../service/axios";
import { formatTimeStamp } from "../../../utils/common";
const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);
const userMsg =
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg")).user;

const type = [
  {
    label: "基础套餐",
    value: 0
  },
  {
    label: "调度套餐",
    value: 1
  },
  {
    label: "视频套餐",
    value: 2
  }
];
const billTypes = [
  {
    label: "基础账单",
    value: 0
  },
  {
    label: "续费账单",
    value: 1
  }
];

const quotaTypeDatas = [
  {
    label: "基本额度",
    value: 0
  },
  {
    label: "VIP额度",
    value: 1
  },
  {
    label: "三年额度",
    value: 2
  },
  {
    label: "续费额度",
    value: 3
  }
];

export class BillExchange extends Component {
  state = {
    date: now,
    endDate: new Date(),
    showToast: false,
    billType: 0,
    quotaType: 0,
    hasBaseVipLeftNum: false,
    hasDispatchVipLeftNum: false,
    hasVideoVipLeftNum: false,
    showVipInfo: false,
    userValidYears: 3
  };

  onSubmit = () => {
    this.props.form.validateFields({ force: true }, error => {
      if (!error) {
        console.log(this.props.form.getFieldsValue());
        let filedsValue = this.props.form.getFieldsValue();
        const { type } = filedsValue;
        if (filedsValue.billType == 1 && filedsValue.quotaType == 0 && type == 0 && this.state.hasBaseVipLeftNum) {
          Toast.info(`请优先用完VIP基础额度置换续费账单`);
          return
        }
        if (filedsValue.billType == 1 && filedsValue.quotaType == 0 && type == 1 && this.state.hasDispatchVipLeftNum) {
          Toast.info(`请优先用完VIP调度额度置换续费账单`);
          return
        }
        if (filedsValue.billType == 1 && filedsValue.quotaType == 0 && type == 2 && this.state.hasVideoVipLeftNum) {
          Toast.info(`请优先用完VIP视频额度置换续费账单`);
          return
        }

        let params = {
          billType: String(filedsValue.billType),
          quotaType: String(filedsValue.quotaType),
          type: String(filedsValue.type),
          number: Number(filedsValue.number),
          remarks: filedsValue.remarks
        };
        this.setState({
          showToast: true
        });
        axios
          .get("/api/oa/pttBill/convert", {
            params
          })
          .then(res => {
            this.setState({
              showToast: false
            });
            if (res) {
              if (res.status == 1000) {
                Toast.success("置换账单成功!",2);
                setTimeout(()=>{
                  this.props.history.goBack();
                },2000)
              } else {
                Toast.fail(res.data,2);
                setTimeout(()=>{
                  this.props.history.goBack();
                },2000)
              }
            }
          })
          .catch(err => {
            this.setState({
              showToast: false
            });
            setTimeout(()=>{
              this.props.history.goBack();
            },2000)
            Toast.fail("置换账单失败!",2);
          });
      }
    });
  };
  componentDidMount() {
    var d2 = new Date();
    d2.setFullYear(d2.getFullYear() + 1);
    d2.setDate(d2.getDate() + 15);
    this.setState({
      endDate: d2
    });
    this.getLoginAdminInfo();
  }
  onChangeQuota = (rule, value, callback) => {
    console.log(value,11111);
    this.setState({
      quotaType: value[0]
    });
    this.onChangeQuotaTypes(value[0]);
    callback()
  };
  onChangeBillTypes = (rule, value, callback) => {
    console.log(value);
    var d2 = new Date();
    d2.setFullYear(d2.getFullYear() + 1);
    if (value[0] == 0) {
      d2.setDate(d2.getDate() + 15);
    }
    this.setState({
      endDate: d2
    });
    callback()
  };
  onChangeQuotaTypes = (value) => {
    console.log(value);
    var d2 = new Date();
    if (value == 0) {
      d2.setFullYear(d2.getFullYear() + 1);
      d2.setDate(d2.getDate() + 15);
    }
    if (value == 2 && this.state.userValidYears == 3) {
      d2.setFullYear(d2.getFullYear() + 3);
      d2.setDate(d2.getDate());
    }
    if (value == 1 || value == 3 || (value == 2 && this.state.userValidYears == 1)) {
      d2.setFullYear(d2.getFullYear() + 1);
      d2.setDate(d2.getDate());
    }
    this.setState({
      endDate: d2
    });
  };
  getLoginAdminInfo() {
    axios
      .get("/api/oa/sysUser/info", {
        params: {
          id: userMsg.id
        }
      })
      .then(res => {
        if (res && res.status == 1000) {
          const { data } = res
          this.setState({
            hasBaseVipLeftNum: data.user.vipAvail > 0,
            hasDispatchVipLeftNum: data.vipAvailInfo.dispatchLeftNum > 0,
            hasVideoVipLeftNum: data.vipAvailInfo.videoLeftNum > 0,
            showVipInfo: data.user.vipAvailAmount > 0 || data.vipAvailInfo.dispatchCount > 0 || data.vipAvailInfo.videoCount > 0,
            userValidYears: res.data.userValidYears.years
          });
        }
      });
  }
  render() {
    const { getFieldProps, getFieldError } = this.props.form;
    const { billType, quotaType, showVipInfo } = this.state;
    console.log(billType)
    return (
      <div className="billExchange">
        <NavBar
          icon={<Icon type="left" />}
          onLeftClick={() => this.props.history.goBack()}
        >
          账单置换
        </NavBar>
        <div>
          <div>
            <ActivityIndicator
              toast
              text="正在处理..."
              animating={this.state.showToast}
            />
            <form>
              <List
                className="date-picker-list"
                renderFooter={() =>
                  getFieldError("quotaType") ||
                  getFieldError("type") ||
                  getFieldError("billType") ||
                  getFieldError("number") ||
                  getFieldError("remarks")
                }
              >
                <DatePicker
                  mode="date"
                  value={this.state.date}
                  {...getFieldProps("starteDate", {
                    rules: [{ required: true, message: "请选择开始时间" }],
                    initialValue: new Date()
                  })}
                  disabled
                >
                  <List.Item arrow="horizontal">开始时间</List.Item>
                </DatePicker>

                <DatePicker
                  mode="date"
                  value={this.state.date}
                  {...getFieldProps("endDate", {
                    rules: [{ required: true, message: "请选择结束时间" }],
                    initialValue: this.state.endDate
                  })}
                  disabled
                  format
                >
                  <List.Item arrow="horizontal">结束时间</List.Item>
                </DatePicker>
                <Picker
                  data={showVipInfo ? quotaTypeDatas : [quotaTypeDatas[0], quotaTypeDatas[2], quotaTypeDatas[3]]}
                  cols={1}
                  {...getFieldProps("quotaType", {
                    rules: [
                      {
                        validator: this.onChangeQuota
                      }
                    ],
                    initialValue: [0]
                  })}
                >
                  <List.Item arrow="horizontal">额度类型</List.Item>
                </Picker>
                <Picker
                  data={type}
                  cols={1}
                  {...getFieldProps("type", {
                    initialValue: [0]
                  })}
                >
                  <List.Item arrow="horizontal">套餐类型</List.Item>
                </Picker>
                <Picker
                  data={billTypes}
                  cols={1}
                  {...getFieldProps("billType", {
                    rules: [
                      {
                        validator: this.onChangeBillTypes
                      }
                    ],
                    initialValue: [quotaType == 1 || quotaType == 3 ? 1 : billType]
                  })}
                  disabled
                >
                  <List.Item arrow="horizontal">账单类型</List.Item>
                </Picker>

                <InputItem
                  {...getFieldProps("number", {
                    rules: [
                      {
                        required: true,
                        message: "请填写要置换的额度值"
                      }
                    ]
                  })}
                  type="number"
                  clear
                  placeholder="请输入数字"
                >
                  额度值
                </InputItem>
                <TextareaItem
                  {...getFieldProps("remarks", {
                    // rules: [{ required: true, message: '请填写备注' }]
                  })}
                  title="备注"
                  placeholder="填写备注"
                  autoHeight
                />
              </List>
              <WhiteSpace size="xl" />
              <WingBlank>
                <Button type="primary" onClick={() => this.onSubmit()}>
                  额度换账单
                </Button>
              </WingBlank>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default createForm()(BillExchange);
