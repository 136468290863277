import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  Drawer,
  InputItem,
  Picker,
  Button,
  WhiteSpace,
  WingBlank,
  Pagination,
  Toast
} from "antd-mobile";
import axios from "../../../service/axios";
import { createForm } from "rc-form";
import NoneDatas from "../../../components/noneDatas";
import "./billlist.scss";

const Item = List.Item;
const Brief = Item.Brief;

const combos = [
  {
    label: "全部",
    value: ""
  },
  {
    label: "基础额度",
    value: "0"
  },
  // {
  //   label: "VIP额度",
  //   value: "1"
  // }
  {
    label: "三年额度",
    value: "2"
  },
  {
    label: "续费额度",
    value: "3"
  }
];

const billTypes = [
  {
    label: "全部",
    value: ""
  },
  {
    label: "基础账单",
    value: "0"
  },
  {
    label: "续费账单",
    value: "1"
  }
];
const selectedContainer = {
  position: "fixed",
  width: "100%",
  top: "45px",
  background: "#f5f5f5",
  height: "100%",
  zIndex: "11",
  transition: "left 0.4s"
};
export class BillList extends Component {
  state = {
    open: false,
    combo: [-1],
    billListDatas: [],
    pageNo: 1,
    pageTotalNum: 0,
    searchDrawer: document.body.clientWidth,
    paramsSearch: {},
    paramsSearchMy: ""
  };
  componentDidMount() {
    this.getBillList(1);
    this.setState({
      paramsSearchMy: this.props.match.params.searchMy ? "/" + this.props.match.params.searchMy : "",
    });
  }
  getBillList(e) {
    Toast.loading("加载中...", 0);
    axios
      .get("/api/oa/pttBill/list", {
        params: {
          pageNo: e,
          pageSize: 10,
          searchMy: this.props.match.params.searchMy,
          ...this.state.paramsSearch
        }
      })
      .then(res => {
        Toast.hide();
        if (res && res.status == 1000 && res.data) {
          this.setState({
            billListDatas: res.data.list,
            pageNo: res.data.pageNo,
            pageTotalNum: Math.ceil(res.data.count / 10)
          });
        }
      })
      .catch(() => {
        Toast.hide();
      });
  }
  onOpenChange = () => {
    this.setState({
      open: !this.state.open
    });
  };
  onPageChange(e) {
    console.log(e);
    this.getBillList(e);
  }
  searchList() {
    this.props.form.validateFields({ force: true }, error => {
      if (!error) {
        console.log(this.props.form.getFieldsValue());
        const { id, quotaType, billType } = this.props.form.getFieldsValue();
        this.setState({
          paramsSearch: {
            id,
            quotaType: quotaType[0],
            billType: billType[0]
          },
          searchDrawer: document.body.clientWidth
        });
        setTimeout(() => {
          this.getBillList(1);
        }, 0);
      }
    });
  }
  searchAllList() {
    this.setState({
      paramsSearch: {},
      searchDrawer: document.body.clientWidth
    });
    setTimeout(() => {
      this.getBillList(1);
    }, 0);
  }
  render() {
    const { getFieldProps, getFieldError } = this.props.form;
    const { billListDatas, pageTotalNum, pageNo, searchDrawer } = this.state;
    return (
      <div className="billlist">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
            rightContent={[
              <span 
                key='0' 
                onClick={ () => {
                  this.setState({
                    searchDrawer: 0
                  });
                }}
                style={{ marginRight: '16px',fontSize:'12px'}}
              >查找</span>,
            ]}
          >
            {this.props.match.params.searchMy == 1? "我的账单":"账单列表"}
          </NavBar>
        </div>

        <div className="sub-container">
          <List className="my-list">
            {billListDatas.map((i, index) => (
              <Item
                key={index}
                arrow="horizontal"
                multipleLine
                onClick={() => this.props.history.push("/billdetails/" + i.id + this.state.paramsSearchMy)}
              >
                账单号： {i.id}
                <Brief>
                  套餐类型：
                  {i.type == 0 ? "基础套餐" : ""}
                  {i.type == 1 ? "调度套餐" : ""}
                  {i.type == 2 ? "视频套餐" : ""}
                </Brief>
                <Brief>
                  额度类型：
                  {i.quotaType == 0 ? "基础额度" : ""}
                  {i.quotaType == 1 ? "VIP额度" : ""}
                  {i.quotaType == 2 ? "三年额度" : ""}
                  {i.quotaType == 3 ? "续费额度" : ""}
                </Brief>
                <Brief>
                  账单类型：
                  {i.billType == 0 ? "基础账单" : "续费账单"}
                </Brief>
              </Item>
            ))}
          </List>
          {billListDatas.length == 0 ? <NoneDatas /> : ""}
          <WhiteSpace size="xl" />
          <WingBlank>
            {pageTotalNum > 0 ? (
              <Pagination
                total={pageTotalNum}
                current={pageNo}
                locale={{
                  prevText: "上一页",
                  nextText: "下一页"
                }}
                onChange={e => this.onPageChange(e)}
              />
            ) : (
              ""
            )}
          </WingBlank>
          <div style={{ ...selectedContainer, left: searchDrawer }}>
            <div className="bill-sidebar">
              <List
                renderHeader={() => "搜索账单"}
                renderFooter={() => getFieldError("id")}
              >
                <InputItem
                  {...getFieldProps("id")}
                  clear
                  placeholder="账单号"
                  type="number"
                >
                  账单号
                </InputItem>
                <Picker
                  data={combos}
                  value={[this.state.combo]}
                  cols={1}
                  onChange={this.onChangeColor}
                  {...getFieldProps("quotaType", {
                    rules: [{ required: true, message: "请选择额度类型" }],
                    initialValue: [""]
                  })}
                >
                  <List.Item arrow="horizontal">额度类型 </List.Item>
                </Picker>
                <Picker
                  data={billTypes}
                  value={this.state.colorValue}
                  cols={1}
                  onChange={this.onChangeColor}
                  {...getFieldProps("billType", {
                    rules: [{ required: true, message: "请选择套餐类型" }],
                    initialValue: [""]
                  })}
                >
                  <List.Item arrow="horizontal">账单类型</List.Item>
                </Picker>
              </List>
              <WhiteSpace size="xl" />
              <Button type="primary" onClick={() => this.searchList()}>
                搜索
              </Button>
              <WhiteSpace size="xl" />
              <Button type="primary" onClick={() => this.searchAllList()}>
                全部
              </Button>
              <WhiteSpace size="xl" />
              <Button
                onClick={() => {
                  this.setState({
                    searchDrawer: document.body.clientWidth
                  });
                }}
                type=""
              >
                关闭
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default createForm()(BillList);
