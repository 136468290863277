import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  Button,
  WhiteSpace,
  WingBlank,
  Toast,
  ActionSheet,
  Modal,
  Radio
} from "antd-mobile";
import "./adminDetail.scss";
import axios from "../../../../service/axios";
import http from '../../../../utils/request'
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;
const userMsg =
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg"))
export class newAdminDetails extends Component {
  state = {
    adminInfoDatas: {},
    baseAvailInfo: {},
    loginAdminMsg: {},
    vipAvailInfo: {},
    waitRenewCount: 0,
    showVipInfo: false,
    modal1: false,
    userValidYears: 3
  };
  componentDidMount() {
    this.getAdminInfo();
    this.getAdminSurlpusAllCount()
    // this.getLoginAdminInfo();
  }
  // 查询目标管理员的信息
  getAdminInfo() {
    Toast.loading("加载中...", 0);
    http.get("/api/user/admin/find/login_name", {
          loginName: this.props.match.params.name
      },'new')
      .then(res => {
        Toast.hide();
        if (res && res.status == 200) {
          console.log('res',res)
          this.setState({
            adminInfoDatas: res.data,
            // baseAvailInfo: res.data.baseAvailInfo,
            // vipAvailInfo: res.data.vipAvailInfo,
            // waitRenewCount: res.data.waitRenewCount,
            // userValidYears: res.data.userValidYears.years
          })
        }
      });
  }
  // 查询当前管理员的信息
  getLoginAdminInfo() {
    axios
      .get("/api/oa/sysUser/info", {
        params: {
          id: userMsg.id
        }
      })
      .then(res => {
        if (res && res.status == 1000) {
          const { data } = res
          this.setState({
            loginAdminMsg: data,
            showVipInfo: data.user.vipAvailAmount > 0 || data.vipAvailInfo.dispatchCount > 0 || data.vipAvailInfo.videoCount > 0
          });
        }
      });
  }
  // 查询目标管理员剩余币信息
  getAdminSurlpusAllCount() {
    http.get('/api/currency/surlpusAllCount',{
      adminId: this.props.match.params.id
    },'new').then(res => {
      if(res.status == 200){
        // console.log('AllCount',res)

      } else {
        Toast.error(res.message)
      }
    })
  }
  // 删除管理员
  deleteUser() {
    const BUTTONS = ["确定", "取消"];
    ActionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        message: "是否删除该管理员？",
        maskClosable: true
      },
      buttonIndex => {
        if (!buttonIndex) {
          Toast.loading("加载中", 0);
          axios
            .get("/api/oa/sysUser/delete", {
              params: {
                id: this.props.match.params.id
              }
            })
            .then(res => {
              if (res) {
                if (res.status == 1000) {
                  Toast.success(res.data, 1, () => {
                    this.props.history.goBack();
                  });
                } else {
                  Toast.fail(res.data, 1);
                }
              }
            });
        }
      }
    );
  }
  onChange(value) {
    console.log(value);
    this.setState({ userValidYears: value });
  }
  // 设置管理员年限
  setUserValidYears() {
    Toast.loading("加载中...", 0);
    http
      .get("/api/user/admin/validYears/set", {
          years: this.state.userValidYears,
          targetId: this.props.match.params.id
      },'new')
      .then(res => {
        Toast.hide();
        if (res) {
          if (res.status == 200 && res.data) {
            Toast.success(res.data, 1, () => {
              this.props.history.goBack();
            });
          } else {
            Toast.fail(res.message, 1);
          }
        }
      })
      .catch(res => {
        Toast.hide();
        Toast.fail("操作失败");
      });
  }

  render() {
    const {
      adminInfoDatas,
      baseAvailInfo,
      loginAdminMsg,
      vipAvailInfo,
      waitRenewCount,
      showVipInfo,
      modal1,
      userValidYears
    } = this.state;
    console.log('loginAdminMsg',loginAdminMsg,userMsg)
    return (
      <div className="addAdmin">
        <div className="header-sticky header-navbar">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            {adminInfoDatas.loginName}
          </NavBar>
        </div>
        <div className="sub-container">
          <form>
            <List renderHeader={() => "基本信息"}>
              <Item extra={`${adminInfoDatas.loginName}`}>登录账号 </Item>
              <Item extra={adminInfoDatas.createBy}>上级账号 </Item>
              <Item extra={`${adminInfoDatas.password}`}>密码 </Item>
              <Item extra={`${adminInfoDatas.remarks}`}>备注 </Item>
            </List>
            <WhiteSpace size="lg" />
            <WingBlank size="lg">
              <Button
                type="ghost"
                onClick={() =>
                  this.props.history.push(`/newAdminnum/${adminInfoDatas.loginName}/0/${adminInfoDatas.id}`)
                }
              >
                下发币
              </Button>
              <WhiteSpace />
              <Button
                onClick={() =>
                  this.props.history.push(`/newAdminnum/${adminInfoDatas.loginName}/1/${adminInfoDatas.id}`)
                }
              >
                回收币
              </Button>
              <WhiteSpace />
              <Button
                type="primary"
                onClick={() =>
                  this.props.history.push(`/newAddadmin/${adminInfoDatas.loginName}/edit/${adminInfoDatas.id}`)
                }
              >
                修改
              </Button>
              <WhiteSpace />
              {userMsg && userMsg.userType == 3 ? (
                <div>
                  <Button
                    type="primary"
                    onClick={() =>
                      this.props.history.push(
                        `/newAdminPermission/${adminInfoDatas.id}`,
                      )
                    }>
                    权限设置
                </Button>
                  <WhiteSpace />
                  <Button onClick={() => this.setState({ modal1: true })}
                  >年限设置
                </Button>
                  {/* <WhiteSpace />
                  <Button
                    type="ghost"
                    onClick={() =>
                      this.props.history.push(`/adminCurrencyNum/${adminInfoDatas.id}/0`)
                    }
                  >
                        下发续费币
                  </Button>
                      <WhiteSpace />
                      <Button
                        onClick={() =>
                          this.props.history.push(`/adminCurrencyNum/${adminInfoDatas.id}/1`)
                        }
                      >
                        回收续费币
                  </Button> */}
                  <WhiteSpace />
                </div>
              ) : ("")}
              {/* <Button type="warning" onClick={() => this.deleteUser()}>
                删除
              </Button> */}
            </WingBlank>
            <Modal
              visible={modal1}
              transparent
              maskClosable={false}
              onClose={() => this.setState({ modal1: false })}
              title="年限设置"
              footer={[
                { text: '确定', onPress: () => this.setUserValidYears() },
                { text: '取消', onPress: () => { console.log(`cancel`); this.setState({ modal1: false }); } }]}
            >
              <div style={{ height: 100, overflow: 'scroll' }}>
                <List>
                  <RadioItem key="1" checked={userValidYears == 1} onChange={() => this.onChange(1)}>
                    1年
                  </RadioItem>
                  <RadioItem key="3" checked={userValidYears == 3} onChange={() => this.onChange(3)}>
                    3年
                  </RadioItem>
                </List>
              </div>
            </Modal>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
          </form>
        </div>
      </div>
    );
  }
}

export default newAdminDetails;
