import React, { Component } from 'react'
import './addAdmin.scss'
import {
  NavBar,
  Icon,
  InputItem,
  List,
  Button,
  WhiteSpace,
  WingBlank,
  Picker,
  Toast
} from 'antd-mobile'
import { createForm } from 'rc-form'
import axios from '../../../service/axios'
const Item = List.Item
const loginFlagDatas = [
  {
    label: '不允许',
    value: 0
  },
  {
    label: '允许',
    value: 1
  }
]
export class AddAdmin extends Component {
  state = {
    loginMsg: {},
    loginMsgUser: {}
  }
  componentDidMount() {
    if (this.props.match.params.type == 'edit') {
      this.getUserMsg()
    }
  }
  getUserMsg() {
    axios
      .get('/api/oa/sysUser/info', {
        params: {
          id: this.props.match.params.id
        }
      })
      .then(res => {
        if (res && res.status == 1000) {
          this.setState({
            loginMsg: res.data,
            loginMsgUser: res.data.user
          })
        }
      })
  }
  submit = () => {
    this.props.form.validateFields({ force: true }, error => {
      const { loginFlag } = this.props.form.getFieldsValue()
      console.log(this.props.form.getFieldsValue())
      let type = this.props.match.params.type ? 'edit' : 'add'
      if (!error) {
        Toast.loading('加载中...', 0)
        axios
          .get('/api/oa/sysUser/' + type, {
            params: {
              ...this.props.form.getFieldsValue(),
              loginFlag: loginFlag[0],
              id: this.props.match.params.id
            }
          })
          .then(res => {
            Toast.hide()
            if (res) {
              if (res.status == 1000 && res.data) {
                Toast.success(res.data, 1.5, () => {
                })
                setTimeout(() => {
                  // this.props.history.push('/adminlist')
                  this.props.history.goBack()
                }, 1500)
              } else {
                Toast.fail(this.props.match.params.type ? res.data : res.message, 1)
              }
            }
          })
      }
    })
  }

  validateAccount = (rule, value, callback) => {
    if (value) {
      if (value.length >= 2) {
        if (!/^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(value)) {
          callback(new Error('存在非法字符，请输入中文、英文、数字或下划线'))
        } else {
          callback()
        }
      } else {
        callback(new Error('账号长度不能少于2'))
      }
    } else {
      callback('请输入账号')
    }
  }
  validatePassword = (rule, value, callback) => {
    if (value) {
      if (value.length < 3) {
        callback(new Error('密码不能少于3位'))
      } else {
        callback()
      }
    } else {
      callback(new Error('密码不能为空'))
    }
  }
  validateConPassword = (rule, value, callback) => {
    const { password } = this.props.form.getFieldsValue()
    if (value) {
      if (value != password) {
        callback(new Error('密码不一致，请确认密码'))
      } else {
        callback()
      }
    } else {
      callback(new Error('请确认密码'))
    }
  }
  render() {
    const { getFieldProps, getFieldError } = this.props.form
    const {
      name,
      loginName,
      password,
      website,
      email,
      mobile,
      phone,
      qq,
      remarks,
      loginFlag
    } = this.state.loginMsgUser
    const { type } = this.props.match.params
    return (
      <div className="addAdmin">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            {this.props.match.params.type == 'edit' ? '修改' : '添加'}管理员
        </NavBar>
        </div>
        <div className='sub-container'>
          <form>
            <List
              renderFooter={() =>
                getFieldError('loginName') ||
                getFieldError('password') ||
                getFieldError('conPassword')
              }
            >
              <WhiteSpace size="lg" />
              <InputItem
                {...getFieldProps('name', {
                  initialValue: name
                })}
                clear
                maxLength="11"
              >
                名字
            </InputItem>
              <InputItem
                {...getFieldProps('loginName', {
                  rules: [{ validator: this.validateAccount }],
                  initialValue: loginName
                })}
                error={!!getFieldError('loginName')}
                clear
                placeholder="支持中文、英文、数字或下划线"
                maxLength="11"
              >
                登录账号
              <i className="red-star">*</i>
              </InputItem>
              <InputItem
                {...getFieldProps('password', {
                  rules: [
                    {
                      validator: this.validatePassword
                    }
                  ],
                  initialValue: password
                })}
                error={!!getFieldError('password')}
                clear
                type={type == 'edit' ? 'text' : "password"}
                maxLength="18"
              >
                密码
              <i className="red-star">*</i>
              </InputItem>
              <InputItem
                {...getFieldProps('conPassword', {
                  rules: [{ validator: this.validateConPassword }],
                  initialValue: password
                })}
                error={!!getFieldError('conPassword')}
                clear
                type={type == 'edit' ? 'text' : "password"}
                maxLength="18"
              >
                确认密码
              <i className="red-star">*</i>
              </InputItem>
              <InputItem
                {...getFieldProps('website', {
                  initialValue: website
                })}
                clear
              >
                网址
            </InputItem>
              <InputItem
                {...getFieldProps('email', {
                  initialValue: email
                })}
                clear
              >
                邮箱
            </InputItem>
              <InputItem
                {...getFieldProps('mobile', {
                  // rules: [{ validator: this.validateAccount }]
                  initialValue: mobile
                })}
                type="number"
                clear
              >
                手机
            </InputItem>
              <InputItem
                {...getFieldProps('phone', {
                  // rules: [{ validator: this.validateAccount }]
                  initialValue: phone
                })}
                type="number"
                clear
                maxLength="11"
              >
                座机
            </InputItem>
              <InputItem
                {...getFieldProps('qq', {
                  initialValue: qq
                })}
                clear
                type="number"
              >
                QQ
            </InputItem>
              <InputItem
                {...getFieldProps('remarks', {
                  initialValue: remarks
                })}
                clear
              >
                备注
            </InputItem>
              <Picker
                {...getFieldProps('loginFlag', {
                  initialValue: [loginFlag ? Number(loginFlag) : 1]
                })}
                data={loginFlagDatas}
                cols={1}
                key="1"
              >
                <Item key="1" arrow="horizontal">
                  是否允许登录
              </Item>
              </Picker>
            </List>
            <WhiteSpace size="lg" />
            <WingBlank>
              <Button onClick={this.submit} type="primary">
                {this.props.match.params.type == 'edit' ? '修改' : '添加'}
              </Button>
              <WhiteSpace size="lg" />
              <Button onClick={() => this.props.history.goBack()}>返回</Button>
            </WingBlank>
            <WhiteSpace size="lg" />
          </form >
        </div>
      </div >
    )
  }
}
export default createForm()(AddAdmin)
