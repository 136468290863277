import React, { Component } from 'react'
import {
  NavBar,
  Icon,
  InputItem,
  List,
  Picker,
  Button,
  WhiteSpace,
  WingBlank,
  Checkbox
} from 'antd-mobile'
import { createForm } from 'rc-form'
import axios from '../../../service/axios'
const Item = List.Item
const CheckboxItem = Checkbox.CheckboxItem

const GpsInterval = [
  {
    label: <div key="1">关闭上报坐标</div>,
    value: 0
  },
  {
    label: <div key="2">1分钟</div>,
    value: 60
  },
  {
    label: <div key="3">5分钟</div>,
    value: 300
  },
  {
    label: <div key="4">10分钟</div>,
    value: 600
  }
]
export class AddUser extends Component {
  state = {
    colorValue: ['#00FF00'],
    userInfo: {},
    type: this.props.match.params.type,
    billList: []
  }
  componentDidMount() {
    console.log(this.props)
    this.getUserInfo()
    this.getBillsByPttUser()
  }
  getUserInfo() {
    axios
      .get('/api/oa/pttUser/info', {
        params: {
          uid: this.props.match.params.id
        }
      })
      .then(res => {
        console.log(res)
        if (res && res.status == 1000) {
          this.setState({
            userInfo: res.data
          })
        }
      })
  }
  getBillsByPttUser() {
    axios.get('/api/oa/pttBill/getBillsByPttUser').then(res => {
      console.log(res)
      if (res && res.status == 1000 && res.data) {
        let arr = []
        res.data.map((item, index) => {
          arr.push({
            label: (
              <div key={index} style={{ fontSize: '10px' }}>
                {item.id +
                  `(${item.endDate}前有效,${item.type == 0 ? '基础套餐' : ''}${
                  item.type == 1 ? '调度套餐' : ''
                  }${item.type == 2 ? '视频套餐' : ''}使用情况${item.number}/${
                  item.leftnum
                  })`}
              </div>
            ),
            value: item.id
          })
        })
        this.setState({
          billList: arr
        })
      }
    })
  }

  render() {
    const { getFieldProps, getFieldError } = this.props.form
    const { type, billList } = this.state
    const {
      account,
      name,
      password,
      imei,
      gpsInterval,
      billid,
      funcs
    } = this.state.userInfo

    return (
      <div className="adduser">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.push('./userlist')}
          >
            {type == 'edit' ? '修改用户' : '添加用户'}
          </NavBar>
        </div>
        <div className="sub-container">
          <form>
            <List>
              <Picker
                {...getFieldProps('billid', {
                  rules: [
                    { required: true, message: 'Please input account' },
                    { validator: this.validateAccount }
                  ],
                  initialValue: [billid]
                })}
                data={billList}
                cols={1}
                key="1"
              >
                <Item key="1" arrow="horizontal">
                  <i className="red-star">*</i>
                  选择账单号
                </Item>
              </Picker>
              <InputItem
                {...getFieldProps('account', {
                  rules: [
                    { required: true, message: '请输入账号' },
                    { validator: this.validateAccount },
                    {}
                  ],
                  initialValue: account
                })}
                clear
                error={!!getFieldError('account')}
                onErrorClick={() => {
                  alert(getFieldError('account').join('、'))
                }}
                placeholder="最大长度11，支持字母、数字"
                key="2"
              >
                <i className="red-star">*</i>
                账号
              </InputItem>
              <InputItem
                {...getFieldProps('name', {
                  rules: [
                    { required: true, message: '请输入账号' },
                    { validator: this.validateAccount }
                  ],
                  initialValue: name
                })}
                clear
                key="3"
              >
                名字
              </InputItem>
              <InputItem
                {...getFieldProps('name', {
                  rules: [
                    { required: true, message: '请输入密码' },
                    { validator: this.validateAccount }
                  ],
                  initialValue: password
                })}
                clear
                error={!!getFieldError('account')}
                onErrorClick={() => {
                  alert(getFieldError('account').join('、'))
                }}
                placeholder="6-12位数字、字母组合"
                key="4"
              >
                <i className="red-star">*</i>
                密码
              </InputItem>

              <InputItem
                {...getFieldProps('imei', {
                  rules: [
                    { required: true, message: 'Please input account' },
                    { validator: this.validateAccount }
                  ],
                  initialValue: imei
                })}
                clear
                key="6"
              >
                IMEI号
              </InputItem>
              <Picker
                {...getFieldProps('gpsInterval', {
                  rules: [
                    { required: true, message: 'Please input account' },
                    { validator: this.validateAccount }
                  ],
                  initialValue: [gpsInterval]
                })}
                data={GpsInterval}
                cols={1}
                key="7"
              >
                <Item key="1" arrow="horizontal">
                  <i className="red-star">*</i>
                  GPS周期
                </Item>
              </Picker>
              <InputItem
                {...getFieldProps('account', {
                  rules: [
                    { required: true, message: 'Please input account' },
                    { validator: this.validateAccount }
                  ]
                })}
                clear
                placeholder="用于批量添加，范围:1~500"
                disabled={this.state.type == 'edit'}
              >
                账号数
              </InputItem>
              <InputItem
                {...getFieldProps('startAcount', {
                  rules: [
                    { required: true, message: 'Please input account' },
                    { validator: this.validateAccount }
                  ],
                  initialValue: 1
                })}
                key="8"
                clear
              >
                开始账号
              </InputItem>
              <Item arrow="horizontal" extra={''} key="9">
                <i className="red-star">*</i>
                默认群组
              </Item>
              <Item arrow="horizontal" extra={''} key="10">
                所属群组
              </Item>
            </List>
            <List renderHeader="权限">
              <CheckboxItem checked={String(funcs).indexOf(0) > -1} >会话</CheckboxItem>
              <CheckboxItem checked={true} >切组</CheckboxItem>
              <CheckboxItem checked={String(funcs).indexOf(2) > -1}>单呼</CheckboxItem>
              <CheckboxItem checked={String(funcs).indexOf(9) > -1}>跨组单呼</CheckboxItem>
              <WhiteSpace />
              <WhiteSpace />
              <WingBlank>
                <Button type="primary">确定</Button>
              </WingBlank>
              <WhiteSpace />
              <WhiteSpace />
              <WhiteSpace />
            </List>
          </form>
        </div>
      </div>
    )
  }
}
const AddUserForm = createForm()(AddUser)
export default AddUserForm
