import React, { Component } from 'react'
import {
  NavBar,
  Icon,
  List,
  Button,
  SegmentedControl,
  Toast,
  Modal,
  ActionSheet,
  WhiteSpace,
  Pagination,
  WingBlank
} from 'antd-mobile'
import axios from '../../../../service/axios'
import http from '../../../../utils/request'
const Item = List.Item
const alert = Modal.alert
let wrapProps
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(
  window.navigator.userAgent
)
if (isIPhone) {
  wrapProps = {
    onTouchStart: e => e.preventDefault()
  }
}

export class newGroupUserList extends Component {
  test = i => {
    console.log(i)
  }
  state = {
    type: 'priority', //list 群组列表/priority 优先级
    userList: [],
    uids: [],
    pageTotalNum: 0,
    pageNo: 1,
    pageSize: 100,
    prioritys: [],
  }
  prioritys = []
  componentDidMount() {
    this.getGroupUserList(1)
  }

  getGroupUserList(e) {
    http
      .post('/api/group/members_priority/get', {
        page: {
          pageNo: 1,
          pageSize: 3000,
        },
        gid: this.props.match.params.gid
      },'new')
      .then(res => {
        console.log(res)
        if (res && res.status == 200 ) {
          this.setState({
            userList: res.data.resultList,
            uids: res.data.resultList.map(item => item.uid),
            pageNo: res.data.pageNo,
            pageTotalNum: Math.ceil(res.data.totalCount / this.state.pageSize)
          })
        }
      })
  }
  //保存修改
  saveUpdate() {
    const BUTTONS = ['确定', '取消']
    ActionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        message: '是否保存修改？',
        maskClosable: true,
        'data-seed': 'logId'
      },
      async buttonIndex => {
        console.log(buttonIndex)
        if (!buttonIndex) {
          await this.setGroupPriority()
          // await this.updateGroupUsers()
        }
      }
    )
  }

  // 设置群组成员的优先级
  setGroupPriority() {
    return new Promise(rev => {
      console.log('this.prioritys',this.prioritys)
      http
        .post('/api/group/members_priority/set', {
          gid: this.props.match.params.gid,
          prioritys: this.prioritys
        },'new')
        .then(res => {
          console.log(res)
          if (res) {
            if (res.status == 200) {
              Toast.success(res.message, 1 ,() => {
                this.props.history.goBack();
              })
              rev()
            } else {
              Toast.fail(res.message)
            }
          }
        })
    })
  }

  //修改群组成员
  updateGroupUsers() {
    return new Promise(rev => {
      let uids = [...this.state.uids]
      axios
        .post('/api/oa/group/edit', {
          name: this.props.match.params.name,
          gid: this.props.match.params.gid,
          members: uids.join(',')
        })
        .then(res => {
          console.log(res)
          if (res && res.status == 1000) {
          }
          rev()
        })
    })
  }
  //删除群组成员
  deleteUser(index) {
    let arr = [...this.state.userList]
    arr.splice(index, 1)
    this.setState({
      userList: arr,
      uids: arr.map(item => item.uid)
    })
    this.prioritys = arr.map(item => item.priority)
  }
  onSegmentedChange(e, index, i) {
    let prioritys = {
      ...i,
      userPriority: e.nativeEvent.selectedSegmentIndex + 1,
    };
    let prioritysArr = [...this.prioritys];
    let prioritysUidArr = prioritysArr.map(item => item.uid);
    if (prioritysUidArr.includes(i.uid)) {
      prioritysArr.splice(prioritysUidArr.indexOf(i.uid), 1);
    }
    prioritysArr.push(prioritys);
    this.prioritys = prioritysArr;
  }

  onPageChange(e) {
    console.log(e);
    this.getGroupUserList(e);
  }

  render() {
    const data = []
    for (let i = 0; i < 100; i++) {
      data.push({
        value: i,
        label: 'zhaoyu' + i + '-' + i
      })
    }
    const { userList, pageTotalNum, pageNo } = this.state

    return (
      <div className="selectList">
        <div className="header-sticky">
          {this.state.type == 'list' ? (
            <NavBar
              icon={<Icon type="left" />}
              onLeftClick={() => this.props.history.goBack()}
            >
              群组成员
            </NavBar>
          ) : (
              <NavBar
                icon={<Icon type="left" />}
                onLeftClick={() => this.props.history.goBack()}
                rightContent={[
                  <Icon onClick={() => this.saveUpdate()} key="1" type="check" />
                ]}
              >
                组员优先级
            </NavBar>
            )}
        </div>
        <div className="sub-container">
          <List>
            {userList.map((i, index) => (
              <div key={index}>
                {this.state.type == 'list' ? (
                  <Item
                    key={index}
                  // extra={<Icon type="cross" onClick={() => this.test(i)} />}
                  >
                    {i.memberName}
                  </Item>
                ) : (
                    <Item
                      key={index + 'b'}
                      // thumb={
                      //   <Icon
                      //     style={{ color: 'red' }}
                      //     onClick={() => this.deleteUser(index)}
                      //     type="cross"
                      //   />
                      // }
                      extra={
                        <SegmentedControl
                          key={index + 'c'}
                          selectedIndex={i.userPriority - 1}
                          values={['初级', '中级', '高级']}
                          onChange={e => this.onSegmentedChange(e, index, i)}
                        />
                      }
                    >
                      {i.account}
                    </Item>
                  )}
              </div>
            ))}
          </List>
          <WhiteSpace />
          <WingBlank>
            {pageTotalNum > 0 ? (
              <Pagination
                total={pageTotalNum}
                current={pageNo}
                locale={{
                  prevText: "上一页",
                  nextText: "下一页"
                }}
                onChange={e => this.onPageChange(e)}
              />
            ) : (
                ""
              )}
          </WingBlank>
        </div>
      </div>
    )
  }
}

export default newGroupUserList
