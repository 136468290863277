import axios from "axios";

var instance = axios.create({
  timeout: 150000,
  withCredentials: false,
});

instance
  // .post("http://scanhold.hawk-sight.com/saoma/token.php") // 正式环境请求参数地址
  // .post("http://temp.hawk-sight.com:72/saoma/token.php")  // 测试环境请求参数地址
  .post("http://tt-saoma.poc-dispatch.com/saoma_tietong/token.php") // 测试环境请求参数地址2
  .then((res) => {
    // res = JSON.parse(res);
    if (res.status == 200) {
      console.log("这是配置的", res);
      let configData = {
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名，见附录1
        jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      };
      window.wx.config(configData);
      localStorage.setItem('configData', JSON.stringify(configData))
      window.wx.ready(() => {
        console.log("微信扫码初始化完成");
      });
    }
  })
  .catch((err) => { });
