import React, { Component } from 'react'
import {
  NavBar,
  Icon,
  List,
  WhiteSpace,
  WingBlank,
  Button,
  Checkbox,
  Toast,
  Pagination,
  Flex,
  SearchBar
} from 'antd-mobile'
import axios from '../../../../service/axios'
import http from '../../../../utils/request'
import './addGroup.scss'
import Search from "../../../../components/search/search";
import NoneDatas from "../../../../components/noneDatas";
import { connect } from 'dva'
const userMsg =
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg"));
const selectedContainer = {
  position: 'fixed',
  width: '100%',
  top: '45px',
  background: '#f5f5f5',
  height: '100%',
  zIndex: '11',
  transition: 'left 0.4s'
}
const CheckboxItem = Checkbox.CheckboxItem
const Item = List.Item
export class SelectUser extends Component {
  state = {
    listDatas: [],
    drawerLeft: document.body.clientWidth,
    drawerSearch: document.body.clientWidth,
    pageTotalNum: 0,
    pageNo: 1,
    selectAll: false,
    searchKey:{},
    uname:'',
  }
  onChange = (e, i) => {
    console.log(e, i)
    if (e.target.checked) {
      this.props.addSelectUserList(i)
    } else {
      this.props.removeSelectUserList(i)
    }
  }
  componentDidMount() {
    this.getUserList(1)
  }
  componentWillUnmount(e) {
    this.setState = (state, callback) => {
      return
    }
  }
  // 查询用户提交
  submit(val){
    this.setState({
      uname:val,
    },()=>{
      this.getUserList(1)
    })
  }
  // 全查
  getUserList(e) {
    Toast.loading('加载中...', 0)
    console.log('')
    http
      .get('/api/group/no_members', {
          pageSize: 10,
          pageNo: e,
          uname:this.state.uname,
          ...this.state.searchKey,
          searchMy: userMsg && userMsg.userType != 4 ? 1 : null
      },'new')
      .then(res => {
        Toast.hide()
        if (res && res.status == 200 && res.data) {
          this.setState({
            listDatas: res.data.resultList || [],
            pageTotalNum: Math.ceil(res.data.totalCount / 10),
            pageNo: res.data.pageNo
          })
        }
        Toast.hide()
      })
      .catch(res => {
        Toast.hide()
      })
  }
  // 将查询条件传递
  onSearchList = e => {
    this.setState({
      searchKey: {
        ...e,
      },
      drawerSearch: document.body.clientWidth,
    });
    setTimeout(() => {
      this.getUserList(1);
    }, 0);
  };

  onPageChange(e) {
    this.getUserList(e)
    this.setState({
      selectAll: false
    })
  }
  selectAll(e) {
    this.setState({
      selectAll: e.target.checked
    })
    if (e.target.checked) {
      console.log(this.state.listDatas)
      this.props.selectAll(this.state.listDatas)
    } else {
      this.props.setEmpty(this.state.listDatas)
    }
  }
  render() {
    const { listDatas, drawerLeft, drawerSearch, pageNo, pageTotalNum, selectAll } = this.state
    const {
      selectUserList,
      selectUserListUidArr,
      removeSelectUserList
    } = this.props

    return (
      <div
        className="selectUser"
        style={{ height: '90%', overflowY: 'scroll' }}
      >
        <div className="sub-container">
          {/* <div className="am-list-header" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <span>请选择群组成员</span>
            
            <Button
              size='small'
              style={{width:'90px'}}
              onClick={()=>
                this.setState(
                  { drawerSearch: 0 }
                )
              }
            >查找成员
            </Button>

          </div> */}
          <SearchBar
            onSubmit={value => this.submit(value)}
            placeholder="请输入名称或者账号"
            maxLength={20} />
          <div className="am-list-header">请选择群组成员</div>
          <div className="title_name">
            <div>名称</div>
            <div>账号</div>
          </div>
          <List>
            {listDatas.map((i, index) => (
              <CheckboxItem
                key={index}
                checked={selectUserListUidArr.includes(i.uid)}
                onChange={e => this.onChange(e, i)}
              >
                <span style={{color:"rgba(0,0,0,.6)",flex:1}}>{i.name}</span>　<span style={{color:"rgb(0,0,0)",flex:1}}>{i.account}</span>
              </CheckboxItem>
            ))}
          </List>
          <div className="footer">
            <CheckboxItem checked={selectAll} onChange={(e) => this.selectAll(e)}></CheckboxItem>
            <span
              onClick={() =>
                this.setState({
                  drawerLeft: 0
                })
              }
            >
              {selectUserList.length}人
            </span>
            <Button
              type="warning"
              inline
              size="small"
              onClick={() => this.props.closeDrawer()}
            >
              返回
            </Button>
            <Button
              type="primary"
              inline
              size="small"
              onClick={() => this.props.closeDrawer()}
            >
              确定
            </Button>
          </div>
          <div
            style={{
              ...selectedContainer,
              left: drawerLeft + 'px',
              height: '95%',
              overflowY: 'scroll',
              paddingBottom: '45'
            }}
          >
            <List renderHeader={() => '请选择群组成员'}>
              {selectUserList.map((i, index) => (
                <Item
                  key={index}
                  extra={
                    <Icon
                      type="cross"
                      onClick={() => removeSelectUserList(i)}
                    />
                  }
                >
                  {i.account}
                </Item>
              ))}
              <WhiteSpace size="xl" />
              <WingBlank>
                <Button
                  onClick={() =>
                    this.setState({
                      drawerLeft: document.body.clientWidth
                    })
                  }
                >
                  关闭
                </Button>
                <WhiteSpace size="xl" />
              </WingBlank>
            </List>
          </div>
          <WhiteSpace size="xl" />
          <WingBlank>
            {pageTotalNum > 0 ? (
              <Pagination
                total={pageTotalNum}
                current={pageNo}
                locale={{
                  prevText: '上一页',
                  nextText: '下一页'
                }}
                onChange={e => this.onPageChange(e)}
              />
            ) : (
                ''
              )}
          </WingBlank>
          <div
            className="search"
            style={{ ...selectedContainer, left: drawerSearch }}
          >
            <Search
              closeSearch={() =>
                this.setState({
                  drawerSearch: document.body.clientWidth,
                })
              }
              type="user"
              onSearchList={this.onSearchList}
            />
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    selectUserList: state.userSelectList.selectUserList,
    selectUserListUidArr: state.userSelectList.selectUserListUidArr
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteSelectUserList: () => {
      dispatch({
        type: 'userSelectList/deleteSelectUserList'
      })
    },
    removeSelectUserList: data => {
      dispatch({
        type: 'userSelectList/removeSelectUserList',
        data
      })
    },
    addSelectUserList: data => {
      dispatch({
        type: 'userSelectList/addSelectUserList',
        data
      })
    },
    selectAll: data => {
      console.log(data)
      dispatch({
        type: 'userSelectList/setSelectAll',
        data
      })
    },
    setEmpty: data => {
      dispatch({
        type: 'userSelectList/setEmpty',
        data
      })
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectUser)
