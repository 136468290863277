export const formatTimeStamp = stamp => {
  /*根据时间戳格式换日期*/
  var u = navigator.userAgent;
  var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
  if (!stamp) {
    return "";
  }
  if (!isAndroid) {
    stamp = stamp.replace(/\-/g, "/");
  }
  console.log(stamp);
  let time = new Date(stamp);
  let y = time.getFullYear(); //年
  let m = time.getMonth() + 1; //月
  let d = time.getDate(); //日
  let h = time.getHours(); //时
  let mm = time.getMinutes(); //分
  mm = mm.toString();
  if (mm.length < 2) {
    mm = "0" + mm;
  }
  let s = time.getSeconds().toString(); //秒
  if (s.length < 2) {
    s = "0" + s;
  }
  return `${y}-${m}-${d}`;
};
export const formDateTime1 = stamp => {
  let time = new Date(stamp);
  let y = time.getFullYear();
  let m = (time.getMonth() + 1)<10?('0'+(time.getMonth() + 1)):(time.getMonth() + 1);
  let d = time.getDate()<10?('0'+time.getDate()):time.getDate();
  return `${y}-${m}-${d} 00:00:00`;
};
export const formDateTime = stamp => {
  let time = new Date(stamp);
  let y = time.getFullYear();
  let m = (time.getMonth() + 1)<10?('0'+(time.getMonth() + 1)):(time.getMonth() + 1);
  let d = time.getDate()<10?('0'+time.getDate()):time.getDate();
  let h = time.getHours()<10?('0'+time.getHours()):time.getHours();
  let mm = time.getMinutes()<10?('0'+time.getMinutes()):time.getMinutes();
  return `${y}-${m}-${d} ${h}:${mm}:00`;
};
