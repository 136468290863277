import App from '../App'
import Login from '../routes/login/login'
import Menu from '../routes/menu/menu'
import UserList from '../routes/userManage/userList/userList'
import UserFriend from '../routes/userManage/userAdd/userFriends'
import UserListSearch from '../routes/userManage/userList/userListSearch/userListSearch'
import AddUser from '../routes/userManage/userAdd/userAdd'
import CurrencyLedgerList from '../routes/currencyLedger/currencyLedger'
import BindingIccid from '../routes/BindingIccid'
import AddAdmin from '../routes/adminManage/addAdmin/addAdmin'
import Adminlist from '../routes/adminManage/adminList'
import UserSelectList from '../routes/userManage/userAdd/userSelectList'
import UserSelectGroup from '../routes/userManage/userAdd/userSelectGroup'
import BillExchange from '../routes/billManage/billExchange/billExchange'
import AdminDetail from '../routes/adminManage/adminDetail/adminDetail'
import AdminNum from '../routes/adminManage/adminDetail/adminNum'
import AdminCurrencyNum from '../routes/adminManage/adminDetail/adminCurrencyNum'
import AdminPermission from "../routes/adminManage/adminDetail/adminPermission";
import BillList from '../routes/billManage/billList/billList'
import BillDetails from '../routes/billManage/billDetail/billDetail'
import BillMessage from '../routes/billManage/billMessage/billMessage'
import Addgroup from '../routes/groupManage/addGroup/addGroup'
import Selectuser from '../routes/groupManage/addGroup/selectUser'
import SelectList from '../routes/groupManage/addGroup/selectList'
import GroupList from '../routes/groupManage/groupList/groupList'
import GroupDetails from '../routes/groupManage/groupList/groupDetails'
import GroupUserList from '../routes/groupManage/groupList/groupUserList'
import GroupCombination from '../routes/groupManage/groupCombination/index'
import EditUser from '../routes/userManage/userList/userEdit'
import RemoteWrite from '../routes/userManage/userAdd/remoteWrite'
import userList from '../models/userList'
import BingWeiXin from '../routes/bingWeiXin/bingWeiXin'
import SimInfo from '../routes/simManage/simInfo/simInfo'
import SimFlowPool from '../routes/simManage/simFlowPool/simFlowPool'
import {locationMsg} from '../routes/locationMsg/index';

import newUserList from '../routes/userManage/newUserManage/userList/userList'
import newAddUser from '../routes/userManage/newUserManage/userAdd/userAdd'
import newRemoteWrite from '../routes/userManage/newUserManage/userAdd/remoteWrite'
import newUserFriends from '../routes/userManage/newUserManage/userAdd/userFriends'
import newUserFuncRenew from '../routes/userManage/newUserManage/userAdd/userFuncRenew'

import  {newlocationMsg}  from '../routes/locationMsg/newLocationMsg/index'

import newGroupList from '../routes/groupManage/newGroupManage/groupList/groupList'
import newAddgroup from '../routes/groupManage/newGroupManage/addGroup/addGroup'
import newGroupUserList from '../routes/groupManage/newGroupManage/groupList/groupUserList'
import newGroupCombination from '../routes/groupManage/groupCombination/newGroupCombination'

import newAdminList from '../routes/adminManage/newAdminManage/adminList'
import newAddAdmin from '../routes/adminManage/newAdminManage/addAdmin/addAdmin'
import newAdminDetail from '../routes/adminManage/newAdminManage/adminDetail/adminDetail'
import newAdminnum from '../routes/adminManage/newAdminManage/adminDetail/adminNum'
import newAdminPermission from '../routes/adminManage/newAdminManage/adminDetail/adminPermission'

import clockinImeiSdk from '../routes/clockinSdk/clockinImei/clockinsdk'
import Dakatale from '../routes/clockinSdk/clockinImei/dakatale/Dakatale'
import Tdaka from '../routes/clockinSdk/clockinImei/Tdaka/Tdaka'
import Uplist from '../routes/clockinSdk/clockinImei/uplist/uplist'

import newBindingIccid from '../routes/BindingIccid/newbinbingIccid'

const menuGlobal = [
  {
    id: 'app',
    pid: '0',
    name: 'app',
    path: '/',
    // models: () => [import('./models/aaa')],
    component: () => App
  },
  {
    id: 'login',
    pid: '0',
    name: 'login',
    path: '/login',
    // models: () => [import('./models/aaa')],
    component: () => Login
  },
  {
    id: 'menu',
    pid: '1',
    name: 'menu',
    path: '/menu',
    models: () => [import('../models/group.js')],
    component: () => Menu
  },
  {
    id: 'userlist',
    pid: '2',
    name: 'userlistssss',
    path: '/userlist',
    models: () => [userList],
    component: () => UserList
  }, {
    id: 'userfriend',
    pid: '2',
    name: 'userfriend',
    path: '/userfriend/:uid?',
    models: () => [userList],
    component: () => UserFriend
  },
  {
    id: 'userlistsearch',
    pid: '3',
    name: 'userlistsearch',
    path: '/userlistsearch',
    // models: () => [import('./models/ccc')],
    component: () => UserListSearch
  },
  {
    id: 'adduser',
    pid: '2',
    name: 'adduser',
    path: '/adduser/:id?/:type?/:searchMy?',
    models: () => [import('../models/group.js')],
    component: () => AddUser
  },
  {
    id: 'remotewrite',
    pid: '2',
    name: 'remotewrite',
    path: '/remotewrite/:id?/:name?/:imei?',
    models: () => [import('../models/group.js')],
    component: () => RemoteWrite
  },
  {
    id: 'useredit',
    pid: '2',
    name: 'useredit',
    path: '/useredit/:id?/:type?',
    // models: () => [import('./models/ccc')],
    component: () => EditUser
  },
  {
    id: 'myuserlist',
    pid: '2',
    name: 'myuserlist',
    path: '/myuserlist/:searchMy?',
    models: () => [userList],
    component: () => UserList
  },
  {
    id: 'bindingIccid',
    pid: '2',
    name: 'bindingIccid',
    path: '/bindingIccid',
    // models: () => [import('./models/ccc')],
    component: () => BindingIccid
  },
  {
    id: 'currencyledgerlist',
    pid: '2',
    name: 'currencyledgerlist',
    path: '/currencyledgerlist',
    // models: () => [import('./models/ccc')],
    component: () => CurrencyLedgerList
  },
  {
    id: 'addadmin',
    pid: '2',
    name: 'addadmin',
    path: '/addadmin/:id?/:type?',
    // models: () => [import('./models/ccc')],
    component: () => AddAdmin
  },
  {
    id: 'adminlist',
    pid: '2',
    name: 'adminlist',
    path: '/adminlist',
    // models: () => [import('./models/ccc')],
    component: () => Adminlist
  },
  {
    id: 'admindetail',
    pid: '2',
    name: 'admindetail',
    path: '/admindetail/:id?',
    // models: () => [import('./models/ccc')],
    component: () => AdminDetail
  }, {
    id: 'adminnum',
    pid: '2',
    name: 'adminnum',
    path: '/adminnum/:id?/:type?',
    // models: () => [import('./models/ccc')],
    component: () => AdminNum
  }, {
    id: 'adminCurrencyNum',
    pid: '2',
    name: 'adminCurrencyNum',
    path: '/adminCurrencyNum/:id?/:type?',
    // models: () => [import('./models/ccc')],
    component: () => AdminCurrencyNum
  },
  {
    id: "adminPermission",
    pid: "2",
    name: "adminPermission",
    path: "/adminPermission/:id?",
    component: () => AdminPermission,
  },
  {
    id: 'userselectlist',
    pid: '2',
    name: 'userselectlist',
    path: '/userselectlist',
    // models: () => [import('./models/ccc')],
    component: () => UserSelectList
  },
  {
    id: 'userselectgroup',
    pid: '2',
    name: 'userselectgroup',
    path: '/userselectgroup',
    models: () => [import('../models/group.js')],
    component: () => UserSelectGroup
  },
  {
    id: 'grouplist',
    pid: '2',
    name: 'grouplist',
    path: '/grouplist',
    // models: () => [import('./models/ccc')],
    component: () => GroupList
  },
  {
    id: 'addgroup',
    pid: '2',
    name: 'addgroup',
    path: '/addgroup/:type?/:gid?/:searchMy?',
    models: () => [import('../models/group/userSelectList')],
    component: () => Addgroup
  },
  {
    id: 'selectuser',
    pid: '2',
    name: 'selectuser',
    path: '/selectuser',
    models: () => [import('../models/group/userSelectList')],
    component: () => Selectuser
  },
  {
    id: 'selectlist',
    pid: '2',
    name: 'selectlist',
    path: '/selectlist',
    models: () => [import('../models/group/userSelectList')],
    component: () => SelectList
  },
  {
    id: 'groupdetails',
    pid: '2',
    name: 'groupdetails',
    path: '/groupdetails',
    // models: () => [import('./models/ccc')],
    component: () => GroupDetails
  },
  {
    id: 'groupuserlist',
    pid: '2',
    name: 'groupuserlist',
    path: '/groupuserlist/:gid?/:name?',
    // models: () => [import('./models/ccc')],
    component: () => GroupUserList
  },
  {
    id: 'groupcombination',
    pid: '2',
    name: 'groupcombination',
    path: '/groupcombination',
    // models: () => [import('./models/ccc')],
    component: () => GroupCombination
  },
  {
    id: 'mygrouplist',
    pid: '2',
    name: 'mygrouplist',
    path: '/mygrouplist/:searchMy?',
    // models: () => [import('./models/ccc')],
    component: () => GroupList
  },
  {
    id: 'billexchange',
    pid: '2',
    name: 'billexchange',
    path: '/billexchange',
    // models: () => [import('./models/ccc')],
    component: () => BillExchange
  },
  {
    id: 'billlist',
    pid: '2',
    name: 'billlist',
    path: '/billlist',
    // models: () => [import('./models/ccc')],
    component: () => BillList
  },
  {
    id: 'billdetails',
    pid: '2',
    name: 'billdetails',
    path: '/billdetails/:id?/:searchMy?',
    // models: () => [import('./models/ccc')],
    component: () => BillDetails
  },
  {
    id: 'billmessage',
    pid: '2',
    name: 'billmessage',
    path: '/billmessage',
    // models: () => [import('./models/ccc')],
    component: () => BillMessage
  },
  {
    id: 'mybilllist',
    pid: '2',
    name: 'mybilllist',
    path: '/mybilllist/:searchMy?',
    // models: () => [import('./models/ccc')],
    component: () => BillList
  },
  {
    id: 'bingWeiXin',
    pid: '2',
    name: 'bingWeiXin',
    path: '/bingWeiXin/:code?',
    // models: () => [import('./models/ccc')],
    component: () => BingWeiXin
  },
  {
    id: 'simInfo',
    pid: '2',
    name: 'simInfo',
    path: '/simInfo',
    // models: () => [import('./models/ccc')],
    component: () => SimInfo
  },
  {
    id: 'simflowpool',
    pid: '2',
    name: 'simflowpool',
    path: '/simflowpool',
    component: () => SimFlowPool
  },
  {
    id: 'locationMsg',
    pid: '7',
    name: 'locationMsg',
    path: '/locationMsg',
    // models: () => [import('./models/ccc')],
    component: () => locationMsg
  },
  {
    id: 'newUserList',
    pid: '2',
    name: 'newUserList',
    path: '/newUserList',
    models: () => [userList],
    component: ()=> newUserList
  },
  {
    id: 'newAddUser',
    pid: '2',
    name: 'newAddUser',
    path: '/newAddUser/:id?/:type?/:searchMy?',
    models: () => [import('../models/group.js')],
    component: () => newAddUser
  },
  {
    id: 'newRemotewrite',
    pid: '2',
    name: 'newRemotewrite',
    path: '/newRemotewrite/:id?/:name?/:imei?',
    models: () => [import('../models/group.js')],
    component: () => newRemoteWrite
  },
  {
    id: 'newUserFriends',
    pid: '2',
    name: 'newUserFriends',
    path: '/newUserFriends/:uid?',
    models: () => [userList],
    component: () => newUserFriends
  },
  {
    id: 'newUserFuncRenew',
    pid: '2',
    name: 'newUserFuncRenew',
    path: '/newUserFuncRenew/:uid?/:type?/:userType?',
    component: () => newUserFuncRenew
  },
  {
    id: 'newLocation',
    pid: '2',
    name: 'newLocation',
    path: '/newLocationMsg',
    component: () => newlocationMsg
  },
  {
    id: 'newGroupList',
    pid: '2',
    name: 'newGroupList',
    path: '/newGroupList',
    component: ()  => newGroupList
  },
  {
    id: "newAddgroup",
    pid: "2",
    name: "newAddgroup",
    path: "/newAddgroup/:type?/:gid?/:name?",
    models: () => [import("../models/group/userSelectList")],
    component: () => newAddgroup,
  },
  {
    id: 'newGroupUserList',
    pid: '2',
    name: 'newGroupUserList',
    path: '/newGroupUserList/:gid?/:name?',
    component: () => newGroupUserList
  },
  {
    id: 'newGroupCombination',
    pid: '2',
    name: 'newGroupCombination',
    path: '/newGroupcombination',
    component: () => newGroupCombination
  },
  {
    id: 'newAdminList',
    pid: '2',
    name: 'newAdminList',
    path: '/newAdminList',
    component: () => newAdminList
  },
  {
    id: 'newAddAdmin',
    pid: '2',
    name: 'newAddAdmin',
    path: '/newAddAdmin/:loginName?/:type?/:id?',
    component: () => newAddAdmin
  },
  {
    id: 'newAdminDetail',
    pid: '2',
    name: 'newAdminDetail',
    path: '/newAdminDetail/:name?/:flag?/:id?',
    component: () => newAdminDetail
  },
  {
   id: 'newAdminnum',
   pid: '2',
   name: 'newAdminnum',
   path: '/newAdminnum/:name/:type/:id',
   component: () => newAdminnum
  },
  {
    id: 'newAdminPermission',
    pid: '2',
    name:'newAdminPermission',
    path:'/newAdminPermission/:id?',
    component: () => newAdminPermission
  },
  // IMEI扫码页
  {
    id: 'clockinImeiSdk',
    pid:'2',
    name: 'clockinImeiSdk',
    path: '/clockinsdk',
    component: () => clockinImeiSdk
  },
  {
    id: 'Dakatale',
    pid: '2',
    name: 'Dakatale',
    path: '/Dakatale',
    component: () => Dakatale
  },
  {
    id: 'Tdaka',
    pid: '2',
    name: 'Tdaka',
    path: '/Tdaka',
    models: () => [import('../models/group.js')],
    component: () => Tdaka
  },
  {
    id: 'uplist',
    pid: '2',
    name: 'uplist',
    path: '/uplist',
    component: () => Uplist
  },
  {
    id: 'newBindingIccid',
    pid: '2',
    name: 'newBindingIccid',
    path: '/newBindingIccid',
    component: () => newBindingIccid
  },
]

export default menuGlobal
