import axios from "axios";
import { Toast } from "antd-mobile";

// 配置请求地址
// axios.defaults.baseURL = window.CONFIG_URL.baseURL;    // 测试环境
let baseURL = window.CONFIG_URL.baseURL
let baseURL2 = window.CONFIG_URL2.baseURL

axios.defaults.withCredentials = true;
//超时时间为60s
axios.defaults.timeout = 30000;
//响应拦截器

axios.interceptors.request.use(
  function(config) {
    console.log('axios.interceptors.request',config)
    const token =
      sessionStorage.getItem("loginMsg") &&
      JSON.parse(sessionStorage.getItem("loginMsg")).token;
    switch(config.urlType){
      case 'new':
        config.url = baseURL2 + config.url
        break
      default : 
        config.url = baseURL + config.url 
    }
    
    if (config.urlType) { 
      console.log('-----------new axios')
      const accessToken = sessionStorage.getItem('pc-access-token')
      const webSession = sessionStorage.getItem('manager-web-session')
      config.headers["pc-access-token"] = accessToken;
      config.headers["manager-web-session"] = webSession;
      config.params = {
        // token: token,
        ...config.params
      }
      
    } else {
      console.log('-----------old axios')
      config.headers["token"] = token;
      config.params = {
        ...config.params
      };
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    console.log('axios.interceptors.response',response);
    Toast.hide();
    if (response.status == 200 && response.data) {
      if (response.data.status == -1001) {
        //登录超时
        sessionStorage.removeItem("loginMsg");
        sessionStorage.removeItem("adminType");
        // Toast.info('登录超时，请重新登录', 1, () => {
        window.location.reload();
        // })
      } else if (response.data.status == 403){
        sessionStorage.removeItem("loginMsg");
        sessionStorage.removeItem("adminType");
        window.location.reload();
      } else {
        return response.data;
      }
    } else {
      return null;
    }
  },
  error => {
    console.log(error);
    Toast.hide();
    Toast.info("操作失败", 1);
  }
);
export default axios;
