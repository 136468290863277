import React, { Component } from 'react'
import {
  Button,
  WhiteSpace,
  WingBlank,
  InputItem,
  Flex,
  Toast,
  Picker,
} from 'antd-mobile'
import company_logo from '../../assets/images/admin_logo.png'
import './login.scss'
import axios from '../../service/axios'
import http from '../../utils/request'

const userTypeList = [
  {
    label: <div key="1">币系统登录</div>,
    value: 5,
  },
  {
    label: <div key="2">账单系统登录</div>,
    value: 0,
  },
  
];
export class Login extends Component {
  state = {
    loading: false,
    username: '',
    password: '',
    userType: 5,
    userTypeName: '币系统登录'
  }
  onChangeUserName = value => {
    console.log(value)
    this.setState({
      username: value
    })
  }
  onChangePassword = value => {
    console.log(value)
    this.setState({
      password: value
    })
  }
  validateInfo = () => {
    return new Promise(rev => { })
  }
  onChangeUserType = value => {
    console.log('userType', value[0])
    let name = value[0] == 5 ? '币系统登录' : '账单系统登录'
    this.setState({
        userType: value[0],
        userTypeName: name
    });
  }
  onLoginBtn = () => {
    const { username, password, userType } = this.state
    if (!username) {
      Toast.info('请填写用户名！', 2)
      return
    }
    if (!password) {
      Toast.info('请填写密码！', 2)
      return
    }
    this.setState({
      loading: true
    })

    // 请求登录
    if (userType == 0) {
      axios.get('/oa/login', {
        params: {
          username: username,
          password: password
        }
      }).then(res => {
        console.log(res)
        this.setState({
          loading: false
        }
      )
        if (res) {
          if (res.data && res.status == 1000) {
            sessionStorage.setItem('loginMsg', JSON.stringify(res.data))
            sessionStorage.setItem('adminType', JSON.stringify(userType))
            Toast.info('登录成功', 2)
            window.location.reload()
          } else {
            Toast.info(res.message, 2)
          }
        }
      })
      .catch(res => {
        this.setState({
          loading: false
        })
      })
    } else {
      http.post( '/auth/open/login', {
          loginName: username,
          password: password
      },'new').then(res => {
        console.log(res)
        this.setState({
          loading: false
        })
        if (res) {
          if (res.data && res.status == 200) {
            sessionStorage.setItem('loginMsg', JSON.stringify(res.data))
            sessionStorage.setItem('adminType', JSON.stringify(userType))
            sessionStorage.setItem('manager-web-session', res.data["manager-web-session"])
            sessionStorage.setItem('pc-access-token', res.data["token"])
            this.getAdminInfo(res.data.loginUser)
          } else {
            Toast.info(res.message, 2)
          }
        }
      })
      .catch(res => {
        this.setState({
          loading: false
        })
      })
    }

  }
  async getAdminInfo(loginUser) {
    let preferentialMonths = 0;
    let userValidYears = 0;
    let permissionCodeList = [];
    //获取设置优惠年限
    await http.get("/api/user/admin/preferentialMonths/get", {}, 'new')
      .then(res => {
        if (res.status == 200 && res.data) {
          preferentialMonths = res.data;
        }
      })
    //获取账号有效年限
    await http.get("/api/user/admin/validYears/get", {}, 'new')
      .then(res => {
        if (res.status == 200 && res.data) {
          userValidYears = res.data;
        }
      })
    //获取管理员权限
    await http.get("/api/admin/permission/find/adminId", {
        adminId: loginUser.id
      }, 'new')
      .then(res => {
        if (res.status == 200 && res.data) {
          permissionCodeList = res.data.map(item => item.code);
        }
      })
    sessionStorage.setItem(
      "loginMsg",
      JSON.stringify({ ...loginUser, preferentialMonths, userValidYears, permissionCodeList }),
    )
    Toast.info("登录成功", 2);
    window.location.reload();
  }
  render() {
    return (
      <div className="login">
        <WingBlank>
          <div className="login-top">
            <Flex justify="center">
              <img src={company_logo} alt="" />
            </Flex>
            <Flex justify="center">
              <h2>铁通集团对讲管理平台</h2>
            </Flex>
          </div>
          <div className="middle">
            <div className=" middle-info">
              <InputItem
                onChange={this.onChangeUserName}
                clear
                placeholder="用户名"
              />
            </div>
            <div className=" middle-info">
              <InputItem
                onChange={this.onChangePassword}
                clear
                type="password"
                placeholder="密码"
              />
            </div>
          </div>
          <div className="bottom">
            <Button
              loading={this.state.loading}
              disabled={this.state.loading}
              type="primary"
              onClick={this.onLoginBtn}
            >
              登录
            </Button>
          </div>
          <div className="register">
            <Picker
              data={userTypeList}
              cols={1}
              onChange={this.onChangeUserType}
            >
              <Button>登录方式：{this.state.userTypeName}</Button>
            </Picker>
          </div>
          <WhiteSpace />
          <WhiteSpace />
          <div>
            <Flex justify="center">
              <span>v1.3.0</span>
            </Flex>
          </div>
        </WingBlank>
      </div>
    )
  }
}

export default Login
