import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  Button,
  Checkbox,
  ActionSheet,
  Toast,
  WhiteSpace,
  Pagination,
  WingBlank
} from "antd-mobile";
import "./groupList.scss";
import axios from "../../../../service/axios";
import http from "../../../../utils/request";
import GroupSearch from "../../../../components/search/search";
const CheckboxItem = Checkbox.CheckboxItem;
const Item = List.Item;
// const more_icon = require("../../../assets/images/icon/more_icon.png");
const drawerContainer = {
  position: "fixed",
  width: "100%",
  top: "45px",
  background: "#f5f5f5",
  height: "100%",
  zIndex: "11",
  transition: "left 0.4s"
};
export class newGroupList extends Component {
  state = {
    showCheck: false,
    groupDatas: [],
    selectNum: 0,
    selectGids: [],
    pageTotalNum: 0,
    pageNo: 1,
    drawerSearch: document.body.clientWidth,
    searchKey: {},
    paramsSearchMy: ""
  };
  onChange = (e, i) => {
    console.log(e, i);
    if (e.target.checked) {
      this.setState({
        selectNum: this.state.selectNum + 1,
        selectGids: [...this.state.selectGids, i.gid]
      });
    } else {
      let selectGids = this.state.selectGids;
      selectGids.splice(selectGids.indexOf(i.gid), 1);
      this.setState({
        selectGids: selectGids,
        selectNum: this.state.selectNum - 1
      });
    }
    console.log(this.state.selectGids);
  };
  componentWillMount() {
    this.getGroupList();
    sessionStorage.removeItem("groupRemarks")
    this.setState({
      paramsSearchMy: this.props.match.params.searchMy ? "/" + this.props.match.params.searchMy : "",
    });
  }
  deleteBtn() {
    if (this.state.selectNum < 1) {
      Toast.info("请选择要删除的群组", 1);
      return;
    }
    const BUTTONS = ["确定", "取消"];
    ActionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        message: "是否删除选中的群组？",
        maskClosable: true,
        "data-seed": "logId"
      },
      async buttonIndex => {
        console.log(buttonIndex);
        if (!buttonIndex) {
          console.log(this.state.selectGids);
          this.deleteGroups();
        }
      }
    );
  }
  // 删除群组
  deleteGroups() {
    http.post("/api/group/delete", { gids: this.state.selectGids }, 'new')
      .then(res => {
        if (res && res.status == 200) {
          Toast.success("操作成功");
          this.getGroupList(1);
          this.setState({
            showCheck: false,
          })
        } else {
          Toast.fail("删除失败")
        }
      });
  }
  // 查询群组
  getGroupList(e) {
    http
      .post("/api/group/list", {
          page: {
            pageNo: e,
            pageSize: 10,
          },
          pttGroupEntity: {
            ...this.state.searchKey
          }
      },'new')
      .then(res => {
        if (res && res.status == 200 && res.data) {
          this.setState({
            groupDatas: res.data.resultList.map(item => {
              item.checked = false;
              return item;
            }),
            pageNo: res.data.pageNo,
            pageTotalNum: Math.ceil(res.data.totalCount / 10)
          });
        } else {
          this.setState({
            groupDatas: [],
            pageNo: res.data.pageNo,
            pageTotalNum: Math.ceil(res.data.totalCount / 10),
          });
        }
      });
  }
  // 翻页切换
  onPageChange(e) {
    const { pageTotalNum } = this.state;
    e = e > pageTotalNum ? pageTotalNum : e;
    console.log(e);
    this.getGroupList(e);
    this.setState({
      selectNum: 0,
      selectGids: []
    });
  }
  onSearchList = e => {
    console.log(e);
    this.setState({
      searchKey: {
        ...e
      },
      drawerSearch: document.body.clientWidth
    });
    setTimeout(() => {
      this.getGroupList(1);
    }, 0);
  };
  render() {
    const { drawerSearch } = this.state;
    const data = [];
    for (let i = 0; i < 100; i++) {
      data.push({
        value: i,
        label: "zhaoyu" + i
      });
    }

    const userMsg =
      sessionStorage.getItem("loginMsg") &&
      JSON.parse(sessionStorage.getItem("loginMsg"))
    const { groupDatas, selectNum, pageTotalNum, pageNo } = this.state;
    return (
      <div className="groupList">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
            rightContent={[
              userMsg.userType == 1 ? (
                ""
              ) : this.state.showCheck ? (
                <Icon
                  onClick={() => {
                    this.setState({
                      showCheck: false
                    });
                  }}
                  key="1"
                  type="check"
                />
              ) : (
                <div key="3">
                  <span
                    type="search"
                    onClick={ () => {
                      this.setState({
                        drawerSearch: 0
                      });
                    }}
                    style={ {fontSize:'12px'} }
                  >查找</span> &ensp; &ensp;
                  <span
                    key= '0'
                    onClick= { () => {
                      this.setState({
                        showCheck: true,
                        selectNum: 0,
                      })
                    }}
                    style={ {fontSize:'12px'} }
                  >选项</span>
                </div>
              )
            ]}
          >
            群组列表
          </NavBar>
        </div>

        <div className="sub-container">
          <List>
            {groupDatas.map((i, index) => {
              return (
                <div key={index}>
                  {this.state.showCheck ? (
                    <CheckboxItem
                      key={index}
                      onChange={e => this.onChange(e, i)}
                    >
                      {i.name}
                    </CheckboxItem>
                  ) : (
                    <Item
                      key={index}
                      arrow="horizontal"
                      onClick={() => {
                        this.props.history.push(`/newAddgroup/edit/${i.gid}/${i.name}`)
                        sessionStorage.setItem("groupRemarks", i.remarks)
                      }}
                    >
                      {i.name}
                    </Item>
                  )}
                </div>
              );
            })}
          </List>
          <WhiteSpace />
          <WingBlank>
            {pageTotalNum > 0 ? (
              <Pagination
                total={pageTotalNum}
                current={pageNo}
                locale={{
                  prevText: "上一页",
                  nextText: "下一页"
                }}
                onChange={e => this.onPageChange(e)}
              />
            ) : (
              ""
            )}
          </WingBlank>

          {this.state.showCheck ? (
            <div className="footer">
              <span>
                已选择：
                {selectNum}
                个群组
              </span>
              <Button
                type="warning"
                inline
                size="small"
                onClick={() => this.deleteBtn()}
              >
                删除
              </Button>
            </div>
          ) : (
            ""
          )}
          <div className="" style={{ ...drawerContainer, left: drawerSearch }}>
            <GroupSearch
              closeSearch={() =>
                this.setState({
                  drawerSearch: document.body.clientWidth
                })
              }
              onSearchList={this.onSearchList}
              type='group'
            />
          </div>
        </div>
      </div>
    );
  }
}

export default newGroupList;
