import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  WingBlank,
  WhiteSpace,
  Button,
  Checkbox,
  Pagination,
} from "antd-mobile";
import axios from "../../../service/axios";
import Search from "../../../components/search/search";
import NoneDatas from "../../../components/noneDatas";
import { connect } from "dva";
const userMsg =
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg")).user;
const CheckboxItem = Checkbox.CheckboxItem;
const Item = List.Item;

const selectedContainer = {
  position: "fixed",
  width: "100%",
  top: "45px",
  background: "#f5f5f5",
  height: "100%",
  zIndex: "11",
  transition: "left 0.4s"
};

export class userSelectGroup extends Component {
  state = {
    groupDatas: [],
    selectNum: 0,
    selectGroupArr: [],
    drawerLeft: document.body.clientWidth,
    drawerSearch: document.body.clientWidth,
    pageTotalNum: 0,
    pageNo: 1,
    selectAll: false
  };
  onChange = (e, i, index) => {
    console.log(e, i);
    if (e.target.checked) {
      this.props.addGroupsToArr(i);
    } else {
      this.props.removeGroupsFromArr(i);
    }
  };
  componentDidMount() {
    this.getGroupList(1);
  }
  getGroupList(e) {
    axios
      .get("/api/oa/group/list", {
        params: {
          pageSize: 10,
          pageNo: e,
          ...this.state.searchKey,
          searchMy: userMsg && userMsg.userType != 4 ? 1 : null
        }
      })
      .then(res => {
        console.log(res);
        if (res && res.status == 1000 && res.data) {
          this.setState({
            groupDatas: res.data.list.map(item => {
              item.checked = false;
              return item;
            }),
            pageNo: res.data.pageNo,
            pageTotalNum: Math.ceil(res.data.count / 10)
          });
        }
      })
      .catch(res => {
      });
  }
  onSearchList = e => {
    this.setState({
      searchKey: {
        ...e,
      },
      drawerSearch: document.body.clientWidth,
    });
    setTimeout(() => {
      this.getGroupList(1);
    }, 0);
  }
  onPageChange(e) {
    this.getGroupList(e);
    this.setState({
      selectAll: false
    });
  }
  selectAll(e) {
    this.setState({
      selectAll: e.target.checked
    });
    if (e.target.checked) {
      console.log(this.state.groupDatas);
      this.props.setSelectAll(this.state.groupDatas);
    } else {
      this.props.setEmpty(this.state.groupDatas);
    }
  }
  render() {
    const {
      groupDatas,
      selectNum,
      drawerLeft,
      drawerSearch,
      pageTotalNum,
      pageNo,
      selectAll
    } = this.state;
    const {
      addGroupsToArr,
      removeGroupsFromArr,
      appSelectGroups,
      groupsGidArr,
      emptyGroups
    } = this.props;
    return (
      <div 
        className="selectUser"
        style={{ height: '90%', overflowY: 'scroll' }}
        >
        {/* <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            请选择所属群组
          </NavBar>
        </div> */}
        <div
          className="sub-container"
        >
          <div className="am-list-header" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <span>请选择所属群组</span>
            
            <Button
              size='small'
              style={{width:'90px'}}
              onClick={()=>
                this.setState(
                  { drawerSearch: 0 }
                )
              }
            >查找群组
            </Button>
          </div>
            <List>
              {groupDatas.map((i, index) => (
                <CheckboxItem
                  key={i.gid}
                  checked={groupsGidArr.includes(i.gid)}
                  onChange={e => this.onChange(e, i, index)}
                >
                  {i.name}
                </CheckboxItem>
              ))}
            </List>
          <WhiteSpace size="xl" />
          <WingBlank>
            {pageTotalNum > 0 ? (
              <Pagination
                total={pageTotalNum}
                current={pageNo}
                locale={{
                  prevText: "上一页",
                  nextText: "下一页"
                }}
                onChange={e => this.onPageChange(e)}
              />
            ) : (
              ""
            )}
          </WingBlank>
          <div
            className="selected-container"
            style={{ ...selectedContainer, left: drawerLeft + "px" }}
          >
            <List renderHeader={() => "请编辑已选群组成员"}>
              {appSelectGroups.map((i, index) => (
                <Item
                  key={index}
                  extra={
                    <Icon type="cross" onClick={() => removeGroupsFromArr(i)} />
                  }
                >
                  {i.name}
                </Item>
              ))}

              <WingBlank>
                <WhiteSpace size="xl" />
                <Button
                  type="primary"
                  onClick={() =>
                    this.setState({
                      drawerLeft: document.body.clientWidth
                    })
                  }
                >
                  确定
                </Button>
                <WhiteSpace size="xl" />
              </WingBlank>
            </List>
          </div>
          <div className="footer">
            <CheckboxItem
              checked={selectAll}
              onChange={e => this.selectAll(e)}
            />
            <span
              onClick={() =>
                this.setState({
                  drawerLeft: 0
                })
              }
            >
              已选:
              {appSelectGroups.length}组
            </span>
            <Button
              type="warning"
              inline
              size="small"
              onClick={() => this.props.handleChildClose()}
            >
              返回
            </Button>
            <Button
              disabled={appSelectGroups.length < 1}
              type="primary"
              inline
              size="small"
              onClick={() => this.props.handleChildClose()}
            >
              确定
            </Button>
          </div>
          <div
            className="search"
            style={{ ...selectedContainer, left: drawerSearch }}
          >
            <Search
              closeSearch={() =>
                this.setState({
                  drawerSearch: document.body.clientWidth,
                })
              }
              type="group"
              onSearchList={this.onSearchList}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appSelectGroups: state.group.groups,
    groupsGidArr: state.group.groupsGidArr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addGroupsToArr: data => {
      dispatch({ type: "group/addGroupsToArr", data });
    },
    removeGroupsFromArr: data => {
      dispatch({
        type: "group/removeGroupsFromArr",
        data
      });
    },
    emptyGroups: () => {
      dispatch({
        type: "group/emptyGroups"
      });
    },
    setSelectAll: data => {
      dispatch({
        type: "group/setSelectAll",
        data
      });
    },
    setEmpty: data => {
      dispatch({
        type: "group/setEmpty",
        data
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(userSelectGroup);
