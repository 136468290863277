import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  Checkbox,
  Button,
  Pagination,
  WingBlank,
  WhiteSpace,
  Toast
} from "antd-mobile";
import axios from "../../../service/axios";
const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;
const selectedContainer = {
  position: "fixed",
  width: "100%",
  top: "45px",
  background: "#f5f5f5",
  height: "100%",
  zIndex: "11",
  transition: "left 0.4s",
  overflowY: "scroll"
};
export class UserFriends extends Component {
  state = {
    userInfo: {},
    friends: [],
    friendsDrawer: document.body.clientWidth,
    strangers: [],
    pageTotalNum: 0,
    pageNo: 1
  };
  componentDidMount() {
    Toast.loading("加载中...", 0);
    this.getUserInfo();
    this.getFriends();
    this.getStrangers(1);
  }
  getStrangers(e) {
    axios
      .get("/api/oa/pttUser/getStrangers", {
        params: {
          pageSize: 10,
          pageNo: e - 1,
          id: this.props.match.params.uid
        }
      })
      .then(res => {
        if (res && res.status == 1000 && res.data) {
          this.setState({
            strangers: res.data.list,
            pageTotalNum: Math.ceil(res.data.count / 10)
          });
        }
      });
  }
  getUserInfo() {
    axios
      .get("/api/oa/pttUser/info", {
        params: {
          uid: this.props.match.params.uid
        }
      })
      .then(res => {
        if (res && res.status == 1000 && res.data) {
          this.setState({
            userInfo: res.data
          });
        }
      });
  }
  getFriends() {
    axios
      .get("/api/oa/pttUser/getFriends", {
        params: {
          uid: this.props.match.params.uid
        }
      })
      .then(res => {
        if (res && res.status == 1000 && res.data) {
          this.setState({
            friends: res.data
          });
        }
      });
  }
  onPageChange(e) {
    this.setState({
      pageNo: e
    });
    this.getStrangers(e);
  }
  onStrangersChange(e, item) {
    const { friends } = this.state;
    let arr = [...friends];
    if (e.target.checked) {
      arr.push(item);
      this.setState({
        friends: arr
      });
      friends.push(item);
    } else {
      arr.splice(arr.map(item => item.fuser.uid).indexOf(item.fuser.uid), 1);
      this.setState({
        friends: arr
      });
    }
  }
  onSaveFriend = () => {
    const { friends } = this.state;
    axios
      .get("/api/oa/pttUser/saveFriend", {
        params: {
          friends: friends.map(item => item.fuser.uid).join(","),
          uid: this.props.match.params.uid
        }
      })
      .then(res => {
        if (res && res.status == 1000) {
          Toast.success("操作成功", 1, () => {
            this.props.history.goBack();
          });
        }
      });
  };
  onSelectedAll = e => {
    console.log(e);
    const { friends, strangers } = this.state;
    let arrStr = [...strangers];
    let arr = [...friends];
    let arrUid = [...friends].map(item => item.fuser.uid);
    if (e.target.checked) {
      arrStr.map(item => {
        if (!arrUid.includes(item.fuser.uid)) {
          arr.push(item);
        }
      });
    } else {
      arrStr.map(items => {
        if (arrUid.includes(items.fuser.uid)) {
          arr.splice(
            arr.map(item => item.fuser.uid).indexOf(items.fuser.uid),
            1
          );
        }
      });
    }
    this.setState({
      friends: arr
    });
  };
  deleteFriends(item) {
    const { friends } = this.state;
    let arr = [...friends];
    arr.splice(arr.map(item => item.fuser.uid).indexOf(item.fuser.uid), 1);
    this.setState({
      friends: arr
    });
  }

  render() {
    const {
      userInfo,
      friends,
      friendsDrawer,
      strangers,
      pageTotalNum,
      pageNo
    } = this.state;
    return (
      <div className="user-list">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            好友
          </NavBar>
        </div>
        <div className="sub-container">
          <List>
            <Item extra={userInfo.account}>账号</Item>
            <Item extra={userInfo.name}>名字</Item>
            <Item
              arrow="horizontal"
              extra={`${friends.length}个好友`}
              onClick={() =>
                this.setState({
                  friendsDrawer: 0
                })
              }
            >
              好友列表
            </Item>
            <Item>
              <WingBlank>
                <Button type="primary" onClick={this.onSaveFriend}>
                  保存
                </Button>
                <WhiteSpace size="lg" />
                <Button onClick={() => this.props.history.goBack()}>
                  返回
                </Button>
              </WingBlank>
            </Item>
          </List>
        </div>
        <div style={{ ...selectedContainer, left: friendsDrawer }}>
          <List renderHeader="已选好友列表">
            <div style={{ height: 200, overflowY: "scroll" }}>
              {friends.map((item, index) => (
                <Item
                  key={index}
                  extra={
                    <Icon
                      style={{ color: "red",padding:'2px 5px' }}
                      onClick={() => this.deleteFriends(item)}
                      type="cross"
                    />
                  }
                >
                  &ensp; &ensp;{item.fuser.name}
                </Item>
              ))}
            </div>
          </List>
          <List renderHeader="陌生人">
            {strangers.map((item, index) => (
              <CheckboxItem
                checked={friends
                  .map(item => item.fuser.uid)
                  .includes(item.fuser.uid)}
                key={index}
                onChange={e => this.onStrangersChange(e, item)}
              >
                {item.fuser.name}
              </CheckboxItem>
            ))}
            <CheckboxItem onChange={e => this.onSelectedAll(e)}>
              全选
            </CheckboxItem>
            <WhiteSpace size="lg" />
            <WingBlank>
              {pageTotalNum > 0 ? (
                <Pagination
                  total={pageTotalNum}
                  current={pageNo}
                  locale={{
                    prevText: "上一页",
                    nextText: "下一页"
                  }}
                  onChange={e => this.onPageChange(e)}
                />
              ) : (
                ""
              )}
            </WingBlank>

            <Item>
              <Button
                type="primary"
                onClick={() =>
                  this.setState({
                    friendsDrawer: document.body.clientWidth
                  })
                }
              >
                确定
              </Button>
              <WhiteSpace size="lg" />
              <Button
                onClick={() =>
                  this.setState({
                    friendsDrawer: document.body.clientWidth
                  })
                }
              >
                返回
              </Button>
            </Item>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
          </List>
        </div>
      </div>
    );
  }
}

export default UserFriends;
