import React, { Component } from "react";
import {
  NavBar,
  Icon,
  Button,
  WhiteSpace,
  List,
  WingBlank,
  TextareaItem,
  Result,
} from "antd-mobile";
import "./dakatale.scss";

export class Tdaka extends Component {
  state = {
    scanResult: {},
  };

  componentDidMount = () => {
    this.TextareaChange();
  };
  TextareaChange() {
    const imei = JSON.parse(localStorage.getItem("scan:result:imei"));
    console.log('??imei',imei)
    this.setState({
      scanResult: imei ? imei : {},
    });
  }
  handleBindingUser1 = () => {
    this.props.history.push({
      //跳转账号
      pathname: "/Tdaka",
    });
  };
  handleBindingUser2 = () => {
    //跳转群组
    this.props.history.push({
      pathname: "/uplist",
    });
  };
  render() {
    return (
      <div className="kakaing">
        <NavBar
          icon={<Icon type="left" />}
          onLeftClick={() => this.props.history.goBack()}
        >
          扫码结果
        </NavBar>
        <div>
          <WhiteSpace />

          <Result
            img={
              <Icon
                type="check-circle"
                className="spe"
                style={{ fill: "#1F90E6" }}
              />
            }
            title="扫码成功"
            message={`IMEI：` + this.state.scanResult.imei}
          />
        </div>

        <List>
          <WingBlank>
            <WhiteSpace />
            <Button type="primary" onClick={this.handleBindingUser2}>
              绑定已有账号
            </Button>
            <WhiteSpace />
            <Button type="warning" onClick={this.handleBindingUser1}>
              绑定新建账号
            </Button>
            <WhiteSpace />
          </WingBlank>
        </List>
      </div>
    );
  }
}

export default Tdaka;
