import React, { Component } from "react";
import "antd-mobile/dist/antd-mobile.css";
import "./App.scss";
import Main from "./routes/main";
import axios from '@/service/ajax'

class App extends Component {
  render() {
    return (
      <div className="App">
        <Main />
      </div>
    );
  }
}

export default App;
