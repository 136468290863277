import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  Button,
  WhiteSpace,
  WingBlank,
  Picker,
  InputItem,
  Toast
} from "antd-mobile";
import axios from "../../../service/axios";
import { createForm } from "rc-form";

const Item = List.Item;
const quotaTypeDatas = [
  {
    label: "基础额度",
    value: 0
  },
  {
    label: "VIP额度",
    value: 1
  },
  {
    label: "三年额度",
    value: 2
  },
  {
    label: "续费额度",
    value: 3
  }
];
const typeDatas = [
  {
    label: "基础套餐",
    value: 0
  },
  {
    label: "调度套餐",
    value: 1
  },
  {
    label: "视频套餐",
    value: 2
  }
];
export class AdminNum extends Component {
  state = {
    adminUser: {},
    adminBaseAvailInfo: {},
    adminVipAvailInfo: {},
    adminThreeAvailInfo: {},
    adminRenewAvailInfo: {},
    loginAdminMsg: {},
    type: 0,
    showVipInfo: false
  };
  componentDidMount() {
    this.getAdminInfo();
    this.getLoginAdminInfo();
  }
  getAdminInfo() {
    Toast.loading("加载中...", 0);
    axios
      .get("/api/oa/sysUser/info", {
        params: {
          id: this.props.match.params.id
        }
      })
      .then(res => {
        Toast.hide();
        if (res && res.status == 1000 && res.data) {
          this.setState({
            adminInfoDatas: res.data,
            adminUser: res.data.user,
            adminBaseAvailInfo: res.data.baseAvailInfo,
            adminVipAvailInfo: res.data.vipAvailInfo,
            adminThreeAvailInfo: res.data.threeAvailInfo,
            adminRenewAvailInfo: res.data.renewAvailInfo
          });
        }
      });
  }

  getLoginAdminInfo() {
    const loginAdminMsg =
      sessionStorage.getItem("loginMsg") &&
      JSON.parse(sessionStorage.getItem("loginMsg")).user;
    axios
      .get("/api/oa/sysUser/info", {
        params: {
          id: loginAdminMsg.id
        }
      })
      .then(res => {
        if (res && res.status == 1000) {
          const { data } = res
          this.setState({
            loginAdminMsg: data,
            showVipInfo: data.user.vipAvailAmount > 0 || data.vipAvailInfo.dispatchCount > 0 || data.vipAvailInfo.videoCount > 0 || data.user.userType == '0'
          });
        }
      });
  }
  submitBtn() {
    this.props.form.validateFields({ force: true }, error => {
      console.log(this.props.form.getFieldsValue());
      const { type, quotaType, optNumber } = this.props.form.getFieldsValue();

      let params = {
        ...this.props.form.getFieldsValue(),
        id: this.props.match.params.id,
        quotaType: quotaType[0],
        // pttAvail: {
        //   type: type[0]
        // },
        isRecycle: this.props.match.params.type == 0 ? false : true,
        type: type[0]
      };
      console.log(params);
      if (!error) {
        Toast.loading("加载中...", 0);
        axios
          .get("/api/oa/sysUser/setAvailability", {
            params
          })
          .then(res => {
            Toast.hide();
            if (res) {
              if (res.status == 1000 && res.data) {
                Toast.success(res.data, 1, () => {
                  this.props.history.goBack();
                });
              } else {
                Toast.fail(res.message, 1);
              }
            }
          })
          .catch(res => {
            Toast.hide();
            Toast.fail("操作失败");
          });
      }
    });
  }
  validateOptNumber = (rule, value, callback) => {
    let typeName = this.props.match.params.type == 0 ? "下发" : "回收";
    if (value) {
      if (value < 1) {
        callback(new Error("请填写大于0的数字"));
      } else {
        callback();
      }
    } else {
      callback(new Error(`请填写${typeName}的额度值`));
    }
  };
  onTypeChange = e => {
    console.log(e);
    this.setState({
      type: e[0]
    });
  };
  render() {
    const { getFieldProps, getFieldError } = this.props.form;
    const { adminUser, adminBaseAvailInfo, adminVipAvailInfo, adminThreeAvailInfo, adminRenewAvailInfo, loginAdminMsg, type, showVipInfo } = this.state;
    const { user, baseAvailInfo, vipAvailInfo, threeAvailInfo, renewAvailInfo } = loginAdminMsg
    let typeName = this.props.match.params.type == 0 ? "下发" : "回收";
    return (
      <div>
        <div className="addAdmin">
          <div className="header-sticky header-navbar">
            <NavBar
              icon={<Icon type="left" />}
              onLeftClick={() => this.props.history.goBack()}
            >
              {typeName}额度
            </NavBar>
          </div>
          <div className="sub-container">
            <List
              renderHeader={`当前登录管理员${user &&
                user.loginName}总额度`}
            >
              <Item
                extra={user ? showVipInfo ? user.availability + '/' + user.vipAvail + '/' + user.threeAvail + '/' + user.renewAvail : user.availability + '/' + user.threeAvail + '/' + user.renewAvail : 0}
              >
                基础
                {showVipInfo ? '/VIP' : ''}
                /三年/续费-
                可用
              </Item>
              <Item
                extra={
                  baseAvailInfo ? showVipInfo ? baseAvailInfo.dispatchLeftNum + '/' + vipAvailInfo.dispatchLeftNum + '/' + threeAvailInfo.dispatchLeftNum + '/' + renewAvailInfo.dispatchLeftNum : baseAvailInfo.dispatchLeftNum + '/' + threeAvailInfo.dispatchLeftNum + '/' + renewAvailInfo.dispatchLeftNum : 0
                }
              >
                调度
                {showVipInfo ? '/VIP' : ''}
                /三年/续费-
                可用
              </Item>
              <Item
                extra={
                  baseAvailInfo ? showVipInfo ? baseAvailInfo.videoLeftNum + '/' + vipAvailInfo.videoLeftNum + '/' + threeAvailInfo.videoLeftNum + '/' + renewAvailInfo.videoLeftNum : baseAvailInfo.videoLeftNum + '/' + threeAvailInfo.videoLeftNum + '/' + renewAvailInfo.videoLeftNum : 0
                }
              >
                视频
                {showVipInfo ? '/VIP' : ''}
                /三年/续费-
                可用
              </Item>
            </List>
            <List renderHeader={`管理员${adminUser.loginName}可用额度`}>
              <Item extra={showVipInfo ? adminUser.availability + '/' + adminUser.vipAvail + '/' + adminUser.threeAvail + '/' + adminUser.renewAvail : adminUser.availability + '/' + adminUser.threeAvail + '/' + adminUser.renewAvail}>
                基础
                {showVipInfo ? '/VIP' : ''}
                /三年/续费-
                可用
                </Item>
              <Item extra={showVipInfo ? adminBaseAvailInfo.dispatchLeftNum + '/' + adminVipAvailInfo.dispatchLeftNum + '/' + adminThreeAvailInfo.dispatchLeftNum + '/' + adminRenewAvailInfo.dispatchLeftNum : adminBaseAvailInfo.dispatchLeftNum + '/' + adminThreeAvailInfo.dispatchLeftNum + '/' + adminRenewAvailInfo.dispatchLeftNum}>
                调度
                {showVipInfo ? '/VIP' : ''}
                /三年/续费-
                可用
                </Item>
              <Item extra={showVipInfo ? adminBaseAvailInfo.videoLeftNum + '/' + adminVipAvailInfo.videoLeftNum + '/' + adminThreeAvailInfo.videoLeftNum + '/' + adminRenewAvailInfo.videoLeftNum : adminBaseAvailInfo.videoLeftNum + '/' + adminThreeAvailInfo.videoLeftNum + '/' + adminRenewAvailInfo.videoLeftNum}>
                视频
                {showVipInfo ? '/VIP' : ''}
                /三年/续费-
                可用
                </Item>
            </List>
            <List
              renderHeader={typeName + "额度"}
              renderFooter={() => getFieldError("optNumber")}
            >
              <Item extra={adminUser.loginName}>管理员 </Item>
              <Picker
                {...getFieldProps("quotaType", {
                  initialValue: [0]
                })}
                data={showVipInfo ? quotaTypeDatas : [quotaTypeDatas[0], quotaTypeDatas[2], quotaTypeDatas[3]]}
                cols={1}
                key="1"
              >
                <Item key="1" arrow="horizontal">
                  额度类型
                </Item>
              </Picker>
              <Picker
                {...getFieldProps("type", {
                  initialValue: [type]
                })}
                data={typeDatas}
                cols={1}
                key="2"
                onChange={this.onTypeChange}
              >
                <Item key="1" arrow="horizontal">
                  套餐类型
                </Item>
              </Picker>
              <InputItem
                {...getFieldProps("optNumber", {
                  rules: [{ validator: this.validateOptNumber }]
                })}
                clear
                type="number"
                placeholder={`请填写${typeName}的额度值`}
              >
                {typeName}额度
              </InputItem>
            </List>
            <WhiteSpace size="xl" />
            <WingBlank>
              <Button type="primary" onClick={() => this.submitBtn()}>
                确定
              </Button>
              <WhiteSpace size="xl" />
              <Button onClick={() => this.props.history.goBack()}>返回</Button>
            </WingBlank>
          </div>
        </div>
      </div>
    );
  }
}

export default createForm()(AdminNum);
