import React, { Component } from 'react'
import {
  NavBar,
  Icon,
  List,
  Drawer,
  WhiteSpace,
  InputItem,
  Button,
  WingBlank,
  Pagination,
  Toast
} from 'antd-mobile'
import './billMessage.scss'
import axios from '../../../service/axios'
const Item = List.Item
const Brief = Item.Brief
const userMsg =
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg")).user;
export class BillMessage extends Component {
  state = {
    open: false,
    billDatas: [],
    pageTotalNum: 0,
    pageNo: 1,
    showVipInfo: false
  }
  componentDidMount() {
    this.getAvailInfo(1);
    this.getLoginAdminInfo();
  }
  getAvailInfo(e) {
    Toast.loading('加载中...')
    axios
      .get('/api/oa/pttBill/availInfo', {
        params: {
          pageNo: e,
          pageSize: 10
        }
      })
      .then(res => {
        Toast.hide()
        if (res && res.status == 1000 && res.data) {
          this.setState({
            billDatas: res.data.list,
            pageNo: res.data.pageNo,
            pageTotalNum: Math.ceil(res.data.count / 10)
          })
        }
      })
  }
  onOpenChange = () => {
    this.setState({
      open: !this.state.open
    })
  }
  onPageChange = e => {
    this.getAvailInfo(e)
  }
  getLoginAdminInfo() {
    axios
      .get("/api/oa/sysUser/info", {
        params: {
          id: userMsg.id
        }
      })
      .then(res => {
        if (res && res.status == 1000) {
          const { data } = res
          this.setState({
            loginAdminMsg: data,
            showVipInfo: data.user.vipAvailAmount > 0 || data.vipAvailInfo.dispatchCount > 0 || data.vipAvailInfo.videoCount > 0
          });
        }
      });
  }

  render() {
    const sidebar = (
      <div className="bill-sidebar">
        <List renderHeader={() => '搜索额度信息'}>
          <InputItem clear placeholder="登录账号">
            登录账号
          </InputItem>
          <WhiteSpace size="xl" />
          <Button type="primary">搜索</Button>
          <WhiteSpace size="xl" />
          <Button onClick={this.onOpenChange} type="">
            关闭
          </Button>
        </List>
      </div>
    )
    const { billDatas, pageTotalNum, pageNo, showVipInfo } = this.state
    return (
      <div className="billMessage">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          // rightContent={[
          //   <Icon
          //     key="0"
          //     type="search"
          //     onClick={this.onOpenChange}
          //     style={{ marginRight: '16px' }}
          //   />
          // ]}
          >
            额度信息
          </NavBar>
        </div>
        <div className="sub-container">
          {/* <Drawer
            className="my-drawer"
            style={{ minHeight: document.documentElement.clientHeight }}
            enableDragHandle
            sidebar={sidebar}
            open={this.state.open}
            onOpenChange={this.onOpenChange}
            position="right"
            contentStyle={{ padding: '45px 0' }}
          > */}

          <List>
            {billDatas.map((i, index) => (
              <Item
                key={index}
                arrow="horizontal"
                multipleLine
                onClick={() => { }}
              >
                登录账号： {i.user.loginName}
                <Brief>
                  基础总额度：
                  {i.user.totalAmount +
                    i.baseAvailInfo.dispatchCount +
                    i.baseAvailInfo.videoCount}
                </Brief>
                <Brief>
                  基础(可用/已用/总数)：
                  {i.user.availability}/
                  {i.user.totalAmount - i.user.availability}/
                  {i.user.totalAmount}
                </Brief>
                <Brief>
                  调度(可用/已用/总数)：
                  {i.baseAvailInfo.dispatchLeftNum}/
                  {i.baseAvailInfo.dispatchCount -
                    i.baseAvailInfo.dispatchLeftNum}
                  /{i.baseAvailInfo.dispatchCount}
                </Brief>
                <Brief>
                  视频(可用/已用/总数)：
                  {i.baseAvailInfo.videoLeftNum}/
                  {i.baseAvailInfo.videoCount - i.baseAvailInfo.videoLeftNum}/
                  {i.baseAvailInfo.videoCount}
                </Brief>
                {
                  showVipInfo ? (
                    <div>
                      <Brief>
                        VIP总额度：
                          {i.user.vipAvailAmount +
                          i.vipAvailInfo.dispatchCount +
                          i.vipAvailInfo.videoCount}
                      </Brief>
                      <Brief>
                        VIP基础(可用/已用/总数)：
                          {i.user.vipAvail}/
                          {i.user.vipAvailAmount - i.user.vipAvail}/
                          {i.user.vipAvailAmount}
                      </Brief>
                      <Brief>
                        VIP调度(可用/已用/总数)：
                          {i.vipAvailInfo.dispatchLeftNum}/
                          {i.vipAvailInfo.dispatchCount -
                          i.vipAvailInfo.dispatchLeftNum}/
                          {i.vipAvailInfo.dispatchCount}
                      </Brief>
                      <Brief>
                        VIP视频(可用/已用/总数)：
                          {i.vipAvailInfo.videoLeftNum}/
                          {i.vipAvailInfo.videoCount - i.vipAvailInfo.videoLeftNum}/
                          {i.vipAvailInfo.videoCount}
                      </Brief>
                    </div>
                  ) : ''
                }
                <Brief>
                  三年总额度：
                  {i.user.threeAvailAmount +
                    i.threeAvailInfo.dispatchCount +
                    i.threeAvailInfo.videoCount}
                </Brief>
                <Brief>
                  基础(可用/已用/总数)：
                  {i.user.threeAvail}/
                  {i.user.threeAvailAmount - i.user.threeAvail}/
                  {i.user.threeAvailAmount}
                </Brief>
                <Brief>
                  调度(可用/已用/总数)：
                  {i.threeAvailInfo.dispatchLeftNum}/
                  {i.threeAvailInfo.dispatchCount -
                    i.threeAvailInfo.dispatchLeftNum}
                  /{i.threeAvailInfo.dispatchCount}
                </Brief>
                <Brief>
                  视频(可用/已用/总数)：
                  {i.threeAvailInfo.videoLeftNum}/
                  {i.threeAvailInfo.videoCount - i.threeAvailInfo.videoLeftNum}/
                  {i.threeAvailInfo.videoCount}
                </Brief>
                <Brief>
                  续费总额度：
                  {i.user.renewAvailAmount +
                    i.renewAvailInfo.dispatchCount +
                    i.renewAvailInfo.videoCount}
                </Brief>
                <Brief>
                  基础(可用/已用/总数)：
                  {i.user.renewAvail}/
                  {i.user.renewAvailAmount - i.user.renewAvail}/
                  {i.user.renewAvailAmount}
                </Brief>
                <Brief>
                  调度(可用/已用/总数)：
                  {i.renewAvailInfo.dispatchLeftNum}/
                  {i.renewAvailInfo.dispatchCount -
                    i.renewAvailInfo.dispatchLeftNum}
                  /{i.renewAvailInfo.dispatchCount}
                </Brief>
                <Brief>
                  视频(可用/已用/总数)：
                  {i.renewAvailInfo.videoLeftNum}/
                  {i.renewAvailInfo.videoCount - i.renewAvailInfo.videoLeftNum}/
                  {i.renewAvailInfo.videoCount}
                </Brief>
              </Item>
            ))}
          </List>
          <WhiteSpace size="xl" />
          <WingBlank>
            {pageTotalNum > 0 ? (
              <Pagination
                total={pageTotalNum}
                current={pageNo}
                locale={{
                  prevText: '上一页',
                  nextText: '下一页'
                }}
                onChange={e => this.onPageChange(e)}
              />
            ) : (
                ''
              )}
          </WingBlank>
          {/* </Drawer> */}
        </div>
      </div>
    )
  }
}

export default BillMessage
