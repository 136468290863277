import React, { Component } from "react";
import {
  Modal,
  NavBar,
  Icon,
  Button,
  WingBlank,
  WhiteSpace,
  List,
  Toast,
} from "antd-mobile";
import "./clockinsdk.scss";
import company_sys from "../../../assets/images/saoyisao.png";
import NoneDatas from "../../../components/noneDatas"

const Item = List.Item;
const alert = Modal.alert
export class Clockinsdk extends Component {
  state = {
    imeiList: [],
  };
  componentDidMount() {
    console.log('sdk-!')
    console.log('cfg',JSON.parse(localStorage.getItem('configData')))
    window.wx.config(JSON.parse(localStorage.getItem('configData')));
    window.wx.ready(() => {
      console.log("cfg-初始化完成");
    })
  }
  DYcamera = () => {
    window.wx.scanQRCode({
      needResult: 1,
      desc: "scanQRCode desc",
      success: (res) => {
        console.log('扫描~', res)
        let result = JSON.parse(res.resultStr);
        console.log('扫描result', result)
        localStorage.setItem('scan:result:imei', JSON.stringify(result));
        this.props.history.push("/Dakatale");
      },
      fail: (res)=> {
        console.log('err!',res)
        Toast.fail(res)
      },
      complete: (res) => {
        console.log('end!',res)
      }
    })
  }; 
  
  DYcamera2 = () => {
    window.wx.scanQRCode({
      needResult: 1,
      desc: "scanQRCode desc",
      success: (res) => {
        console.log('扫描~', res)
        let result = this.state.imeiList
        result.push(JSON.parse(res.resultStr)) 
        console.log('扫描result', result)
        localStorage.setItem('scan:result:imei', JSON.stringify(result));
        localStorage.setItem('scan:result:imeiList', JSON.stringify(result));
        // this.props.history.push("/Dakatale");
        this.setState({
          imeiList: result
        },()=>{})
      },
      fail: (res)=> {
        console.log('err!',res)
        Toast.fail(res)
      },
      complete: (res) => {
        console.log('end!',res)
      }
    })
  }
  onSave = () => {
    console.log('跳?',this.state.imeiList,this.state.imeiList.length)
    if(this.state.imeiList.length > 0){
      // 跳转到新建页
      // Toast.success('ok!')
      this.props.history.push("/Tdaka")
    }else{
      // 提示无数据时 不跳转
      Toast.fail('暂无数据, 请先扫码')
    }
    
  }
  DYcamera3 = () => {
    // 随机一条数据
    let number = Math.floor(Math.random()*10)
    let randomIMEI = {"imei":"89860230035678"+number,"type":1}

    let random = this.state.imeiList
    random.push(randomIMEI)
    console.log("记录?",this.state.imeiList,'随机数?',number,"?",randomIMEI,"数组?",random)
    
    // 保存传递数据
    localStorage.setItem('scan:result:imeiList', JSON.stringify(random));
    this.setState({
      imeiList: random
    },()=>{})
    
  }

  // 测试跳转成功
  success = () => {
    const result = {"imei":"860181063921271","type":1}
    localStorage.setItem('scan:result:imei', JSON.stringify(result));
    this.props.history.push("/Dakatale");
    console.log('result', result)
  };

  // 删除列表中的imei
  onDelectImei(item,index){
    console.log('!~',item,index)
    let imeidArr = this.state.imeiList
    imeidArr.splice(index,1)
    this.setState({
      imeiList: imeidArr
    })
  }

  render() {
    const { imeiList } = this.state
    return (
      <div className="kakaing">
        <NavBar mode="dark"
          icon={<Icon type="left" />}
          onLeftClick={() => this.props.history.goBack()}
        >扫码界面</NavBar>
        <div className="saoyisao">
          <WingBlank>
            {/* <h4>请点击扫一扫</h4> */}
            {/* <img src={company_sys} alt="" onClick={this.DYcamera2()} /> */}
            <img src={company_sys} alt=""/>
            {/* <Button type="primary" onClick={() => this.success()}>扫码成功测试跳转</Button> */}
            <WhiteSpace size="xl" />
            <Button type="primary" onClick={() => this.DYcamera2()}>
              开始批量扫码
            </Button>
            <WhiteSpace size="xl" />
            {/* <Button type="primary" onClick={() => this.DYcamera3()}>
              扫码测试
            </Button>
            <WhiteSpace size="xl" /> */}
            <Button type="primary" onClick={() => this.onSave()}>
              创建账号
            </Button>
          </WingBlank>
        </div>
        {/* 批量扫码部分 */}
        <WingBlank>
          <p>扫描到的数据:</p>
          <div style={{maxHeight:'200px',overflow:'auto'}} >
            <List>
              {imeiList.map((item, index) => {
                return (
                  <Item 
                    key={index}
                    extra={
                      <Button 
                        size="small" 
                        type="warning" 
                        inline='true' 
                        onClick={()=> alert('删除', '是否删除该条扫码结果?', [
                          { text: '取消', onPress: () => console.log('cancel') },
                          { text: '确认', onPress: () => this.onDelectImei(item,index) },
                        ])}
                        key={index}
                      >
                      删
                      </Button>
                    }
                  >
                    {item.imei}
                  </Item>
              )})}
              {imeiList.length == 0? <NoneDatas /> : ""}
            </List>
          </div>
        </WingBlank>
      </div>
    );
  }
}

export default Clockinsdk;
