import React, { Component } from 'react'
import './simInfo.scss'
import {
  NavBar,
  Icon,
  List,
  WhiteSpace,
  Toast,
  SearchBar
} from 'antd-mobile'
import { createForm } from 'rc-form'
import axios from '../../../service/axios'
import NoneDatas from "../../../components/noneDatas";
const Item = List.Item
const Brief = List.Item.Brief

export class SimInfo extends Component {
  state = {
    cardInfo: {},
    cardStatus:{},
    monthFlow:{},   
    sm:''//01果壳 02泉州电信 03武汉电信
  }
  componentDidMount() {
  }

  submit = (iccid) => {
    if (!iccid) {
      Toast.fail('请填写查询ICCID', 1);
      return;
    }
    Toast.loading('加载中...', 0)
    axios
      .get('/api/oa/card/getCardInfo', {
        params: {
          iccid,
        }
      })
      .then(res => {
        console.log(res)
        Toast.hide()
        if (res) {
          if (res.status == 1000 && res.data) {
            this.setState({
              cardInfo: res.data.cardInfo,
              cardStatus: res.data.cardStatus,
              monthFlow: res.data.monthFlow||{},
              sm:res.data.sm
            })
          } else if (res.status == -1004) {
            Toast.fail(res.data, 1)
          } else {
            Toast.fail(res.message, 1)
          }
        }
      });
  }

  render() {
    const { cardInfo,cardStatus,monthFlow,sm } = this.state;
    return (
      <div className="simInfo">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            卡号信息查询
          </NavBar>
        </div>
        <div className='sub-container'>
          <SearchBar
              onSubmit={value => this.submit(value)}
              placeholder="请输入查询ICCID"
              maxLength={20} />
          {cardInfo && Object.keys(cardInfo).length > 0 ? 
          (
            sm=='02'?(
              <List>
                <WhiteSpace size="lg" />
                <Item>
                  ICCID： <Brief>{cardInfo.iccid}</Brief>
                </Item>
                <Item>
                  套餐名称： <Brief>{cardInfo.pkgName}</Brief>
                </Item>
                <Item>
                  计费周期： <Brief>{cardInfo.pkgCycle}</Brief>
                </Item>
                {
                  cardInfo.pkgBeginDate?(
                  <Item>
                    套餐生效时间： <Brief>{cardInfo.pkgBeginDate}</Brief>
                  </Item>):null
                }
                {
                  cardInfo.pkgEndDate?(
                    <Item>
                      套餐失效时间： <Brief>{cardInfo.pkgEndDate}</Brief>
                    </Item>
                  ):null
                }
                <Item>
                  卡状态： <Brief>{cardStatus.status }</Brief>
                </Item>
              </List> 
            ):(sm=='03'?(
              <List>
                <WhiteSpace size="lg" />
                <Item>
                  物联网卡号： <Brief>{cardInfo.access_number}</Brief>
                </Item>
                <Item>
                  ICCID： <Brief>{cardStatus.number}</Brief>
                </Item>
                <Item>
                3G_IMSI： <Brief>{cardInfo['3G_IMSI']}</Brief>
                </Item>
                <Item>
                4G_IMSI： <Brief>{cardInfo['4G_IMSI']}</Brief>
                </Item>
                <Item>
                  生效日期： <Brief>{cardStatus.servCreateDate}</Brief>
                </Item>
                <Item>
                  状态： <Brief>{cardStatus.operatorDefinitionStatusName}</Brief>
                </Item>
                <Item>
                  已使用流量： <Brief>{monthFlow.totalByteCnt}</Brief>
                </Item>
                <Item>
                  使用时长： <Brief>{monthFlow.durationch}</Brief>
                </Item>
              </List> 
            )
            :(
              <List>
                <WhiteSpace size="lg" />
                <Item>
                  ICCID： <Brief>{cardInfo.iccid}</Brief>
                </Item>
                <Item>
                  MSISDN： <Brief>{cardInfo.msisdn}</Brief>
                </Item>
                <Item>
                  本月已使用流量： <Brief>{monthFlow.dataAmount}kb</Brief>
                </Item>
                <Item>
                  卡状态： <Brief>
                    {cardStatus.cardStatus == 1 ? '待激活' : ''}
                    {cardStatus.cardStatus == 2 ? '已激活' : ''}
                    {cardStatus.cardStatus == 4 ? '已停机' : ''}
                    {cardStatus.cardStatus == 6 ? '可测试' : ''}
                    {cardStatus.cardStatus == 7 ? '库存' : ''}
                    {cardStatus.cardStatus == 8 ? '预销户' : ''}
                    {cardStatus.cardStatus == 9 ? '已销户' : ''}
                  </Brief>
                </Item>
                <Item>
                  激活日期： <Brief>{cardInfo.activeDate}</Brief>
                </Item>
                <Item>
                  开卡日期： <Brief>{cardInfo.openDate}</Brief>
                </Item>
              </List> ))
          ):<NoneDatas name="暂无查询信息" />
          }
        </div>
      </div >
    )
  }
}
export default SimInfo
