import React, { Component } from 'react'

export class NoneDatas extends Component {
  render() {
    return (
      <div>
        <div  style={{padding:'20px 0',textAlign:'center',color:'#888'}}>
            {this.props.name?this.props.name:'暂无信息'}
        </div>
      </div>
    )
  }
}

export default NoneDatas
