import React, { Component } from "react";
import { createForm } from "rc-form";
import axios from "axios";
import http from '../../../../utils/request'
import {
  NavBar,
  Icon,
  List,
  WhiteSpace,
  Pagination,
  WingBlank,
  Button,
  Accordion,
  Checkbox,
  Radio,
  InputItem,
  Toast,
  Badge,
} from "antd-mobile";
import { connect } from "dva";
import '../uplist/uplist.scss'
import UserSelectGroups from '../../../userManage/newUserManage/userAdd/userSelectGroup'
import DefaultGroup from '../../../userManage/newUserManage/userAdd/defaultGroup'
import { types } from "babel-core";

const Item = List.Item;
const RadioItem = Radio.RadioItem;
const CheckboxItem = Checkbox.CheckboxItem;
const selectedContainer = {
  position: "fixed",
  width: "100%",
  top: "0px",
  background: "#f5f5f5",
  height: "100%",
  zIndex: "11",
  transition: "left 0.4s",
}
const userMsg = 
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg"));

export class Tdaka extends Component {
  state = {
    inputval: "",
    drawerLeft: document.body.clientWidth,
    drawerLeftDefault: document.body.clientWidth,
    arr: {},
    groupDatas: [],
    userInfo: {
      gpsInterval: 60,
    },
    groulistdata: [],
    funcGidArr: [],
    checkedFuncGidArr: [],
    scanResult: '',
    defaultGid: "",
    pageTotalNum: 0,
    pageNo: 1,
    arrer: [],
    totalPrice: "0",
    quanjin: [],
    preValidateMonth: "",
    moren: '',
    voice: false, //会话权限
    switchs: false, //切组
    single: false, //单呼
    groupSingle: false, //跨组单呼
    funcValueList: [0,1,3,9],
    isOne: true,
    isMore: false,
    imeiList: [],
  };
  componentDidMount() {
    console.log('??????????????')
    // const imei = JSON.parse(localStorage.getItem("scan:result:imei"));
    const imei = '?'
    const imeiList = localStorage.getItem("scan:result:imeiList")
    console.log('start',imei,imeiList)
    this.setState({
      scanResult: imei ? imei : '',
      imeiList: imeiList
    });
    this.getGroupList();
    this.getGroupTOD();
    this.getPreValidateMonth();
  }
  getPreValidateMonth() {
    return new Promise((resolve, reject) => {
      http.get("/api/user/admin/preferentialMonths/get",{},'new').then((res) => {
        if (res.status == 200 && res.data) {
          if (res.data >= 0) {
            this.setState({
              preValidateMonth: res.data,
            });
          }
        }
      });
    });
  }

  getGroupList(e) {
    http
      .post("/api/group/list", {
        page: {
          pageNo: e,
          pageSize: 10,
        },
      },'new')
      .then((res) => {
        if (res && res.status == 200) {
          const { resultList } = res.data
          const defaultGid = resultList && resultList.length > 0 ? resultList[0].gid : "";
          if (e) {
            this.props.form.setFieldsValue({
              defaultGid: defaultGid,
            });
          }
          this.setState({
            groupDatas: res.data.resultList || [],
            pageTotalNum: Math.ceil(res.data.totalCount / 10),
            pageNo: res.data.pageNo,
            arr: resultList ? resultList[0] : {},
            defaultGid,
          }, () => {
            this.createDegaultGroup();
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  createDegaultGroup() {
    const { groupDatas } = this.state
    setTimeout(() => {
      console.log('groupDatas', groupDatas)
      if (groupDatas && groupDatas.length == 0) {
        http
          .post("/api/group/add", {
            name: "对讲群组" + Math.floor((Math.random() * 100) + 1),
          },'new')
          .then(res => {
            if (res) {
              if (res.status == 200) {
                this.getGroupList();
              } else {
                Toast.fail(res.message);
              }
            }
          })
          .catch(res => {
            Toast.fail("网络错误");
          });
      }
    }, 1000);
  }

  onPageChange(e) {
    const { pageTotalNum } = this.state;
    e = e > pageTotalNum ? pageTotalNum : e;
    console.log(e);
    this.getGroupList(e);
    this.setState({
      selectItem: [],
    });
  }
  getGroupTOD(e) {
    http
      .get("/api/func/group/list", {
        page: {
          pageNo: e,
          pageSize: 10,
        },
      },'new')
      .then((res) => {
        if (res && res.status == 200 && res.data) {
          const defaultFgid = res.data ? [res.data[0].id] : [];
          this.state.quanjin.push(res.data[0])
          this.setState({
            totalPrice: this.state.quanjin[0].pttAmount
          })
          this.setState({
            groulistdata: res.data,
            checkedFuncGidArr: defaultFgid,
            arrer: defaultFgid,
          });
        }
      });
  }
  // 页面表单提交
  handleClickTJdd = (e, item) => {
    const { voice, switchs, single, groupSingle, imeiList } = this.state;
    // 处理权限
    let str = [];
    if (voice) {
      str.push(0);
    }
    if (switchs) {
      str.push(1);
    }
    if (single) {
      str.push(3);
    }
    if (groupSingle) {
      str.push(9);
    }
    // 处理imei
    let arrData = JSON.parse(imeiList)
    let imeiArr = []
    arrData.map((item,index) =>{
      imeiArr.push(item.imei)
    })
    this.props.form.validateFields({ force: true }, error => {
      console.log(error,"error_handleClickTJdd");
      const fildsValue = this.props.form.getFieldsValue();
      console.log('fildsValue ======== ', fildsValue,this.state.funcValueList,str)
      let newUserTyep = 3
      if(this.state.isMore){
        newUserTyep = 0
      }
      if (!error) {
        Toast.loading("加载中...", 5);
        http
          .post("/api/user/ptt/write_account/qr_code", {
            ...fildsValue,
            funcValueList: str,
            type: newUserTyep,
            imeiList: imeiArr
          },'new')
          .then((res) => {
            console.log(res,'请求返回')
            if ( res&&res.status == 200) {
              Toast.success(res.message, 5);
              this.props.history.push("/menu");
              localStorage.removeItem("scan:result:imei")
            } else {
              Toast.info(res.message, 1);
            }
          });
      }
    });
  }

  // 验证选择加入的群组
  validateInGroups = (rule, value, callback) => {
    if (this.props.groups.length < 1) {
        callback(new Error("请选择用户所在群组"));
    } else {
        callback();
    }
  }

  // 验证选择的默认群组
  validateDefaultGid = (rule, value, callback) => {
    let defaultGroup = this.props.defaultGroup;
    if (defaultGroup && defaultGroup.name) {
        callback();
    } else {
        callback(new Error("请选择用户默认群组"));
    }
  }

  render() {
    const {
      getFieldProps,
      getFieldError,
    } = this.props.form;
    const {
      groupDatas,
      pageTotalNum,
      pageNo,
      groulistdata,
      checkedFuncGidArr,
      defaultGid,
      arr,
      scanResult,
      totalPrice,
      quanjin,
      preValidateMonth,
      drawerLeft,
      drawerLeftDefault,
      isOne,
      isMore,
    } = this.state;
    const {groups,groupsGidArr,addDefaultGroup,defaultGroup,match} = this.props

    const  {imei}  = scanResult
    console.log('~?~?~?',imei,scanResult)
    const onChange = (e, id, i) => {
      if (e.target.checked) {
        this.state.arrer.push(i.id);
        this.state.quanjin.push(i);
        console.log(this.state.arrer);
      } else {
        this.state.arrer.splice(
          this.state.arrer.map((i) => i.id).indexOf(id),
          1
        );
        this.state.quanjin.splice(
          this.state.quanjin.map((i) => i).indexOf(i),
          1
        );
      }
      this.props.form.setFieldsValue({
        funcsList: this.state.arrer,
      });
      this.setState(
        {
          checkedFuncGidArr: this.state.arrer,
        },
        () => {
          console.log(checkedFuncGidArr);
          sumTotalPrice();
        }
      );
    };
    const sumTotalPrice = () => {
      let sumTotalPrice = 0;
      quanjin.filter((item) => {
        sumTotalPrice += item.pttAmount;
      });
      this.setState({
        totalPrice: sumTotalPrice.toString(),
      });
    };
    const validateNoChinese = (rule, value, callback) => {
      let reg = /^[^\u4e00-\u9fa5]+$/g;
      let regEmpty = /^\s*$/g;
      if (value && !reg.test(value)) {
        callback('书写格式错误只能输入数字，字母');
      } else if (value && regEmpty.test(value)) {
        callback('不能为空');
      } else {
        callback();
      }
    }
    const onChangeAction = (e, id, i) => {
      if (arr != "") {
        this.setState(
          {
            arr: i,
          },
          () => {
            console.log("哈哈", this.state.arr);
          }
        );
      } else {
        this.setState({
          arr: i
        })

      }
      this.props.form.setFieldsValue({
        defaultGid: id,
      });
      this.setState({
        defaultGid: id,
      });

    };

    // 
    const onSingleCall = (i,type) => {
      console.log(i.target.checked,type);
      if (type == 1) {
        this.setState({
          voice: i.target.checked
        });
      } else if (type == 2) {
        this.setState({
          switchs: i.target.checked
        });
      } else if (type == 3) {
        this.setState({
          single: i.target.checked
        });
      } else {
        this.setState({
          groupSingle: i.target.checked
        });
      }
      // this.setState({
      //   funcValueList: e.target.checked ? [0,1,3,9] : [0,1,3,9],
      // })
    };
    
    const checkOneUserType = () => {this.setState({ isOne: true, isMore: false})}
    const checkMoreUserType = () => {this.setState({ isOne: false, isMore: true})}

    console.log(groulistdata, 'groulistdata')
    return (
      <div className="uhss-list">
        <div className="list-er">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.push("/clockinsdk")}
          >
            批量创建账号
          </NavBar>
        </div>

        <div className="sub-container">
          <form>
            {/* <List renderFooter={() => getFieldError("imei")}>
              <InputItem
                {...getFieldProps("imei", {
                  rules: [
                    {
                      required: true,
                      message: 'IMEI号不能为空',
                    },
                  ],
                  initialValue: imei,
                })}
                type="number"
                editable={false}
                disabled={preValidateMonth > 0}
                onBlur={sumTotalPrice}
                clear
              >
                <span className="red-star">*</span>
                IMEI号：
              </InputItem>
            </List> */}
            {/* <List renderFooter={() => getFieldError("account")}>
              <InputItem
                {...getFieldProps("account", {
                  rules: [
                    {
                      required: true,
                      message: '账号不能为空',
                    },
                  ],
                  initialValue: imei,
                })}
                type="type"
                editable={false}
                defaultValue={12}
                disabled={preValidateMonth > 0}
                onBlur={sumTotalPrice}
                clear
              >
                <span className="red-star">*</span>
                账号：
              </InputItem>
            </List> */}
            <Item >
              <CheckboxItem checked={isOne} onChange={checkOneUserType}>创建一年期</CheckboxItem>
              <WingBlank/>
              <CheckboxItem checked={isMore} onChange={checkMoreUserType}>创建三年期</CheckboxItem>
            </Item>
            <List>
              <InputItem
                {...getFieldProps("name", {
                  initialValue: "",
                })}
                type="type"
                editable={true}
                maxLength={14}
              >
                名称：
              </InputItem>
            </List>
            
            {/* <List renderFooter={() => getFieldError("defaultGid")}>
              <Accordion defaultActiveKey="0">
                <Accordion.Panel header={<span><span className="red-star">*</span>群组</span>}
                  {...getFieldProps("defaultGid", {
                    rules: [
                      {
                        required: true,
                        message: '请选择群组',
                      },
                    ],
                    initialValue: defaultGid,
                  })}>
                  <List>
                    {groupDatas.map((i, index) => {
                      return (
                        <RadioItem
                          key={index}
                          checked={defaultGid == i.gid}
                          onChange={(e) => onChangeAction(e, i.gid, i)}
                        >
                          {i.name}
                        </RadioItem>
                      );
                    })}
                  </List>
                  <WingBlank>
                    {pageTotalNum > 0 ? (
                      <Pagination
                        total={pageTotalNum}
                        current={pageNo}
                        locale={{
                          prevText: "上一页",
                          nextText: "下一页",
                        }}
                        onChange={(e) => this.onPageChange(e)}
                      />
                    ) : (
                        ""
                      )}
                  </WingBlank>
                </Accordion.Panel>
              </Accordion>
            </List> */}
            {/* 选择多个群组 */}
            <InputItem
              className='hide-input'
              arrow="horizontal"
              extra = {
                <span
                  onClick={()=>{
                    this.setState({ drawerLeft: 0 })
                  }}
                >
                  {groupsGidArr.length < 1 
                    ? '选择群组'
                    :`已选择${groupsGidArr.length}个群组` }
                </span>
              }
              onClick={()=>{ this.setState({ drawerLeft: 0 })}}
              editable="false"
              key="10"
              {...getFieldProps("joinGids",{
                rules:[{validator: this.validateInGroups}],
                initialValue: groupsGidArr
              })}
            >
              <i className="red-star">*</i>
              所属群组
            </InputItem>
            {/* 选择一个默认群组 */}
            <InputItem
              className="hide-input"
              value={defaultGroup}
              arrow="horizontal"
              editable="false"
              extra = {
                <div
                  style={{
                    textAlign: " right",
                    width: "50vw",
                    minWidth: "100px",
                    height: "16px",
                    fontSize: "12px"
                  }}
                  onClick={()=>{
                    if(groupsGidArr.length < 1){
                      Toast.info('请先选择所属群组',1.5)
                    } else {
                      this.setState({
                        drawerLeftDefault: 0
                      })
                    }
                  }}
                >
                  {defaultGroup && defaultGroup.name
                    ? defaultGroup.name
                    : `请选择默认群组`
                  }
                </div>
              }
              onClick={()=>{
                if(groupsGidArr.length < 1){
                  Toast.info('请先选择所属群组',1.5)
                } else {
                  this.setState({
                    drawerLeftDefault: 0
                  })
                }
              }}
              {...getFieldProps("defaultGid", {
                rules: [{validator: this.validateDefaultGid}],
                initialValue: defaultGroup.gid
              })}
            >
              <i className="red-star">*</i>
              默认群组
            </InputItem>
            {/* 账号权限 */}
            <List renderHeader="权限">
              <CheckboxItem
                {...getFieldProps('defaultCheckboxItem')}
                // checked={this.state.funcValueList.includes(3)}
                onChange={e => onSingleCall(e,1)}
              >
                会话
              </CheckboxItem>
              <CheckboxItem
                onChange={e => onSingleCall(e, 2)}
                
              >
                切组
              </CheckboxItem>
              <CheckboxItem
                onChange={e => onSingleCall(e, 3)}
              >
                单呼
              </CheckboxItem>
              <CheckboxItem
                onChange={e => onSingleCall(e, 4)}
              >
                跨组单呼
              </CheckboxItem>
            </List>
            {/* 功能组 */}
            <List renderFooter={() => getFieldError("funcsList")}>
              <Accordion defaultActiveKey="0">
                <Accordion.Panel header={<span><span className="red-star">*</span>功能组</span>}
                  {...getFieldProps("funcsList", {
                    rules: [
                      {
                        required: true,
                        message: '请选择功能组',
                      },
                    ],
                    initialValue: checkedFuncGidArr,
                  })}>
                  {groulistdata.map((i, index) => {
                    return (
                      <CheckboxItem
                        defaultChecked={index == 0}
                        key={index}
                        onChange={(e) => onChange(e, i.id, i)}
                      >
                        {i.name}
                        <List.Item.Brief>{i.remarks}</List.Item.Brief>
                      </CheckboxItem>
                    );
                  })}
                </Accordion.Panel>
              </Accordion>
            </List>
            {/* <List.Item>
              虚拟币总数：
              <Badge
                text={totalPrice}
                style={{
                  padding: "0 3px",
                  backgroundColor: "#fff",
                  color: "#108ee9",
                  borderRadius: 2,
                  border: "1px solid #108ee9",
                }}
              />
            </List.Item>
            <InputItem
              {...getFieldProps("validMonths", {
                initialValue: preValidateMonth,
              })}
              type="number"
              defaultValue={12}
              disabled={preValidateMonth > 0}
              onBlur={sumTotalPrice}
              clear
            >
              <i className="red-star">*</i>
              购买月数
            </InputItem> */}
            {/* <List>
              <InputItem type="number" editable={false} value={arr.name} defaultValue={12}
                disabled={preValidateMonth > 0}
                onBlur={sumTotalPrice}
                clear>
                选中名称：
              </InputItem>
            </List> */}
            {/* 经销商密码 */}
            <List renderFooter={() => getFieldError("password")}>
              <InputItem
                {...getFieldProps("password", {
                  rules: [
                    {
                      required: true,
                      message: '经销商密码不能为空',
                    },
                  ],
                  initialValue: 123456,
                })}
                labelNumber={6}
                type="number"
              >
                <span className="red-star">*</span>
                经销商密码：
            </InputItem>
            </List>
          </form>
          <WingBlank>
          <WhiteSpace />
          <Button type="primary" onClick={this.handleClickTJdd}>
            提交
          </Button>
          </WingBlank>
        </div>
        <div
          style={{
            ...selectedContainer,
            left: drawerLeft + "px",
          }}
        >
          <UserSelectGroups
            defaultGroup={defaultGroup}
            addDefaultGroup={addDefaultGroup}
            uid={match.params.id}
            handleChildClose={() =>
              this.setState({
                drawerLeft: document.body.clientWidth,
              })
            }
          />
      </div>
      <div
        style={{
          ...selectedContainer,
          left: drawerLeftDefault + "px",
          overflowY: "scroll",
          paddingBottom: "45",
        }}
      >
        <DefaultGroup
          selectList={groups}
          addDefaultGroup={addDefaultGroup}
          defaultGroup={defaultGroup}
          handleChildClose={() => {
            this.setState({
              drawerLeftDefault: document.body.clientWidth,
            });
          }}
        />
    </div>
      </div>
    );
  }
}

const TdakaFrom = createForm()(Tdaka)
const mapStateToProps = state => {
  return {
    groups: state.group.groups,
    groupsGidArr: state.group.groupsGidArr,
    defaultGroup: state.group.defaultGroup,
    funcGroupList: state.app.funcGroupList,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getFuncList: () => {
      dispatch({
          type: "app/getFuncGroupList",
          payload: {
            pageNo: 1,
            pageSize: 1000,
          },
      });
    },
    addDefaultGroup: group => {
      dispatch({
        type: "group/addDefaultGroup",
        group
      });
    },
    setGroups: data => {
      dispatch({
        type: "group/setGroups",
        data
      });
    },
    emptyGroups: () => {
      dispatch({
        type: "group/emptyGroups"
      });
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TdakaFrom)

// export default createForm()(Tdaka);
