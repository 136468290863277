import React,{ Component } from 'react';
import {
    NavBar,
    Icon,
    InputItem,
    List,
    Picker,
    Button,
    WhiteSpace,
    WingBlank,
    Checkbox,
    PickerView,
    DatePickerView,
    TextareaItem,
    Modal,
    Toast,
    Drawer,
    SearchBar,
    ActionSheet,
    ListView,
    PullToRefresh
  } from "antd-mobile";
import "./userAdd.scss";
import { createForm } from "rc-form";
import { formDateTime,formDateTime1 } from "../../../utils/common";
import axios from "../../../service/axios";
import http from '../../../utils/request';
import { connect } from "dva";
import { div } from 'prelude-ls';
const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;
const userMsg =
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg")).user;
const AMap = window.AMap;
var map;
const season = [
    {
        label:'全部',
        value:'',
    },
    {
        label:'在线',
        value:2,
    },
    {
        label:'离线',
        value:1,
    },
];
export class newlocationMsg extends Component{
    constructor(props){
        // 创建ListViewDataSource对象
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2
        })
        super(props)
        this.state = {
            title:'',
            mapHeight:'',
            mapWidth:'',
            headHeight:'',
            selectData:[0],
            screen:'',
            modalFlag:false,
            drawerSearch: document.body.clientWidth,
            docked:false,
            userDatas:[],
            setectArr:[],
            mapDom:1000,
            dateTime1:null,
            dateTime2:null,
            modalStartFlag:false,
            modalEndFlag:false,
            startTime:'',
            endTime:'',
            selectUid:'',
            searchKey:{},
            isAllChecked:false,
            page:1,
            size:20,
            dataSource:dataSource,
            hasMore: true,
            refreshing: true,
            isLoading: true,
        };
    }
    componentDidMount(){
        this.setState({
            mapHeight:document.body.clientHeight-this.refs.headHeight.clientHeight+'px',
            mapWidth:document.body.clientWidth+'px',
            headHeight:this.refs.headHeight.clientHeight+'px',
            dateTime1:new Date(formDateTime1(new Date())),
            dateTime2:new Date(Date.parse(new Date(formDateTime1(new Date())))+86400000)
        },()=>{
            this.setState({
                title:'终端定位'
            })
            this.getGroupSite();
        })
    }
    getGroupSite(){
        map = new AMap.Map('container',{
            zoom:12,
            resizeEnable: true,
        });
        AMap.plugin([
            'AMap.ToolBar',
            'AMap.Scale',
            'AMap.OverView',
            'AMap.MapType',
            'AMap.Geolocation',
            'AMap.Geocoder'
        ], function(){
            map.addControl(new AMap.ToolBar());
            map.addControl(new AMap.Scale());
            map.addControl(new AMap.MapType());
        });
        http.post('/api/location/getLatestCoords2',{
            isOnLine:this.state.screen
        },'new')
        .then(res=>{
            if(res.status==200){
                if(!res.data||res.data.length==0){
                    if(this.state.screen==''){
                        Toast.fail('该管理员下暂未有上报位置的终端！',2)
                        return;
                    }
                    else if(this.state.screen==2){
                        Toast.fail('该管理员下暂未在线终端！',2)
                        return;
                    }
                    else if(this.state.screen==1){
                        Toast.fail('该管理员下暂未离线终端！',2)
                        return;
                    }
                }
                var lnglats = [];
                for(var i=0;i<res.data.length;i++){
                    lnglats.push(res.data[i])
                }
                var infoWindow = new AMap.InfoWindow({
                    isCustom: true,  //使用自定义窗体
                    offset: new AMap.Pixel(16, -45)
                });
                for (var i = 0, marker; i < lnglats.length; i++) {
                    var icon = lnglats[i].isOnLine==1?require('../../../assets/images/icon/noline.png'):require('../../../assets/images/icon/line.png')
                    var marker = new AMap.Marker({
                        position: lnglats[i].location,
                        map: map,
                        icon:icon
                    });
                    var title = '详细信息',
                    content = [];
                    var status = lnglats[i].isOnLine==1?'离线':'在线'
                    content.push("用户名："+lnglats[i].username);
                    content.push("状态："+status);
                    content.push("经纬度："+marker.De.position.lng+','+marker.De.position.lat);
                    content.push("上报时间："+formDateTime(lnglats[i].creatTime));
                    var retrogress = '轨迹回放';
                    marker.content = this.singleSite(title, content.join("<br/>"),retrogress,lnglats[i]);
                    marker.on('click', markerClick);
                }
                function markerClick(e) {
                    infoWindow.setContent(e.target.content);
                    infoWindow.open(map, e.target.getPosition());
                }
                map.setFitView();
            }
            else{
                Toast.fail(res.message,2)
            }
        })
    }
    //查看终端定位信息
    singleSite(title,content,retrogress,lnglats){
        var that = this;
        var info = document.createElement("div");
        info.className = "custom-info input-card content-window-card";
        // 定义顶部标题
        var top = document.createElement("div");
        var titleD = document.createElement("div");
        var closeX = document.createElement("img");
        top.className = "info-top";
        titleD.innerHTML = title;
        closeX.src = "https://webapi.amap.com/images/close2.gif";
        closeX.onclick = closeInfoWindow;
        top.appendChild(titleD);
        top.appendChild(closeX);
        info.appendChild(top);
        // 定义中部内容
        var middle = document.createElement("div");
        middle.className = "info-middle";
        middle.style.backgroundColor = 'white';
        middle.innerHTML = content;
        info.appendChild(middle);
        if(retrogress){
            //定义轨迹回放按钮
            var retrogressDom = document.createElement("div");
            retrogressDom.className = 'retrogress';
            retrogressDom.setAttribute("uid",lnglats.id)
            retrogressDom.onclick = retrogressBtn;
            retrogressDom.innerHTML = retrogress;
            info.appendChild(retrogressDom);
        }
        // 定义底部内容
        var bottom = document.createElement("div");
        bottom.className = "info-bottom";
        bottom.style.position = 'relative';
        bottom.style.top = '0px';
        bottom.style.margin = '0 auto';
        var sharp = document.createElement("img");
        sharp.src = "https://webapi.amap.com/images/sharp.png";
        bottom.appendChild(sharp);
        info.appendChild(bottom);
        return info;
        function retrogressBtn(e){
            console.log(e)
            console.log(this.getAttribute('uid'))
            that.setState({
                selectUid:this.getAttribute('uid'),
                modalStartFlag:true,
                mapDom:0
            })
        }
        function closeInfoWindow() {
            map.clearInfoWindow();
        }
    }
    //轨迹回放事件
    playback(arr){
        var lineArr = [];
        for(var i=0;i<arr.length;i++){
            lineArr.push(arr[i].location)
        }
        map = new AMap.Map('container',{
            zoom:12,
            resizeEnable: true,
            center:arr[0].location
        });
        AMap.plugin([
            'AMap.ToolBar',
            'AMap.Scale',
            'AMap.OverView',
            'AMap.MapType',
            'AMap.Geolocation',
        ], function(){
            map.addControl(new AMap.ToolBar());
            map.addControl(new AMap.Scale());
            map.addControl(new AMap.MapType());
        });
        var marker;
        marker = new AMap.Marker({
            map: map,
            position: arr[0].location,
            icon: "https://webapi.amap.com/images/car.png",
            offset: new AMap.Pixel(-26, -13),
            autoRotation: true,
            angle:-90,
        });
        // 绘制轨迹
        var polyline = new AMap.Polyline({
            map: map,
            path: lineArr,
            showDir:true,
            strokeColor: "blue",  //线颜色
            strokeWeight: 6,      //线宽
        });
        var passedPolyline = new AMap.Polyline({
            map: map,
            strokeColor: "red",  //线颜色
            strokeWeight: 6,      //线宽
        });
        marker.on('moving', function (e) {
            passedPolyline.setPath(e.passedPath);
        });
        map.setFitView();
        marker.moveAlong(lineArr, 2000);
    }
    onClose(){
        this.setState({
            modalFlag:false,
        })
    }
    selectChange = (value) => {
        console.log(value);
        this.setState({
            selectData:value,
        });
    }
    affirm(){
        console.log(this.state.selectData)
        this.setState({
            screen:this.state.selectData[0]
        },()=>{
            for(var i=0;i<season.length;i++){
                if(this.state.screen==season[i].value){
                    this.setState({
                        condition:season[i].label,
                        modalFlag:false
                    },()=>{
                        this.onClose();
                        this.getGroupSite();
                    })
                }
            }
        })
    }
    onChange(e,key){
        key = Number(key)
        const { userDatas } = this.state;
        const newUserDatas = userDatas;
        for(var i=0;i<newUserDatas.length;i++){
            if(i==key){
                newUserDatas[i].checked=e.target.checked
            }
        }
        var arr = [];
        for(var i=0;i<newUserDatas.length;i++){
            if(newUserDatas[i].checked){
                arr.push(newUserDatas[i].uid);
            }
        }
        console.log(arr)
        if(arr.length==newUserDatas.length){
            this.setState({
                isAllChecked: true,
            })
        }else{
            this.setState({
                isAllChecked: false,
            })
        }
        this.setState({
            setectArr:arr,
            userDatas:newUserDatas,
            dataSource: this.state.dataSource.cloneWithRows(JSON.parse(JSON.stringify(newUserDatas))),

        })
    }
    getUserList(ref = false) {
        var para ={
            pageSize:this.state.size,
            pageNo:this.state.page
        }
        http.post("/api/user/ptt/list", {
            page: para,
            pttUserEntity:{
                ...this.state.searchKey,
            },
        },'new')
        .then(res => {
            console.log(res)
            if (res && res.status == 200) {
                var dataList = res.data.resultList||[];
                const len = dataList.length;
                if (len <= 0) {
                    this.setState({
                        refreshing: false,
                        isLoading: false,
                        hasMore: false
                    })
                    return false;
                }
                var userDatas = this.state.userDatas.concat(dataList);
                for(var i=0;i<userDatas.length;i++){
                    userDatas[i].checked= userDatas[i].checked?userDatas[i].checked:false;
                }
                if (ref) {
                    console.log(ref)
                    this.setState({
                        pageNo: 1,
                        dataSource: this.state.dataSource.cloneWithRows(userDatas),
                        hasMore:true,
                        refreshing: false,
                        isLoading: false,
                        userDatas: userDatas
                    })
                }
                else{
                    this.setState({
                        pageNo: this.state.pageNo,
                        dataSource: this.state.dataSource.cloneWithRows(userDatas),
                        refreshing: false,
                        isLoading: false,
                        userDatas: userDatas
                    })
                }
            }
            else {
                
                Toast.fail("请求过快，请刷新重试！",2);
            }
        });
    }
    // 下拉刷新
    onRefresh = () => {
        console.log(1)
        this.setState({
            refreshing: true,
            isLoading: true,
            page: 1,
            userDatas:[],
        }, ()=>{
            this.getUserList(true)
        })
    }
    // 滑动到底部时加载更多
    onEndReached = (event) => {
        console.log(event)
        if (this.state.isLoading || !this.state.hasMore) {
            return
        }
        this.setState({
            isLoading: true,
            page: this.state.page + 1,
        }, ()=> {
            this.getUserList(false)
        })
    }
    confirm(){
        this.closeModel()
        if(this.state.setectArr.length==0){
            Toast.fail('请勾选用户！',2)
            return;
        }else{
            this.setState({
                userDatas:[],
                page:1,
                setectArr:[]
            })
            this.getUserSite(this.state.setectArr)
        }
    }
    goBack(){
        this.setState({
            userDatas:[],
            page:1
        })
        this.closeModel()
    }
    //获取选择用户后的定位
    getUserSite(arr){
        http.post('/api/location/getLatestCoords',{
            uids:arr,
            isOnLine:''
        },'new')
        .then(res=>{
            console.log(res)
            if(res.status==200){
                if(res.data.length==0){
                    map = new AMap.Map('container',{
                        zoom:12,
                        resizeEnable: true,
                    });
                    AMap.plugin([
                        'AMap.ToolBar',
                        'AMap.Scale',
                        'AMap.OverView',
                        'AMap.MapType',
                        'AMap.Geolocation',
                    ], function(){
                        map.addControl(new AMap.ToolBar());
                        map.addControl(new AMap.Scale());
                        map.addControl(new AMap.MapType());
                    });
                    Toast.fail('所选择用户暂未上报位置信息！',2)
                    return;
                }
                else{
                    map = new AMap.Map('container',{
                        zoom:12,
                        resizeEnable: true,
                        center:res.data[0].location
                    });
                    AMap.plugin([
                        'AMap.ToolBar',
                        'AMap.Scale',
                        'AMap.OverView',
                        'AMap.MapType',
                        'AMap.Geolocation',
                    ], function(){
                        map.addControl(new AMap.ToolBar());
                        map.addControl(new AMap.Scale());
                        map.addControl(new AMap.MapType());
                    });
                    var lnglats = [];
                    for(var i=0;i<res.data.length;i++){
                        lnglats.push(res.data[i])
                    }
                    var infoWindow = new AMap.InfoWindow({
                        isCustom: true,  //使用自定义窗体
                        offset: new AMap.Pixel(16, -45)
                    });
                    for (var i = 0, marker; i < lnglats.length; i++) {
                        var icon = lnglats[i].isOnLine==1?require('../../../assets/images/icon/noline.png'):require('../../../assets/images/icon/line.png')
                        var marker = new AMap.Marker({
                            position: lnglats[i].location,
                            map: map,
                            icon:icon
                        });
                        var title = '详细信息',
                        content = [];
                        var status = lnglats[i].isOnLine==1?'离线':'在线'
                        content.push("用户名："+lnglats[i].username);
                        content.push("状态："+status);
                        content.push("经纬度："+marker.De.position.lng+','+marker.De.position.lat);
                        content.push("上报时间："+formDateTime(lnglats[i].creatTime));
                        var retrogress = '轨迹回放';
                        marker.content = this.singleSite(title, content.join("<br/>"),retrogress,lnglats[i]);
                        marker.on('click', markerClick);
                    }
                    function markerClick(e) {
                        console.log(e)
                        infoWindow.setContent(e.target.content);
                        infoWindow.open(map, e.target.getPosition());
                    }
                    map.setFitView();
                }
            }
            else{
                Toast.fail(res.message,2)
            }
        })
    }
    //关闭抽屉
    closeModel(){
        setTimeout(()=>{
            this.setState({
                mapDom:1000
            })
        },700)
        this.setState({
            docked:false,
            modalStartFlag:false,
            modalEndFlag:false,
            modalFlag:false,
            isAllChecked:false,
        })
    }
    onClose(){
        this.closeModel()
    }
    onChange1 = (value) => {
        this.setState({ dateTime1:value });
    };
    onChange2 = (value) => {
        this.setState({ dateTime2:value });
    };
    affirm1(){
        this.setState({
            modalStartFlag:false,
            modalEndFlag:true
        })
    }
    getUserlnglat(startTime,endTime){
        http.post('/api/location/trackPlayback',{
            uids:[this.state.selectUid],
            startTime:startTime,
            endTime:endTime
        },'new')
        .then(res=>{
            if(res.status==200){
                if(JSON.stringify(res.data)=='{}'){
                    Toast.fail('该用户在一天内没有上报位置！',2)
                    return;
                }
                else if(res.data[this.state.selectUid].length==1){
                    Toast.fail('该用户在一天内只上报了一次位置！',2)
                    return;
                }
                else{
                    this.playback(res.data[this.state.selectUid])
                }
            }
            else{
                Toast.fail(res.message,2)
            }
        })
    }
    affirm2(){
        if(Date.parse(this.state.dateTime1)>Date.parse(this.state.dateTime2)){
            Toast.fail('结束日期不能小于开始日期',2)
            return;
        }
        else if((Date.parse(this.state.dateTime2)-Date.parse(this.state.dateTime1))>86400000){
            Toast.fail('查询时间不能超过一天',2)
            return;
        }
        else if(formDateTime1(this.state.dateTime1)==formDateTime1(this.state.dateTime2)){
            this.setState({
                startTime:formDateTime1(this.state.dateTime1),
                endTime:formDateTime1(Date.parse(new Date(this.state.dateTime2))+86400000)
            },()=>{
                this.closeModel();
                this.getUserlnglat(this.state.startTime,this.state.endTime)
            })
        }
        else{
            this.closeModel();
            this.setState({
                startTime:formDateTime1(this.state.dateTime1),
                endTime:formDateTime1(this.state.dateTime2)
            },()=>{
                this.getUserlnglat(this.state.startTime,this.state.endTime)
            })
        }
    }
    submit = (name) => {
        if(name){
            let data = Object.assign({}, this.state.datavalue, { name })
            this.setState({
                page:1,
                searchKey:data,
                userDatas:[]
            },()=>{
                this.getUserList(true);
            })
        }
        else{
            this.setState({
                page:1,
                searchKey:{},
                userDatas:[]
            },()=>{
                this.getUserList(true);
            })
        }
    }
    allCheckMonth=(e)=>{
        const idChecked = e.target.checked;
        const { userDatas } = this.state;
        const newUserDatas = userDatas;
        newUserDatas.map((item) => {
            item.checked = idChecked;
        });
        this.setState({
            isAllChecked: idChecked,
            userDatas: newUserDatas,
            dataSource: this.state.dataSource.cloneWithRows(JSON.parse(JSON.stringify(newUserDatas))),
        });
        if(idChecked){
            var arr = [];
            for(var i=0;i<newUserDatas.length;i++){
                arr.push(newUserDatas[i].uid)
            }
            this.setState({
                setectArr:arr
            })
        }
        else{
            this.setState({
                setectArr:[]
            })
        }
    }
    row = (rowData, sectionID, rowID) => {
        return (
            <CheckboxItem key={rowID}  checked={rowData.checked} onChange={(e) => this.onChange(e,rowID)}>
                {rowData.name}
            </CheckboxItem>
        )
    }
  render(){
    var aHeight = document.body.clientHeight-165-44-44;
    const {
        history
    } = this.props;
    const { mapWidth,mapHeight,headHeight,title,condition } = this.state;
    // const row = (rowData, sectionID, rowID) => {
    //     console.log('111',rowData,rowData.checked)
    //     return (
    //         <CheckboxItem key={rowID}  checked={rowData.checked?rowData.checked:false} onChange={() => this.onChange(rowID)}>
    //             {rowData.name}
    //         </CheckboxItem>
    //     )
    // }
    const sidebar = (
        <div>
            <div>
                <SearchBar
                    onSubmit={value => this.submit(value)}
                    placeholder="输入用户名进行模糊查询"
                    maxLength={20} />
                <div>
                    <CheckboxItem
                        onChange={(e)=>{this.allCheckMonth(e)}}
                        checked={this.state.isAllChecked}
                        >
                        全选
                    </CheckboxItem>
                    <ListView
                        style={{height:aHeight+'px',overflow:'scroll'}}
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (<div className="footer">{this.state.isLoading ? '数据加载中...' : '我也是有底线的！'}</div>)}
                        renderRow={this.row.bind(this)}
                        // useBodyScroll={true}
                        pullToRefresh={
                            <PullToRefresh
                                refreshing={this.state.refreshing}
                                onRefresh={this.onRefresh}
                            />
                        }
                        onEndReachedThreshold={10}
                        onEndReached={this.onEndReached}
                        pageSize={5}
                        initialListSize={0}
                    />
                </div>
                
                {/**/}
                {
                    this.state.dataState?<div className="loading">{this.state.loadMsg}</div>:<div className="loading">{this.state.noData}</div>
                }
            </div>
            <Button onClick={this.confirm=this.confirm.bind(this)} type="primary" style={{width:'200px',margin:'0 auto',height:'40px',lineHeight:'40px',marginBottom:'5px',marginTop:'10px'}}>确定</Button>
            <Button onClick={this.goBack=this.goBack.bind(this)} style={{width:'200px',margin:'0 auto',height:'40px',lineHeight:'40px',marginBottom:'10px'}}>返回</Button>
        </div>
    );
    return (
        <div className="adduser">
            <div ref="headHeight" className="header-sticky">
                <NavBar
                    icon={<Icon type="left" />}
                    onLeftClick={() => history.goBack()}
                    rightContent={[
                        !this.props.match.params.type?(
                            <div key='2'>
                                <Icon
                                    type="search"
                                    size="sm"
                                    style={{position:'relative',top:'7px',marginRight:'10px'}}
                                    key="1"
                                    onClick={() =>{
                                        if(!this.state.docked){
                                            this.getUserList(true);
                                            this.setState({
                                                mapDom:0
                                            })
                                        }
                                        else{
                                            this.closeModel()
                                        }
                                        this.setState({
                                            docked:!this.state.docked,
                                            modalFlag:false
                                        })
                                    }}
                                />
                                <span
                                    key='2'
                                    onClick={()=>{
                                        this.setState({
                                            modalFlag:true,
                                            mapDom:0
                                        })
                                    }}
                                    style={{ fontSize: '12px' }}
                                >状态</span>
                            </div>
                        ):null
                    ]}
                >
                    {title}
                </NavBar>
            </div>
            <Modal
                popup
                visible={this.state.modalFlag}
                onClose={this.onClose=this.onClose.bind(this)}
                animationType="slide-up"
                >
                    <div style={{display:'flex',justifyContent:'space-between',height:'40px',alignItems:'center',padding:'0 10px'}}>
                        <div onClick={()=>{this.setState({modalFlag:false,mapDom:1000})}}>取消</div>
                        <div>查询</div>
                        <div onClick={this.affirm=this.affirm.bind(this)}>确定</div>
                    </div>
                    <PickerView
                        value={this.state.selectData}
                        data={season}
                        onChange={this.selectChange}
                        cascade={false}
                    />
            </Modal>
            <div className="mapCmp">
                <div ref="mapDom" style={{width:mapWidth,height:mapHeight,position:'absolute',top:headHeight,zIndex:this.state.mapDom}} id="container"></div>
            </div>
            <Drawer
                className="my-drawer"
                ref="myDrawer"
                style={{ minHeight: document.documentElement.clientHeight}}
                contentStyle={{ color: '#A6A6A6', textAlign: 'center', paddingTop: 42 }}
                sidebarStyle={{ border: '1px solid #ddd' }}
                sidebar={sidebar}
                docked={this.state.docked}
            >
            </Drawer>
            <Modal
                popup
                visible={this.state.modalStartFlag}
                onClose={this.onClose=this.onClose.bind(this)}
                animationType="slide-up"
                >
                    <div style={{display:'flex',justifyContent:'space-between',height:'40px',alignItems:'center',padding:'0 10px'}}>
                        <div onClick={()=>{this.setState({modalStartFlag:false,mapDom:1000})}}>取消</div>
                        <div>选择开始日期</div>
                        <div onClick={this.affirm1=this.affirm1.bind(this)}>确定</div>
                    </div>
                    <DatePickerView
                        mode="date"
                        value={this.state.dateTime1}
                        onChange={this.onChange1}
                    />
            </Modal>
            <Modal
                popup
                visible={this.state.modalEndFlag}
                onClose={this.onClose=this.onClose.bind(this)}
                animationType="slide-up"
                >
                    <div style={{display:'flex',justifyContent:'space-between',height:'40px',alignItems:'center',padding:'0 10px'}}>
                        <div onClick={()=>{this.setState({modalEndFlag:false,mapDom:1000})}}>取消</div>
                        <div>选择结束日期</div>
                        <div onClick={this.affirm2=this.affirm2.bind(this)}>确定</div>
                    </div>
                    <DatePickerView
                        mode="date"
                        value={this.state.dateTime2}
                        onChange={this.onChange2}
                    />
            </Modal>
        </div>
    )
  }
}

