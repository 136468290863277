import React, { Component } from "react";
import {
  NavBar,
  Icon,
  InputItem,
  List,
  Picker,
  Button,
  WhiteSpace,
  WingBlank,
  Checkbox,
  TextareaItem,
  Toast
} from "antd-mobile";
import { createForm } from "rc-form";
import "./userAdd.scss";
import axios from "../../../service/axios";
import { connect } from "dva";
import UserSelectGroups from "./userSelectGroup";
import DefaultGroup from "./defaultGroup";
const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;
const selectedContainer = {
  position: "fixed",
  width: "100%",
  top: "0px",
  background: "#f5f5f5",
  height: "100%",
  zIndex: "11",
  transition: "left 0.4s"
};
const GpsInterval = [
  {
    label: <div key="1">0s</div>,
    value: 0
  },
  {
    label: <div key="2">1分钟</div>,
    value: 60
  },
  {
    label: <div key="3">5分钟</div>,
    value: 300
  },
  {
    label: <div key="4">10分钟</div>,
    value: 600
  }
];

export class AddUser extends Component {
  state = {
    userInfo: {
      gpsInterval: 0
    },
    type: this.props.match.params.type,
    billList: [],
    voice: true, //会话权限
    switchs: true, //切组
    single: true, //单呼
    groupSingle: true, //跨组单呼
    drawerLeft: document.body.clientWidth,
    drawerLeftDefault: document.body.clientWidth,
    showDivDefaultGroup: "block"
  };
  async componentDidMount() {
    console.log(this.props);
    this.props.emptyGroups();
    Toast.loading("加载数据中...", 0);
    if (this.props.match.params.type == "edit") {
      await this.getUserInfo();
      await this.getUserIsIngroup();
    }
    await this.getBillsByPttUser();
  }
  //确定提交
  submitBtn() {
    this.props.form.validateFields({ force: true }, error => {
      if (!error) {
        let params = {
          ...this.props.form.getFieldsValue(),
          funcsList: this.funcsListExchange(),
          defaultGid: this.props.defaultGroup.gid,
          in_groups: this.props.groupsGidArr.join(","),
          billid: this.props.form.getFieldsValue().billid[0],
          gpsInterval: this.props.form.getFieldsValue().gpsInterval[0],
          remarks: this.props.form.getFieldsValue().remarks || "",
          name: this.props.form.getFieldsValue().name || "",
          imei: this.props.form.getFieldsValue().imei || "",
          uid: this.props.match.params.id
        };
        Toast.loading("加载中...", 0);
        let type = this.props.match.params.type == "edit" ? "edit" : "add";
        axios
          .get("/api/oa/pttUser/" + type, {
            params
          })
          .then(res => {
            console.log(res);
            if (res) {
              if (res.status == 1000 && res.data) {
                this.props.emptyGroups();
                Toast.success(res.data, 2, () => {
                  this.props.history.goBack();
                });
              } else {
                Toast.fail(res.data);
              }
            }
          });
      }
    });
  }
  //权限转换
  funcsListExchange() {
    const { voice, switchs, single, groupSingle } = this.state;
    let str = "";
    if (voice) {
      str = "0";
    }
    if (switchs) {
      str += ",1";
    }
    if (single) {
      str += ",2";
    }
    if (groupSingle) {
      str += ",9";
    }
    return str;
  }
  //获取用户所属群组
  getUserIsIngroup() {
    return new Promise(rev => {
      axios
        .get("/api/oa/pttUser/getJoinGroups", {
          params: {
            uid: this.props.match.params.id
          }
        })
        .then(res => {
          if (res && res.status == 1000 && res.data) {
            this.props.setGroups(res.data);
            rev();
          }
        });
    });
  }
  //编辑用户时候获取用户的信息
  getUserInfo() {
    return new Promise(rev => {
      axios
        .get("/api/oa/pttUser/info", {
          params: {
            uid: this.props.match.params.id
          }
        })
        .then(res => {
          console.log(res);
          rev();
          if (res && res.status == 1000 && res.data) {
            let data = {
              ...res.data,
              defaultGroup: {
                name: res.data.defaultGidName,
                gid: res.data.defaultGid
              }
            };
            this.props.addDefaultGroup({
              name: res.data.defaultGidName,
              gid: res.data.defaultGid
            });

            this.setState({
              userInfo: data,
              voice: data.funcs && data.funcs.indexOf(0) > -1,
              switchs: data.funcs && data.funcs.indexOf(1) > -1,
              single: data.funcs && data.funcs.indexOf(2) > -1,
              groupSingle: data.funcs && data.funcs.indexOf(9) > -1
            });
          }
        });
    });
  }
  //获取账单列表
  getBillsByPttUser() {
    axios
      .get("/api/oa/pttBill/getBillsByPttUser", {
        params: {
          billid: this.state.userInfo.billid
        }
      })
      .then(res => {
        console.log(res);
        Toast.hide();
        if (res && res.status == 1000 && res.data) {
          let arr = [];
          res.data.map((item, index) => {
            arr.push({
              label: (
                <div key={index} style={{ fontSize: "10px" }}>
                  {item.id +
                    `(${item.endDate.split(" ")[0]}到期,${
                    item.type == 0 ? "基础套餐" : ""
                    }${item.type == 1 ? "调度套餐" : ""}${
                    item.type == 2 ? "视频套餐" : ""
                    } ${item.leftnum}/${item.number})`}
                </div>
              ),
              value: item.id
            });
          });
          console.log(arr);
          this.setState({
            billList: arr
          });
        } else {
          Toast.fail("获取账单号列表失败！");
        }
      });
  }

  onChangeCheckBox = (i, type) => {
    if (type == 1) {
      this.setState({
        voice: i.target.checked
      });
    } else if (type == 2) {
      this.setState({
        switchs: i.target.checked
      });
    } else if (type == 3) {
      this.setState({
        single: i.target.checked
      });
    } else {
      this.setState({
        groupSingle: i.target.checked
      });
    }
  };
  validateAccount = (rule, value, callback) => {
    if (value) {
      if (value.trim()) {
        if (!/^[0-9a-zA-z\*]+$/.test(value)) {
          callback(new Error("账号只能是字母,数字和 * 组合"));
        } else {
          if (value.length < 2) {
            callback(new Error("账号长度不能少于2"));
          } else {
            callback();
          }
        }
      } else {
        callback(new Error("请输入账号"));
      }
    } else {
      callback(new Error("请输入账号"));
    }
  };

  //验证账单号
  validateBillId = (rule, value, callback) => {
    console.log(value);
    if (value[0]) {
      callback();
    } else {
      callback(new Error("请选择账单号"));
    }
  };
  //验证密码
  validatePassword = (rule, value, callback) => {
    if (value) {
      if (
        /^[A-Za-z0-9]+$/gi.test(value) &&
        value.length <= 12 &&
        value.length >= 6
      ) {
        callback();
      } else {
        callback(new Error("请输入6-12位由数字和字母组成的密码"));
      }
    } else {
      callback(new Error("请输入密码"));
    }
  };
  //验证gps
  validateGpsinterval = (rule, value, callback) => {
    if (typeof value[0] == "number") {
      callback();
    } else {
      callback(new Error("请选择GPS上报周期"));
    }
  };
  validateInGroups = (rule, value, callback) => {
    if (this.props.groups.length < 1) {
      callback(new Error("请选择用户所在群组"));
    } else {
      callback();
    }
  };
  //验证账号数
  validateCreateNumber = (rule, value, callback) => {
    if (/^\d{1,3}$/.test(value) && value > 0 && value <= 500) {
      callback();
    } else {
      callback(new Error("请输入1~500的数字"));
    }
  };
  validateDefaultGid = (rule, value, callback) => {
    if (this.props.defaultGroup) {
      callback();
    } else {
      callback(new Error("请选择用户默认群组"));
    }
  };
  validateCheckboxItem = (rule, value, callback) => {
    const { voice, switchs, single, groupSingle } = this.state;
    if (!voice && !switchs && !single && !groupSingle) {
      callback(new Error("请至少勾选一个权限"));
    } else {
      callback();
    }
  };

  render() {
    const { getFieldProps, getFieldError } = this.props.form;
    const {
      type,
      billList,
      drawerLeft,
      drawerLeftDefault,
      userInfo
    } = this.state;
    const {
      groupsGidArr,
      groups,
      addDefaultGroup,
      defaultGroup,
      match,
      history
    } = this.props;
    const {
      account,
      name,
      password,
      imei,
      gpsInterval,
      billid,
      remarks,
      hasExpired,
      usrType
    } = this.state.userInfo;
    const userMsg =
      sessionStorage.getItem("loginMsg") &&
      JSON.parse(sessionStorage.getItem("loginMsg")).user;

    return (
      <div className="adduser">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => history.goBack()}
          >
            {type == "edit" ? "修改用户" : "添加用户"}
          </NavBar>
        </div>
        <div className="sub-container">
          <form>
            <List
              renderFooter={() =>
                getFieldError("billid") ||
                getFieldError("account") ||
                getFieldError("password") ||
                getFieldError("gpsInterval") ||
                getFieldError("create_number") ||
                getFieldError("in_groups") ||
                getFieldError("defaultGid") ||
                getFieldError("defaultCheckboxItem")
              }
            >
              <Picker
                {...getFieldProps("billid", {
                  rules: [
                    { required: true, message: "请选择账单号" },
                    { validator: this.validateBillId }
                  ],
                  initialValue: [billid]
                })}
                disabled={usrType == 2 || usrType == 3 || hasExpired == 1}
                data={billList}
                cols={1}
                key="1"
              >
                <Item key="1" arrow="horizontal">
                  <i className="red-star">*</i>
                  选择账单号
                </Item>
              </Picker>
              <InputItem
                {...getFieldProps("account", {
                  rules: [
                    {
                      validator: this.validateAccount
                    }
                  ],
                  initialValue: account
                })}
                clear
                placeholder="最长11位,支持*,字母,数字"
                key="2"
                maxLength="11"
                minLength="2"
              >
                <i className="red-star">*</i>
                账号
              </InputItem>

              <InputItem
                {...getFieldProps("name", {
                  initialValue: name
                })}
                clear
                placeholder="最长11位,支持*,字母,数字和中文"
                key="3"
                maxLength="11"
                minLength="2"
              >
                名字
              </InputItem>
              <div className="am-list-item" style={{ color: '#6c6c6c', fontSize: '12px' }}>PS: 可用 * 代替序号位置进行批量创建</div>
              {this.props.match.params.type == "edit" && userMsg && userMsg.userType == 4 && !userMsg.permissionIds.includes("203") ? ("") : (
                <InputItem
                  {...getFieldProps("password", {
                    rules: [{ validator: this.validatePassword }],
                    initialValue: password
                  })}
                  clear
                  error={!!getFieldError("password")}
                  placeholder="6-12位数字、字母组合"
                  key="4"
                  maxLength="12"
                  type={type == "edit" ? "text" : "password"}
                >
                  <i className="red-star">*</i>
                  密码
              </InputItem>
              )}
              <InputItem
                {...getFieldProps("imei", {
                  initialValue: imei
                })}
                clear
                key="6"
                disabled={usrType == 3}
              >
                IMEI号
              </InputItem>
              <Picker
                {...getFieldProps("gpsInterval", {
                  rules: [
                    {
                      validator: this.validateGpsinterval
                    }
                  ],
                  initialValue: [gpsInterval]
                })}
                data={GpsInterval}
                cols={1}
                key="7"
                error={!!getFieldError("gpsInterval")}
              >
                <Item key="1" arrow="horizontal">
                  <i className="red-star">*</i>
                  GPS周期
                </Item>
              </Picker>
              {this.props.match.params.type == "edit" ? (
                ""
              ) : (
                  <div>
                    <InputItem
                      {...getFieldProps("create_number", {
                        rules: [
                          {
                            validator: this.validateCreateNumber,
                          },
                        ],
                        initialValue: 1,
                      })}
                      clear
                      placeholder="用于批量添加，范围:1~500"
                      disabled={this.state.type == "edit"}
                      type="number"
                      max="500"
                      maxLength="3"
                    >
                      账号数
                  </InputItem>
                    <InputItem
                      {...getFieldProps("beginAccount", {
                      })}
                      key="8"
                      type="number"
                      maxLength="9"
                      clear
                    >
                      开始账号
                  </InputItem>
                  </div>
                )}

              <InputItem
                {...getFieldProps("in_groups", {
                  rules: [
                    {
                      validator: this.validateInGroups
                    }
                  ],
                  initialValue: groupsGidArr
                })}
                className="hide-input"
                value={groupsGidArr}
                arrow="horizontal"
                extra={
                  <span onClick={() => this.setState({ drawerLeft: 0 })}>
                    {groupsGidArr.length < 1
                      ? `点击选择群组`
                      : `已选择${groupsGidArr.length}个群组`}
                  </span>
                }
                key="15"
                onClick={() => this.setState({ drawerLeft: 0 })}
                editable="false"
              >
                <i className="red-star">*</i>
                所属群组
              </InputItem>
              <InputItem
                {...getFieldProps("defaultGid", {
                  rules: [
                    {
                      validator: this.validateDefaultGid
                    }
                  ],
                  initialValue: defaultGroup.gid
                })}
                className="hide-input"
                value={defaultGroup}
                arrow="horizontal"
                extra={
                  <div
                    style={{ padding: " 0 20px" }}
                    onClick={() => {
                      if (groupsGidArr.length < 1) {
                        Toast.info("请先选择所属群组", 1.5);
                      } else {
                        this.setState({
                          drawerLeftDefault: 0
                        });
                      }
                    }}
                  >
                    {defaultGroup && defaultGroup.name ? defaultGroup.name : `请选择默认群组`}
                  </div>
                }
                key="14"
                editable="false"
              >
                <i className="red-star">*</i>
                默认群组
              </InputItem>
              <TextareaItem
                {...getFieldProps("remarks", {
                  initialValue: remarks
                })}
                title="备注"
                placeholder=""
                autoHeight
                maxLength="100"
              />
              <List renderHeader="权限">
                <CheckboxItem
                  {...getFieldProps("defaultCheckboxItem", {
                    rules: [
                      {
                        validator: this.validateCheckboxItem
                      }
                    ]
                  })}
                  onChange={i => this.onChangeCheckBox(i, 1)}
                  checked={this.state.voice}
                >
                  会话
                </CheckboxItem>
                <CheckboxItem
                  onChange={i => this.onChangeCheckBox(i, 2)}
                  checked={this.state.switchs}
                >
                  切组
                </CheckboxItem>
                <CheckboxItem
                  onChange={i => this.onChangeCheckBox(i, 3)}
                  checked={this.state.single}
                >
                  单呼
                </CheckboxItem>
                <CheckboxItem
                  onChange={i => this.onChangeCheckBox(i, 4)}
                  disabled={!this.state.single}
                  checked={this.state.groupSingle && this.state.single}
                >
                  跨组单呼
                </CheckboxItem>
              </List>
            </List>
            <WhiteSpace />
            <WhiteSpace />
            <WingBlank>
              {this.props.match.params.type == "edit" ? (
                this.props.match.params.searchMy != 1 && userMsg && userMsg.userType >= 1 && userMsg.userType <= 3 ? ("") : (
                  <div>
                    <Button
                      type="warning"
                      disabled={usrType == 3}
                      onClick={() =>
                        history.push(`/remotewrite/${match.params.id}`)
                      }
                    >
                      远程放号
                    </Button>
                    <WhiteSpace size="xl" />
                    {userMsg.userType == 4 && !userMsg.permissionIds.includes("201") ? ("") : (
                      <div>
                        <Button type="primary" onClick={() => this.submitBtn()}>
                          修改
                    </Button>
                        <WhiteSpace size="xl" />
                      </div>)}
                    <Button
                      onClick={() =>
                        this.props.history.push(`/userfriend/${userInfo.uid}`)
                      }
                    >
                      好友
                    </Button>
                  </div>)
              ) : (
                  <Button type="primary" onClick={() => this.submitBtn()}>
                    确定
                </Button>
                )}
            </WingBlank>
            <WhiteSpace />
            <WhiteSpace />
            <WhiteSpace />
          </form>
        </div>
        <div
          style={{
            ...selectedContainer,
            left: drawerLeft + "px"
          }}
        >
          <UserSelectGroups
            handleChildClose={() =>
              this.setState({
                drawerLeft: document.body.clientWidth
              })
            }
          />
        </div>
        <div
          style={{
            ...selectedContainer,
            left: drawerLeftDefault + "px",
            overflowY: "scroll",
            paddingBottom: "45"
          }}
        >
          <DefaultGroup
            selectList={groups}
            addDefaultGroup={addDefaultGroup}
            defaultGroup={defaultGroup}
            handleChildClose={() => {
              this.setState({
                drawerLeftDefault: document.body.clientWidth
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const AddUserForm = createForm()(AddUser);
const mapStateToProps = state => {
  return {
    groups: state.group.groups,
    groupsGidArr: state.group.groupsGidArr,
    defaultGroup: state.group.defaultGroup
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addDefaultGroup: group => {
      dispatch({
        type: "group/addDefaultGroup",
        group
      });
    },
    setGroups: data => {
      dispatch({
        type: "group/setGroups",
        data
      });
    },
    emptyGroups: () => {
      dispatch({
        type: "group/emptyGroups"
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserForm);
