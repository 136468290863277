import React, { Component } from 'react'
import {
  NavBar,
  Icon,
  List,
  Checkbox,
  WhiteSpace,
  Button,
  Toast,
} from "antd-mobile";
import axios from "../../../service/axios";
import "./adminPermission.scss";

const CheckboxItem = Checkbox.CheckboxItem;

// @connect(({ admin }) => ({ admin }))
export class AdminPermission extends Component {
  state = {
    permissionList: [],
    defaultPermissions: [],
    checkPermissions: [],
  };
  componentDidMount() {
    this.getAllPermissions();
    this.getUserPermissions();
  }

  getAllPermissions() {
    Toast.loading("加载中...", 0);
    axios
      .get("/api/oa/sysUser/getAllPermissions", {
        params: {}
      })
      .then(res => {
        Toast.hide();
        if (res && res.status == 1000 && res.data) {
          const { data } = res;
          data.shift();
          this.setState({
            permissionList: data,
          });
        }
      });
  }

  getUserPermissions() {
    axios
      .get("/api/oa/sysUser/getUserPermissions", {
        params: {
          id: this.props.match.params.id
        }
      })
      .then(res => {
        if (res && res.status == 1000 && res.data) {
          const { data } = res;
          const d = data.map(item => item.permissionId);
          this.setState({
            defaultPermissions: d,
            checkPermissions: d,
          });
        }
      });
  }

  onChange = (e, item) => {
    const { id } = item;
    let arr = this.state.checkPermissions;
    if (e.target.checked) {
      arr.push(id);
    } else {
      arr.splice(arr.map(item => item).indexOf(id), 1);
    }
    this.setState({
      checkPermissions: arr,
      defaultPermissions: arr,
    });
  }

  submit = () => {
    const { checkPermissions } = this.state;
    Toast.loading("处理中...", 0);
    axios
      .post("/api/oa/sysUser/setUserPermissions", {
        id: this.props.match.params.id,
        permissionIds: checkPermissions,
      })
      .then(res => {
        Toast.hide();
        if (res && res.status == 1000 && res.data) {
          Toast.success(res.data);
          setTimeout(() => window.history.go(-1), 1500);
        } else {
          Toast.fail(res.data);
        }
      });
  }

  render() {
    const { permissionList, defaultPermissions, } = this.state;
    return (
      <div className="main-permission-list">
        <NavBar
          icon={<Icon type="left" />}
          onLeftClick={() => this.props.history.goBack()}
        >
          权限设置
        </NavBar>
        <div className="permission-list">
          <form>
            <List renderHeader={() => '权限列表'}>
              {
                permissionList.map((item, index) => (
                  <CheckboxItem key={item.id} checked={defaultPermissions.includes(item.id)} onChange={e => this.onChange(e, item)}>
                    {item.name}
                  </CheckboxItem>
                ))
              }
            </List>
            <WhiteSpace size="xl" />
            <Button type="primary" onClick={() => this.submit()}>
              确定
            </Button>
            <WhiteSpace size="xl" />
            <Button onClick={() => this.props.history.goBack()}>
              返回
            </Button>
          </form>
        </div>
      </div>
    )
  }
}

export default AdminPermission
