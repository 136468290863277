import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  Button,
  WhiteSpace,
  WingBlank,
  Toast,
  ActionSheet,
  Modal,
  Radio
} from "antd-mobile";
import "./adminDetail.scss";
import axios from "../../../service/axios";
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;
const userMsg =
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg")).user;
export class AdminDetails extends Component {
  state = {
    adminInfoDatas: {},
    adminUser: {},
    baseAvailInfo: {},
    loginAdminMsg: {},
    vipAvailInfo: {},
    waitRenewCount: 0,
    showVipInfo: false,
    modal1: false,
    userValidYears: 3
  };
  componentDidMount() {
    this.getAdminInfo();
    this.getLoginAdminInfo();
  }
  getAdminInfo() {
    Toast.loading("加载中...", 0);
    axios
      .get("/api/oa/sysUser/info", {
        params: {
          id: this.props.match.params.id
        }
      })
      .then(res => {
        Toast.hide();
        if (res && res.status == 1000 && res.data) {
          this.setState({
            adminInfoDatas: res.data,
            adminUser: res.data.user,
            baseAvailInfo: res.data.baseAvailInfo,
            vipAvailInfo: res.data.vipAvailInfo,
            waitRenewCount: res.data.waitRenewCount,
            userValidYears: res.data.userValidYears.years
          });
        }
      });
  }
  getLoginAdminInfo() {
    axios
      .get("/api/oa/sysUser/info", {
        params: {
          id: userMsg.id
        }
      })
      .then(res => {
        if (res && res.status == 1000) {
          const { data } = res
          this.setState({
            loginAdminMsg: data,
            showVipInfo: data.user.vipAvailAmount > 0 || data.vipAvailInfo.dispatchCount > 0 || data.vipAvailInfo.videoCount > 0
          });
        }
      });
  }
  deleteUser() {
    const BUTTONS = ["确定", "取消"];
    ActionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        message: "是否删除该管理员？",
        maskClosable: true
      },
      buttonIndex => {
        if (!buttonIndex) {
          Toast.loading("加载中", 0);
          axios
            .get("/api/oa/sysUser/delete", {
              params: {
                id: this.props.match.params.id
              }
            })
            .then(res => {
              if (res) {
                if (res.status == 1000) {
                  Toast.success(res.data, 1, () => {
                    this.props.history.goBack();
                  });
                } else {
                  Toast.fail(res.data, 1);
                }
              }
            });
        }
      }
    );
  }
  onChange(value) {
    console.log(value);
    this.setState({ userValidYears: value });
  }
  setUserValidYears() {
    Toast.loading("加载中...", 0);
    axios
      .get("/api/oa/sysUser/setUserValidYears", {
        params: {
          years: this.state.userValidYears,
          adminId: this.props.match.params.id
        }
      })
      .then(res => {
        Toast.hide();
        if (res) {
          if (res.status == 1000 && res.data) {
            Toast.success(res.data, 1, () => {
              this.props.history.goBack();
            });
          } else {
            Toast.fail(res.message, 1);
          }
        }
      })
      .catch(res => {
        Toast.hide();
        Toast.fail("操作失败");
      });
  }

  render() {
    const {
      adminInfoDatas,
      adminUser,
      baseAvailInfo,
      loginAdminMsg,
      vipAvailInfo,
      waitRenewCount,
      showVipInfo,
      modal1,
      userValidYears
    } = this.state;

    return (
      <div className="addAdmin">
        <div className="header-sticky header-navbar">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            {adminUser.loginName}详情
          </NavBar>
        </div>
        <div className="sub-container">
          <form>
            <List
              renderHeader={`当前登录管理员${loginAdminMsg.user &&
                loginAdminMsg.user.loginName}总额度`}
            >
              <Item
                extra={loginAdminMsg.user && loginAdminMsg.user.availability}
              >
                基础可用额度
              </Item>
              <Item
                extra={
                  loginAdminMsg.user &&
                  loginAdminMsg.baseAvailInfo.dispatchLeftNum
                }
              >
                调度可用额度
              </Item>
              <Item
                extra={
                  loginAdminMsg.user && loginAdminMsg.baseAvailInfo.videoLeftNum
                }
              >
                视频可用额度
              </Item>
            </List>
            <List renderHeader={() => "基本信息"}>
              <Item extra={`${adminUser.loginName}`}>登录账号 </Item>
              {/* <Item extra={`${user.uid}`}>账号ID </Item> */}
              {/* <Item extra={'111111'}>密码 </Item> */}
              <Item extra={adminInfoDatas.createBy}>上级账号 </Item>
              <Item extra={`${adminUser.totalAmount}`}>额度值 </Item>
              <Item extra={`${adminUser.totalAmount - adminUser.availability}`}>
                已使用
              </Item>
              <Item extra={`${adminUser.availability}`}>未使用 </Item>
              <Item extra={`${adminUser.remarks}`}>备注 </Item>
            </List>
            <List renderHeader={() => "续费信息"}>
              <Item extra={`${adminUser.baseCount}`}>基础续费 </Item>
              <Item extra={`${adminUser.renewalCount - adminUser.baseCount}`}>
                VIP续费
              </Item>
              <Item extra={`${adminUser.renewalCount}`}>续费总数 </Item>
              <Item extra={`${waitRenewCount}`}>待续费数 </Item>
            </List>
            <List renderHeader={() => "基础额度信息"}>
              <Item>
                基础额度(可用/已用/总数)
                <Brief>
                  {adminUser.availability}-
                  {adminUser.totalAmount - adminUser.availability}-
                  {adminUser.totalAmount}
                </Brief>
              </Item>
              <Item>
                基础调度(可用/已用/总数)
                <Brief>
                  {baseAvailInfo.dispatchLeftNum}-
                  {baseAvailInfo.dispatchCount - baseAvailInfo.dispatchLeftNum}-
                  {baseAvailInfo.dispatchCount}
                </Brief>
              </Item>
              <Item>
                基础视频(可用/已用/总数)
                <Brief>
                  {baseAvailInfo.videoLeftNum}-
                  {baseAvailInfo.videoCount - baseAvailInfo.videoLeftNum}-
                  {baseAvailInfo.videoCount}
                </Brief>
              </Item>
            </List>
            {
              showVipInfo ? (
                <List renderHeader={() => "VIP额度信息"}>
                  <Item>
                    VIP额度(可用/已用/总数)
                <Brief>
                      {adminUser.vipAvail}-
                  {adminUser.vipAvailAmount - adminUser.vipAvail}-
                  {adminUser.vipAvailAmount}
                    </Brief>
                  </Item>
                  <Item>
                    VIP调度(可用/已用/总数)
                <Brief>
                      {vipAvailInfo.dispatchLeftNum}-
                  {vipAvailInfo.dispatchCount - vipAvailInfo.dispatchLeftNum}-
                  {vipAvailInfo.dispatchCount}
                    </Brief>
                  </Item>
                  <Item>
                    VIP视频(可用/已用/总数)
                <Brief>
                      {vipAvailInfo.videoLeftNum}-
                  {baseAvailInfo.videoCount - vipAvailInfo.videoLeftNum}-
                  {vipAvailInfo.videoCount}
                    </Brief>
                  </Item>
                </List>
              ) : ''
            }
            <WhiteSpace size="lg" />
            <WingBlank size="lg">
              <Button
                type="ghost"
                onClick={() =>
                  this.props.history.push(`/adminnum/${adminUser.id}/0`)
                }
              >
                下发额度
              </Button>
              <WhiteSpace />
              <Button
                onClick={() =>
                  this.props.history.push(`/adminnum/${adminUser.id}/1`)
                }
              >
                回收额度
              </Button>
              <WhiteSpace />
              <Button
                type="primary"
                onClick={() =>
                  this.props.history.push(`/addadmin/${adminUser.id}/edit`)
                }
              >
                修改
              </Button>
              <WhiteSpace />
              {userMsg && userMsg.userType == 3 ? (
                <div>
                  <Button
                    type="primary"
                    onClick={() =>
                      this.props.history.push(
                        `/adminPermission/${adminUser.id}`,
                      )
                    }>
                    权限设置
                </Button>
                  <WhiteSpace />
                  <Button onClick={() => this.setState({ modal1: true })}
                  >年限设置
                </Button>
                  <WhiteSpace />
                  <Button
                    type="ghost"
                    onClick={() =>
                      this.props.history.push(`/adminCurrencyNum/${adminUser.id}/0`)
                    }
                  >
                    下发续费币
              </Button>
                  <WhiteSpace />
                  <Button
                    onClick={() =>
                      this.props.history.push(`/adminCurrencyNum/${adminUser.id}/1`)
                    }
                  >
                    回收续费币
              </Button>
                  <WhiteSpace />
                </div>
              ) : ("")}
              <Button type="warning" onClick={() => this.deleteUser()}>
                删除
              </Button>
            </WingBlank>
            <Modal
              visible={modal1}
              transparent
              maskClosable={false}
              onClose={() => this.setState({ modal1: false })}
              title="年限设置"
              footer={[
                { text: '确定', onPress: () => this.setUserValidYears() },
                { text: '取消', onPress: () => { console.log(`cancel`); this.setState({ modal1: false }); } }]}
            >
              <div style={{ height: 100, overflow: 'scroll' }}>
                <List>
                  <RadioItem key="1" checked={userValidYears == 1} onChange={() => this.onChange(1)}>
                    1年
              </RadioItem>
                  <RadioItem key="3" checked={userValidYears == 3} onChange={() => this.onChange(3)}>
                    3年
              </RadioItem>
                </List>
              </div>
            </Modal>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
          </form>
        </div>
      </div>
    );
  }
}

export default AdminDetails;
