// import React from 'react'
// import ReactDOM from 'react-dom'
// import App from './App'
// import * as serviceWorker from './serviceWorker'
import './index.css'
import './App.scss'
import './lib/rem'
import createHistory from 'history/createBrowserHistory'
import dva from 'dva'
// ReactDOM.render(<App />, document.getElementById('root'))
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()

const app = dva({
  history: createHistory()
})

app.model(require('./models/app').default)
app.router(require('./routes/router').default)
app.start('#root')
