import { routerRedux } from 'dva/router'

export default {
  namespace: 'userList',
  state: {
    name: 'userListsss'
  },
  subscriptions: {
    setup({ dispatch, history }) {
     
    }
  },
  effects: {
  
  },
  reducers: {}
}
