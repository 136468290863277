import React, { Component } from "react";
import { createForm } from "rc-form";
import axios from "axios";
import http from '../../../../utils/request'
import {
  NavBar,
  Icon,
  Button,
  Radio,
  Toast,
  WhiteSpace,
  Pagination,
  WingBlank,
  Accordion,
  InputItem,
  List,
} from "antd-mobile";

import "./uplist.scss";
const RadioItem = Radio.RadioItem;
const Item = List.Item;

export class Uplist extends Component {
  state = {
    pageTotalNum: 0,
    pageNo: 1,
    pageSize: 10,
    userDatas: [],
    selectItem: [],
    defaultUserId: "",
    selectUser: {},
    agentpwd: 132456,
    scanResult: '',
  };

  componentDidMount() {
    const imei = localStorage.getItem("scan:result:imei");
    const scanResult = imei ? JSON.parse(imei) : '';
    this.setState({
      scanResult: scanResult,
    });
    this.getUserList(
      sessionStorage.getItem("userListPage")
        ? sessionStorage.getItem("userListPage")
        : 1, 0
    );
  }

  getUserList(e, type) {
    console.log('cha',e)
    http
      .get("/api/user/ptt/unbound_imei/list", {
          pageNo: e,
          pageSize: 10,
      },'new')
      .then((res) => {
        if (res.status == 200) {
          const { resultList } = res.data
          const defaultUserId = resultList && resultList.length > 0 ? resultList[0].id : ""
          const selectUser = resultList && resultList.length > 0 ? resultList[0] : {}
          if (type == 1) {
            this.props.form.setFieldsValue({
              account: selectUser.account,
            });
          }
          this.setState({
            userDatas: resultList || [],
            pageTotalNum: Math.ceil(res.data.totalCount / 10),
            pageNo: res.data.pageNo,
            selectUser,
            defaultUserId,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onPageChange(e) {
    const { pageTotalNum } = this.state;
    e = e > pageTotalNum ? pageTotalNum : e;
    console.log(e);
    this.getUserList(e, 1);
    this.setState({
      selectItem: [],
    });
  }

  onSubmit = () => {
    const { selectUser, agentpwd, scanResult } = this.state
    this.props.form.validateFields({ force: true }, error => {
      console.log(error);
      const fildsValue = this.props.form.getFieldsValue();
      console.log('fildsValue ======== ', fildsValue)
      if (!error) {
        Toast.loading("加载中...", 5);
        http
          .post("/api/user/ptt/write_account", {
            ...fildsValue,
            uid: selectUser.uid,
            password: selectUser.password,
          },'new')
          .then((res) => {
            if (res.status == 200) {
              this.props.history.push("/menu");
              localStorage.removeItem("scan:result:imei");
              Toast.success(res.message, 1);
            } else {
              Toast.fail(res.message, 1);
            }
          })
          .catch((error) => {
            Toast.info("错误信息", 1);
          });
      }
    });
  };

  render() {
    const {
      getFieldProps,
      getFieldError,
    } = this.props.form;
    const {
      userDatas,
      pageTotalNum,
      pageNo,
      defaultUserId,
      selectUser,
      scanResult,
    } = this.state;

    const onChangeAction = (e, id, i) => {
      if (selectUser) {
        this.setState({
          selectUser: i,
        });
      } else {
        this.setState({
          selectUser: i
        })

      }
      this.setState({
        defaultUserId: id,
      });
      this.props.form.setFieldsValue({
        account: i.account,
      });
    };

    return (
      <div className="uhss-list">
        <div className="list-er">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            IMEI号绑定账号
          </NavBar>
        </div>

        {/* <div className="sub-list-lei"> */}
        <div className="sub-container">
          <form>
            <List renderFooter={() => getFieldError("imei")}>
              <InputItem
                {...getFieldProps("imei", {
                  rules: [
                    {
                      required: true,
                      message: 'IMEI号不能为空',
                    },
                  ],
                  initialValue: scanResult.imei,
                })}
                type="number"
                editable={false}
                value={scanResult.imei}
                disabled   
              >
                <span className="red-star">*</span>
                IMEI号：
              </InputItem>
            </List>
            <List renderFooter={() => getFieldError("account")}>
              <Accordion defaultActiveKey="0">
                <Accordion.Panel header={<span><span className="red-star">*</span>账号</span>}
                  {...getFieldProps("account", {
                    rules: [
                      {
                        required: true,
                        message: '账号不能为空',
                      },
                    ],
                    initialValue: selectUser.account,
                  })}>
                  <List>
                    {userDatas.map((i, index) => {
                      return (
                        <RadioItem
                          key={index}
                          checked={defaultUserId === i.id}
                          onChange={(e) => onChangeAction(e, i.id, i)}
                        >
                          {i.account}
                        </RadioItem>
                      );
                    })}
                  </List>
                  <WingBlank>
                    {pageTotalNum > 0 ? (
                      <Pagination
                        total={pageTotalNum}
                        current={pageNo}
                        locale={{
                          prevText: "上一页",
                          nextText: "下一页",
                        }}
                        onChange={(e) => this.onPageChange(e)}
                      />
                    ) : (
                        ""
                      )}
                  </WingBlank>
                </Accordion.Panel>
              </Accordion>
            </List>
            <List>
              <InputItem
                type="number"
                editable={false}
                value={selectUser.account}                
                disabled                                
              >
              <span className="red-star">*</span>
                选中账号：
              </InputItem>
            </List>
            <List>
              <InputItem
                type="number"
                editable={false}
                value={selectUser.name}
                disabled                   
              >
              <span className="red-star">*</span>
                选中名称：
              </InputItem>
            </List>
            <List renderFooter={() => getFieldError("agentpwd")}>
              <InputItem
                {...getFieldProps("agentpwd", {
                  rules: [
                    {
                      required: true,
                      message: '经销商密码不能为空',
                    },
                  ],
                  initialValue: 123456,
                })}
                type="type"
                labelNumber={6}
              >
                <span className="red-star">*</span>
                经销商密码：
              </InputItem>
            </List>
          </form>
          <WingBlank>
            <WhiteSpace />
            <Button type="primary" onClick={this.onSubmit}>
              提交
            </Button>
          </WingBlank>
        </div>
      </div>
    );
  }
}

export default createForm()(Uplist);
