//hasExpired 1是到期和快到期
import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  SearchBar,
  Radio,
  Button,
  WhiteSpace,
  Checkbox,
  Toast,
  Pagination,
  WingBlank,
  ActionSheet,
  Popover,
  Modal
} from "antd-mobile";
import "./userList.scss";
import axios from "../../../service/axios";
import Search from "../../../components/search/search";
import NoneDatas from "../../../components/noneDatas";
const more_icon = require("../../../assets/images/icon/more_icon.png");
const Item = List.Item;
const Brief = Item.Brief;
const CheckboxItem = Checkbox.CheckboxItem;
const RadioItem = Radio.RadioItem;
const alert = Modal.alert;
const selectedContainer = {
  position: "fixed",
  width: "100%",
  top: "45px",
  background: "#f5f5f5",
  height: "100%",
  zIndex: "11",
  transition: "left 0.4s"
};
export class UserList extends Component {
  state = {
    userDatas: [],
    pageTotalNum: 0,
    pageNo: 1,
    showCheck: false,
    selectItem: [],
    drawerLeft: document.body.clientWidth,
    drawerSearch: document.body.clientWidth,
    billList: [],
    selectBillId: null,
    searchKey: {},
    paramsSearchMy: "",
    visible: false,
  };
  componentDidMount() {
    this.getUserList(
      sessionStorage.getItem("userListPage")
        ? sessionStorage.getItem("userListPage")
        : 1
    );
    this.setState({
      paramsSearchMy: this.props.match.params.searchMy ? "/" + this.props.match.params.searchMy : "",
    });
  }
  getUserList(e) {
    Toast.loading("加载中...", 0);
    axios
      .get("/api/oa/pttUser/list", {
        params: {
          pageSize: 10,
          pageNo: e,
          searchMy: this.props.match.params.searchMy,
          ...this.state.searchKey
        }
      })
      .then(res => {
        Toast.hide();
        if (res && res.status == 1000) {
          this.setState({
            userDatas: res.data.list,
            pageTotalNum: Math.ceil(res.data.count / 10),
            pageNo: res.data.pageNo
          });
        } else {
          this.setState({
            userDatas: []
          });
          Toast.fail("获取列表失败，请稍后再试");
        }
      });
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  onPageChange(e) {
    console.log(e);
    this.getUserList(e);
    this.setState({
      selectItem: []
    });
    sessionStorage.setItem("userListPage", e);
  }
  onItemChange(e, i) {
    let arr = [...this.state.selectItem];
    if (e.target.checked) {
      arr.push(i);
      this.setState({
        selectItem: arr
      });
    } else {
      arr.splice(arr.map(item => item.uid).indexOf(i.uid), 1);
      this.setState({
        selectItem: arr
      });
    }
  }
  actionBtn(e) {
    const { selectItem } = this.state;
    console.log(selectItem);
    if (selectItem.length < 1) {
      Toast.fail("请选择要操作的用户！", 1);
      return;
    }
    let action;
    let message;
    if (e == 1) {
      if (this.state.selectItem.map(item => item.hasExpired).indexOf(1) >= 0) {
        Toast.info("不能删除过期用户", 1);
        return;
      }
    }
    switch (e) {
      case 1:
        action = "delete";
        message = "确认删除选中的用户？";
        break;
      case 2:
        action = "resume";
        message = "确认激活选中的用户？";
        break;
      case 3:
        action = "suspend";
        message = "确认暂停选中的用户？";
        break;
      case 4:
        action = "renew";
        message = "确认续费选中的用户？";
        break;
      default:
        break;
    }
    const BUTTONS = ["确定", "取消"];
    ActionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        cancelButtonIndex: 1,
        destructiveButtonIndex: 0,
        message: message,
        maskClosable: true
      },
      buttonIndex => {
        console.log(buttonIndex);
        if (!buttonIndex) {
          Toast.loading("加载中...", 0);
          axios
            .get("/api/oa/pttUser/" + action, {
              params: {
                ids: this.state.selectItem.map(item => item.id).join(",")
              }
            })
            .then(res => {
              Toast.hide();
              if (res) {
                if (res.status == 1000 && res.data) {
                  Toast.success("操作成功", 1);
                  setTimeout(() => {
                    this.getUserList(this.state.pageNo);
                    this.setState({
                      selectItem: []
                    });
                  }, 1000);
                } else {
                  Toast.fail(res.data, 1);
                }
              }
            })
            .catch(() => {
              Toast.fail("操作失败", 1);
            });
        } else {
        }
      }
    );
    return;
  }
  showRenewDrawer() {
    if (this.state.selectItem.length < 1) {
      Toast.fail("请选择要操作的用户！", 1);
      return;
    }
    //检测是不是都是需要续费的账单
    let hasExpired = this.state.selectItem.filter(item => item.hasExpired);
    console.log('hasExpired', hasExpired);
    if (hasExpired.length != this.state.selectItem.length) {
      Toast.fail("请选择(快)过期的账号进行续费", 1);
      return;
    }
    //检测是不是都是普通账号
    let baseUser = this.state.selectItem.filter(item => item.usrType != 6);
    console.log('baseUser', baseUser);
    if (baseUser.length != this.state.selectItem.length) {
      Toast.fail("优惠账号请使用续费币续费", 1);
      return;
    }
    this.getBillList();

    this.setState({
      drawerLeft: 0
    });
  }
  getBillList() {
    axios
      .get("/api/oa/pttBill/getBillsByPttUser", {
        params: {
          type: 1
        }
      })
      .then(res => {
        if (res && res.status == 1000 && res.data) {
          if (res.data.length < 1) {
            Toast.info("暂无有效续费账单，请先置换", 2);
            return;
          }
          this.setState({
            billList: res.data
          });
        }
      });
  }
  onChangeBill(e) {
    console.log(e);
    if (e.leftnum < this.state.selectItem.length) {
      Toast.info("当前账单可用额度不足，请选择其他账单", 1);
      return;
    }
    this.setState({
      selectBillId: e.id
    });
  }
  pttUserRenew() {
    if (this.state.selectItem.length < 1) {
      Toast.fail("请选择要操作的用户！", 1);
      return;
    }
    if (!this.state.selectBillId) {
      Toast.fail("请选择续费的账单", 1);
      return;
    }
    Toast.loading("加载中...", 0);
    axios
      .get("/api/oa/pttUser/renew", {
        params: {
          ids: this.state.selectItem.map(item => item.id).join(","),
          billid: this.state.selectBillId
        }
      })
      .then(res => {
        Toast.hide();
        if (res && res.status == 1000 && res.data) {

          Toast.success(res.data, 1);
          setTimeout(() => {
            this.getUserList(this.state.pageNo);
            this.setState({
              drawerLeft: document.body.clientWidth,
              selectItem: []
            });
          }, 1000);
        } else {
          Toast.fail(res.data, 1);
        }
      })
      .catch(() => {
        Toast.fail("操作失败");
      });
  }
  //搜索结果赋值给billList
  onSearchList = e => {
    console.log(e);
    this.setState({
      searchKey: {
        ...e
      },
      drawerSearch: document.body.clientWidth
    });
    setTimeout(() => {
      this.getUserList(1);
    }, 0);
  };

  handleVisibleChange = (visible) => {
    this.setState({
      visible,
    });
  };

  renewDiscountUser = () => {
    if (this.state.selectItem.length < 1) {
      Toast.fail("请选择要操作的用户！", 1);
      return;
    }
    //检测是不是都是优惠账号
    let discountUser = this.state.selectItem.filter(item => item.usrType == 6);
    console.log('discountUser', discountUser);
    if (discountUser.length != this.state.selectItem.length) {
      Toast.fail("普通账号请使用续费账单续费", 1);
      return;
    }
    console.log(this.state.selectItem);

    alert('优惠账号续费', `消耗${discountUser.length}个续费币续费，确定?`, [
      { text: '取消', onPress: () => console.log('cancel') },
      { text: '确定', onPress: () => this.actionRenewDiscountUser() },
    ])
  };

  actionRenewDiscountUser = () => {
    Toast.loading("加载中...", 0);
    axios
      .get("/api/oa/pttUser/renewDiscountPttUser", {
        params: {
          ids: this.state.selectItem.map(item => item.uid).join(","),
        }
      })
      .then(res => {
        Toast.hide();
        if (res) {
          if (res.status == 1000 && res.data) {
            Toast.success(res.data, 1, () => {
              this.props.history.goBack();
            });
          } else {
            Toast.fail(res.data, 1);
          }
        }
      })
      .catch(res => {
        Toast.hide();
        Toast.fail("操作失败");
      });
  };

  onSelect = (opt) => {
    console.log(opt.props.value);
    if (opt.props.value == 1) {
      this.handleVisibleChange(false);
      this.showRenewDrawer();
    }
    if (opt.props.value == 2) {
      this.handleVisibleChange(false);
      this.renewDiscountUser();
    }
  };

  render() {
    const {
      pageNo,
      pageTotalNum,
      showCheck,
      selectItem,
      userDatas,
      drawerLeft,
      billList,
      selectBillId,
      drawerSearch
    } = this.state;
    const userMsg =
      sessionStorage.getItem("loginMsg") &&
      JSON.parse(sessionStorage.getItem("loginMsg")).user;

    const UserActions = () => (
      <div>
        <div className="am-list-header">已选择需要续费的用户</div>
        <List style={{ height: "20%", overflowY: "scroll" }}>
          {selectItem.map((item, index) => (
            <Item key={index}>{item.account}</Item>
          ))}
        </List>
        <div className="am-list-header">选择续费的账单号</div>
        <List style={{ height: "40%", overflowY: "scroll" }}>
          {billList.map((item, index) => (
            <RadioItem
              key={index}
              checked={selectBillId === item.id}
              onChange={() => this.onChangeBill(item)}
            >
              <div style={{ fontSize: "12" }}>
                {item.id}
                <div className="item-brief">
                  {item.type == 0 ? "基础套餐" : ""}
                  {item.type == 1 ? "调度套餐" : ""}
                  {item.type == 2 ? "视频套餐" : ""}
                  [续费账单] 使用情况:
                  {item.leftnum}/{item.number}
                </div>
                <div className="item-brief">
                  有效期:
                  {item.endDate.split(" ")[0]}
                </div>
                {/* <List.Item.Brief>
                  {item.type == 0 ? "基础套餐" : ""}
                  {item.type == 1 ? "调度套餐" : ""}
                  {item.type == 2 ? "视频套餐" : ""}
                  [续费账单] 使用情况:
                  {item.leftnum}/{item.number}
                </List.Item.Brief> */}
                {/* <List.Item.Brief>
                  有效期:
                  {item.endDate}
                </List.Item.Brief> */}
              </div>
            </RadioItem>
          ))}
        </List>
        <WhiteSpace />
        <WingBlank>
          <Button type="primary" onClick={() => this.pttUserRenew()}>
            确定
          </Button>
          <WhiteSpace />
          <Button
            onClick={() =>
              this.setState({
                drawerLeft: document.body.clientWidth
              })
            }
          >
            返回
          </Button>
        </WingBlank>
      </div>
    );

    const ItemList = ({ item }) => (
      <div>
        {item.account} (
        {item.online == 1 ? (
          "离线"
        ) : (
            <span style={{ color: "#0078D7" }}>在线</span>
          )}
        )
        {item.hasExpired == 1 ? (
          <span style={{ color: "red", margin: "0 10px" }}>{new Date(item.validDate.replace(/-/g, "\/")) < new Date() ? "" : "即将"}过期账号</span>
        ) : (
            ""
          )}
        {item.hasExpired != 1 && item.usrType == 2 ? (
          <span style={{ color: "red", margin: "0 10px" }}>续费账号</span>
        ) : (
            ""
          )}
        {item.hasExpired != 1 && item.usrType == 3 ? (
          <span style={{ color: "red", margin: "0 10px" }}>VIP续费账号</span>
        ) : (
            ""
          )}
        <Brief>
          <span>
            ID:
            {item.uid}{" "}
          </span>
          &ensp;&ensp;
          <span>
            名字：
            {item.name}
          </span>{" "}
        </Brief>
        <Brief>
          <span>
            账号类型：
            {item.usrType == 6 ? <span style={{ color: "#f19736" }}>优惠</span> : '普通'}
          </span>
        </Brief>
        <Brief>
          <span>
            默认群组：
            {item.defaultGidName}{" "}
          </span>
          &ensp;&ensp;
          <span>
            创建者：
            {item.createBy.loginName}
          </span>{" "}
        </Brief>
        <Brief>
          <span>
            状态：
            {item.usrValid == 2 ? (
              <span style={{ color: "#00e000" }}>激活</span>
            ) : (
                <span style={{ color: "#c3c300" }}>暂停</span>
              )}
          </span>
          &ensp;&ensp;
          <span>
            有效期：
            {item.validDate.split(" ")[0]}{" "}
          </span>
        </Brief>
      </div>
    );

    return (
      <div className="user-list">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
            rightContent={[
              this.props.match.params.searchMy != 1 && userMsg && userMsg.userType >= 1 && userMsg.userType <= 3 ? (
                <Icon
                  type="search"
                  size="sm"
                  key="1"
                  onClick={() =>
                    this.setState({
                      drawerSearch: 0
                    })
                  }
                />
              ) : showCheck ? (
                <Icon
                  onClick={() => {
                    this.setState({
                      showCheck: false,
                      selectItem: []
                    });
                  }}
                  key="1"
                  type="check"
                />
              ) : (
                    // <div key="2">
                    //   <Icon
                    //     type="search"
                    //     size="sm"
                    //     key="1"
                    //     onClick={() =>
                    //       this.setState({
                    //         drawerSearch: 0,
                    //       })
                    //     }
                    //   />
                    //   &ensp; &ensp;
                    //   <img
                    //     key="2"
                    //     style={{ width: 22 }}
                    //     onClick={() => {
                    //       this.setState({
                    //         showCheck: true,
                    //         selectNum: 0,
                    //       });
                    //     }}
                    //     key="0"
                    //     src={more_icon}
                    //   />
                    // </div>

                    <div key='2'>
                      <span
                        key='1'
                        onClick={() => {
                          this.setState({ drawerSearch: 0 })
                        }}
                        style={{ fontSize: '12px' }}
                      >查找</span>&ensp; &ensp;
                      <span
                        key='2'
                        style={{ fontSize: '12px', width: 22 }}
                        onClick={() => {
                          this.setState({ showCheck: true, selectNum: 0 })
                        }}
                      >选项</span>
                    </div>
                  )
            ]}
          >
            {this.props.match.params.searchMy == 1 ? "我的用户" : "用户列表"}
          </NavBar>
        </div>
        <div className="sub-container">
          <WhiteSpace />
          {userDatas.map((item, index) => {
            return (
              <div key={index}>
                {showCheck ? (
                  <CheckboxItem
                    key={index}
                    checked={selectItem
                      .map(item => item.uid)
                      .includes(item.uid)}
                    onChange={e => this.onItemChange(e, item)}
                    style={
                      item.hasExpired == 1 ? { background: "#e6e6e6" } : {}
                    }
                  >
                    <ItemList item={item} />
                  </CheckboxItem>
                ) : (
                    <Item
                      arrow="horizontal"
                      multipleLine
                      onClick={() =>
                        this.props.history.push(`/adduser/${item.uid}/edit` + this.state.paramsSearchMy)
                      }
                      key={index}
                      style={
                        item.hasExpired == 1 ? { background: "#e6e6e6" } : {}
                      }
                    >
                      <ItemList item={item} />
                    </Item>
                  )}
              </div>
            );
          })}
          {userDatas.length == 0 ? <NoneDatas /> : ""}
          <WhiteSpace />
          <WingBlank>
            {pageTotalNum > 0 ? (
              <Pagination
                total={pageTotalNum}
                current={pageNo}
                locale={{
                  prevText: "上一页",
                  nextText: "下一页"
                }}
                onChange={e => this.onPageChange(e)}
              />
            ) : (
                ""
              )}
          </WingBlank>

          {showCheck ? (
            <div className="footer">
              <Button
                type="primary"
                inline
                size="small"
                disabled={userMsg && userMsg.userType == 4 && !userMsg.permissionIds.includes("202")}
                onClick={() => this.actionBtn(1)}
              >
                删除
              </Button>
              <Button
                type="primary"
                inline
                size="small"
                onClick={() => this.actionBtn(2)}
              >
                激活
              </Button>
              <Button
                type="primary"
                inline
                size="small"
                onClick={() => this.actionBtn(3)}
              >
                暂停
              </Button>
              <Button
                type="primary"
                inline
                size="small"
              // onClick={() => this.showRenewDrawer()}
              >
                <Popover mask
                  overlayClassName="fortest"
                  overlayStyle={{ color: 'currentColor' }}
                  visible={this.state.visible}
                  placement="topRight"
                  overlay={[
                    (<Popover.Item key="1" value="1" >普通账号续费</Popover.Item>),
                    (<Popover.Item key="2" value="2">优惠账号续费</Popover.Item>),
                  ]}
                  onVisibleChange={this.handleVisibleChange}
                  onSelect={this.onSelect}
                >
                  <div>
                    续费
                  </div>
                </Popover>
              </Button>
            </div>
          ) : (
              ""
            )}
          <div
            className="renew"
            style={{ ...selectedContainer, left: drawerLeft }}
          >
            <UserActions />
          </div>
          <div
            className="search"
            style={{ ...selectedContainer, left: drawerSearch }}
          >
            <Search
              closeSearch={() =>
                this.setState({
                  drawerSearch: document.body.clientWidth
                })
              }
              type="user"
              onSearchList={this.onSearchList}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default UserList;
