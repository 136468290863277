import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  Button,
  WhiteSpace,
  WingBlank,
  Picker,
  InputItem,
  Toast
} from "antd-mobile";
import axios from "../../../../service/axios";
import http from "../../../../utils/request";
import { createForm } from "rc-form";

const Item = List.Item;
const quotaTypeDatas = [
  {
    label: "基础额度",
    value: 0
  },
  {
    label: "VIP额度",
    value: 1
  },
  {
    label: "三年额度",
    value: 2
  },
  {
    label: "续费额度",
    value: 3
  }
];
const typeDatas = [
  {
    label: "基础套餐",
    value: 0
  },
  {
    label: "调度套餐",
    value: 1
  },
  {
    label: "视频套餐",
    value: 2
  }
];

const currencyType = [
  {
      label: "三年期虚拟币",
      value: 1,
  },
  {
    label: "一年期虚拟币",
    value: 11,
  },
  {
      label: "行业币",
      value: 3,
  },
  {
    label: "三年期续费币",
    value: 32,
  },
  {
    label: "一年期续费币",
    value: 12,
  },
  {
      label: "优惠续费币",
      value: 2,
  },
  {
    label: 'VIP续费币',
    value: 5
  },
];
const loginAdminMsg =
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg"));
export class newAdminNum extends Component {
  state = {
    adminUser: {},
    adminBaseAvailInfo: {},
    adminVipAvailInfo: {},
    adminThreeAvailInfo: {},
    adminRenewAvailInfo: {},
    type: 0,
    defaultCurrencyType: 1,
    showVipInfo: false,
    adminCurrencyAll: {
      currency1: {surplusCount:0,totalCount:0,usedCount:0},  // 三年 可用/总数/已用
      currency2: {surplusCount:0,totalCount:0,usedCount:0},  // 优惠 三年拆分  
      currency3: {surplusCount:0,totalCount:0,usedCount:0},  // 行业
      currency5: {surplusCount:0,totalCount:0,usedCount:0},  // vip
      currency11: {surplusCount:0,totalCount:0,usedCount:0}, // 一年
      currency12: {surplusCount:0,totalCount:0,usedCount:0}, // 一年续
      currency32: {surplusCount:0,totalCount:0,usedCount:0}, // 三年续
    },
    CurrencyInfo: {
      currencyInfo1: 0,  // 三年 可用/总数/已用
      currencyInfo2: 0,  // 优惠 三年拆分  
      currencyInfo3: 0,  // 行业
      currencyInfo5: 0,  // vip
      currencyInfo11:0, // 一年
      currencyInfo12:0, // 一年续
      currencyInfo32:0, // 三年续
    }
  };
  componentDidMount() {
    this.getAdminInfo();
    this.getLoginAdminInfo();
  }
  // 查询目标管理员的币信息
  getAdminInfo() {
    Toast.loading("加载中...", 0);
    http.get('/api/currency/countOfAdminAll',{
      id: this.props.match.params.id
    },'new').then(res => {
      if(res.status == 200){
        this.setState({
          adminCurrencyAll: res.data
        })
      }
    })
    // axios
    //   .get("/api/oa/sysUser/info", {
    //     params: {
    //       id: this.props.match.params.id
    //     }
    //   })
    //   .then(res => {
    //     Toast.hide();
    //     if (res && res.status == 1000 && res.data) {
    //       this.setState({
    //         adminInfoDatas: res.data,
    //         adminUser: res.data.user,
    //         adminBaseAvailInfo: res.data.baseAvailInfo,
    //         adminVipAvailInfo: res.data.vipAvailInfo,
    //         adminThreeAvailInfo: res.data.threeAvailInfo,
    //         adminRenewAvailInfo: res.data.renewAvailInfo
    //       });
    //     }
    //   });
  }

  // 查询当前管理员的币信息
  getLoginAdminInfo() {
    http
      .get("/api/currency/countOfAdminAll", {
        id: loginAdminMsg.id
      },'new')
      .then(res => {
        if (res && res.status == 200) {
          const {data} = res
          this.setState({
            CurrencyInfo:{
              currencyInfo1: data.currency1.surplusCount,  
              currencyInfo2: data.currency2.surplusCount,  
              currencyInfo3: data.currency3.surplusCount,  
              currencyInfo5: data.currency5.surplusCount,  
              currencyInfo11: data.currency11.surplusCount, 
              currencyInfo12: data.currency12.surplusCount, 
              currencyInfo32: data.currency32.surplusCount, 
            }
          });
        }
      });
  }
  // submitBtn() {
  //   this.props.form.validateFields({ force: true }, error => {
  //     console.log(this.props.form.getFieldsValue());
  //     const { type, quotaType, optNumber } = this.props.form.getFieldsValue();

  //     let params = {
  //       ...this.props.form.getFieldsValue(),
  //       id: this.props.match.params.id,
  //       quotaType: quotaType[0],
  //       // pttAvail: {
  //       //   type: type[0]
  //       // },
  //       isRecycle: this.props.match.params.type == 0 ? false : true,
  //       type: type[0]
  //     };
  //     console.log(params);
  //     if (!error) {
  //       Toast.loading("加载中...", 0);
  //       axios
  //         .get("/api/oa/sysUser/setAvailability", {
  //           params
  //         })
  //         .then(res => {
  //           Toast.hide();
  //           if (res) {
  //             if (res.status == 1000 && res.data) {
  //               Toast.success(res.data, 1, () => {
  //                 this.props.history.goBack();
  //               });
  //             } else {
  //               Toast.fail(res.message, 1);
  //             }
  //           }
  //         })
  //         .catch(res => {
  //           Toast.hide();
  //           Toast.fail("操作失败");
  //         });
  //     }
  //   });
  // }
  validateOptNumber = (rule, value, callback) => {
    let typeName = this.props.match.params.type == 0 ? "下发" : "回收";
    if (value) {
      if (value < 1) {
        callback(new Error("请填写大于0的数字"));
      } else {
        callback();
      }
    } else {
      callback(new Error(`请填写${typeName}的额度值`));
    }
  };
  onTypeChange = e => {
    this.setState({
      type: e[0]
    });
  };
  render() {
    const { getFieldProps, getFieldError } = this.props.form;
    const { match, app, admin, dispatch } = this.props;
    const { adminUser, adminBaseAvailInfo, adminVipAvailInfo, adminThreeAvailInfo, adminRenewAvailInfo, type, showVipInfo } = this.state;
    const { user, baseAvailInfo, vipAvailInfo, threeAvailInfo, renewAvailInfo } = loginAdminMsg
    const { currency1, currency2, currency3, currency5, currency11, currency12, currency32} = this.state.adminCurrencyAll
    const { currencyInfo1,currencyInfo2,currencyInfo3,currencyInfo5,currencyInfo11,currencyInfo12,currencyInfo32} = this.state.CurrencyInfo
    
    let typeName = this.props.match.params.type == 0 ? "下发" : "回收";
    const submitBtn = () => {
      this.props.form.validateFields({ force: true }, error => {
        if (!error) {
          let actionType = this.props.match.params.type == 0 ? "grant" : "retrieve";
          const { number } = this.props.form.getFieldsValue();
          const { type } = this.props.form.getFieldsValue();
          let payload = {
            count: number,
            targetId: this.props.match.params.id,
            type: type ? type[0] : 1,
          }
          http.get(`/api/currency/${actionType}`,payload,'new').then(res => {
            if(res && res.status == 200) {
              Toast.success('操作成功!')
              setTimeout(()=> window.history.go(-1), 1500)
            } else {
              Toast.fail(res.message)
            }
          })
        }
      })
    }
    return (
      <div>
        <div className="addAdmin">
          <div className="header-sticky header-navbar">
            <NavBar
              icon={<Icon type="left" />}
              onLeftClick={() => this.props.history.goBack()}
            >
              {typeName}币
            </NavBar>
          </div>
          <div className="sub-container">
            <List renderHeader={`当前登录管理员${loginAdminMsg.loginName}额度`}>

              <Item extra={currencyInfo1}> 可用三年虚拟币数 </Item>
              <Item extra={currencyInfo11}>可用一年虚拟币数</Item>
              <Item extra={currencyInfo32}>可用三年续费币数</Item>
              <Item extra={currencyInfo12}>可用一年续费币数</Item>
              {/* <Item extra={currencyInfo5}>VIP续费币数</Item> */}
              <Item extra={currencyInfo3}>可用行业币数</Item>
            </List>
            <List renderHeader={`管理员${match.params.name}可用额度`}>
              <Item extra={currency1.surplusCount}> 可用三年虚拟币数 </Item>
              <Item extra={currency11.surplusCount}>可用一年虚拟币数</Item>
              <Item extra={currency32.surplusCount}>可用三年续费币数</Item>
              <Item extra={currency12.surplusCount}>可用一年续费币数</Item>
              <Item extra={currency5.surplusCount}>VIP续费币数</Item>
              <Item extra={currency3.surplusCount}>可用行业币数</Item>
              {(loginAdminMsg && loginAdminMsg.userType == 3 && loginAdminMsg.preferentialMonths > 0) ? (
                <Item extra={currency2.surplusCount}>
                    可用优惠续费币数{" "}
                </Item>
              ) : ("")}
            </List>
            {/* {(loginAdminMsg && loginAdminMsg.userType == 3 && loginAdminMsg.preferentialMonths > 0) ? ( */}
            <List renderHeader={`币种类型`}>
              <Picker
                data={currencyType}
                cols={1}
                onChange={this.onChangeColor}
                {...getFieldProps("type", {
                    rules: [{ required: true, message: "请选择币种类型" }],
                    initialValue: [this.state.defaultCurrencyType],
                })}
              >
                <List.Item arrow="horizontal">币种类型 </List.Item>
              </Picker>
            </List>
            <List
                renderHeader={typeName + "数量"}
                renderFooter={() => getFieldError("number")}
            >
                <InputItem
                    {...getFieldProps("number", {
                        rules: [
                            { validator: this.validateOptNumber },
                        ],
                    })}
                    clear
                    type="number"
                    placeholder={`请填写${typeName}数量`}
                >
                    {typeName}数量
                </InputItem>
            </List>
            <WhiteSpace size="xl" />
            <WingBlank>
                <Button type="primary" onClick={() => submitBtn()}>
                    确定
                </Button>
                <WhiteSpace size="xl" />
                <Button onClick={() => this.props.history.goBack()}>
                    返回
                </Button>
            </WingBlank>
          </div>
        </div>
      </div>
    );
  }
}

export default createForm()(newAdminNum);
