import React, { Component } from 'react'
import { NavBar, Icon, List, Button } from 'antd-mobile'
const Item = List.Item
export class SelectList extends Component {
  test = i => {
    console.log(i)
  }
  render() {
    const data = []
    for (let i = 0; i < 100; i++) {
      data.push({
        value: i,
        label: 'zhaoyu' + i + '-' + i
      })
    }

    return (
      <div className="selectList">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            rightContent={[
              <Button key="0" inline size="small" type="primary">
                确定
              </Button>
            ]}
            onLeftClick={() => this.props.history.goBack()}
          >
            已选择1人
          </NavBar>
        </div>
        <div className="sub-container">
          <List renderHeader={() => '请选择群组成员'}>
            {data.map(i => (
              <Item
                key={i.value}
                extra={<Icon type="cross" onClick={() => this.test(i.value)} />}
              >
                {i.label}
              </Item>
            ))}
          </List>
        </div>
      </div>
    )
  }
}

export default SelectList
