import { routerRedux } from 'dva/router'
import api from '@/service'
import { Toast } from "antd-mobile"

const {
    currencyCountOfAdmin,
    funcGroupList,
    currencyRenewSurlpusCount,
    currencyTradeSurlpusCount,
} = api

export default {
  namespace: 'app',
  state: {
    name: 'app',
    selectGroups: [],
    funcGroupList: [],
    currencyTotalCount: 0,
    currencyUsedCount: 0,
    renewSurlpusCount: 0,
  },
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        console.log(location)
        // 先判断有无登录
        if(sessionStorage.getItem('loginMsg')){
          // 再判断请求的地址
          if(location.pathname == '/' || location.pathname == '/login'){
            dispatch({
              type: 'menu'
            })
          }
        } else {
          dispatch({
            type: 'login'
          })
        }
      })
    }
  },
  effects: {
    *menu({ payload }, { put }) {
      yield put(routerRedux.push('/menu'))
    },
    *login({ payload }, { put }) {
      yield put(routerRedux.push('/login'))
    },
    // 查询功能组
    *getFuncGroupList({ payload }, { call, put }) {
      let data = yield call(funcGroupList, payload);
      yield put({
        type: "updateState",
        payload: {
          funcGroupList: data.data,
        },
      });
    },
    //获取管理员虚拟币信息
    *getCurrencyCountOfAdmin({ payload }, { call, put }) {
      console.log('查询虚拟币',payload, currencyCountOfAdmin)
      let datas = yield call(currencyCountOfAdmin, payload);
      const { data } = datas
      if (datas.status == 200) {
        if (data) {
          let totalCount = 0;
          let usedCount = 0;
          if (data[payload[0]]) {
            totalCount = data[payload[0]].totalCount;
            usedCount = data[payload[0]].usedCount;
          }
          yield put({
            type: "updateState",
            payload: {
              currencyTotalCount: totalCount,
              currencyUsedCount: usedCount,
            },
          });
        }
      } else {
        Toast.fail(`${datas.message}`, 3);
      }
    },
    //获取管理员续费币信息
    *getCurrencyRenewSurlpusCount({ payload }, { call, put }) {
      let datas = yield call(currencyRenewSurlpusCount, payload);
      const { data } = datas
      if (datas.status == 200) {
        yield put({
            type: "updateState",
            payload: {
                renewSurlpusCount: data,
            },
        });
      }
    },
    //获取管理员行业币信息
    *getCurrencyTradeSurlpusCount({ payload }, { call, put }) {
      let datas = yield call(currencyTradeSurlpusCount, payload);
      if (datas.status == 200) {
        return datas.data;
      } else {
        Toast.fail(`${datas.message}`, 3);
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    //保存选择的群组
    selectGroupList(state, { selectGroups }) {
      console.log(selectGroups)
      return {
        ...state,
        selectGroups: selectGroups.selectGroups
      }
    },
    deleteSelectGroup(state, { gid }) {
      let groupArr = [...state.selectGroups]
      groupArr.splice(groupArr.map(item => item.gid).indexOf(gid), 1)
      console.log(groupArr)
      return {
        ...state,
        selectGroups: groupArr
      }
    },
    emptySelectGroup(state){
      return {
        ...state,
        selectGroups:[]
      }
    }
  }
}
