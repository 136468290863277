import React, { Component } from "react";
import {
  List,
  InputItem,
  Icon,
  NavBar,
  WhiteSpace,
  WingBlank,
  Button,
  Toast
} from "antd-mobile";
import "./common.scss";
import { createForm } from "rc-form";
import axios from "../../../service/axios";
export class RemoteWrite extends Component {
  state = {
    userInfo: ""
  };
  componentDidMount() {
    this.getUserinfo();
  }
  getUserinfo = () => {
    Toast.loading("加载数据中...", 0);
    axios
      .get("/api/oa/pttUser/info", {
        params: {
          uid: this.props.match.params.id
        }
      })
      .then(res => {
        console.log(res);
        Toast.hide();
        if (res && res.status == 1000 && res.data) {
          let data = {
            ...res.data,
            defaultGroup: {
              name: res.data.defaultGidName,
              gid: res.data.defaultGid
            }
          };
          this.setState({
            userInfo: data
          });
        }
      })
      .catch(() => {
        Toast.hide();
      });
  };

  remoteWrite = () => {
    this.props.form.validateFields({ force: true }, error => {
      console.log(this.props.form.getFieldsValue());
      if (!error) {
        const {
          agentpwd,
          imei,
          account,
          password
        } = this.props.form.getFieldsValue();
        Toast.loading("放号请求中，请稍后...", 0);
        axios
          .get("/api/oa/pttUser/remoteWrite", {
            params: {
              account: account,
              uid: this.props.match.params.id,
              password: password,
              imei: imei,
              agentpwd: agentpwd
            }
          })
          .then(res => {
            Toast.hide();
            console.log(res);
            if (res) {
              if (res.status == 1000 && res.data) {
                Toast.success(res.data, 1, () => {
                  this.props.history.goBack();
                });
              } else {
                Toast.fail(res.data, 1);
              }
            }
          })
          .catch(() => {
            Toast.hide();
            Toast.fail("放号失败");
          });
      }
    });
  };

  validateImei = (rule, value, callback) => {
    if (!value) {
      callback(new Error("请输入IMEI号"));
    } else {
      if (!/^[0-9A-Za-z]+$/.test(value)) {
        callback(new Error("IMEI号不能有特殊字符"));
      } else {
        if (value.length < 12) {
          callback(new Error("IMEI号为12-16位字母和数字的组合"));
        } else {
          callback();
        }
      }
    }
  };
  render() {
    const { getFieldProps, getFieldError } = this.props.form;
    const { name, account, imei, password } = this.state.userInfo;
    return (
      <div>
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            远程放号
          </NavBar>
        </div>
        <div className="sub-container">
          <List
            renderFooter={() =>
              getFieldError("account") ||
              getFieldError("name") ||
              getFieldError("imei") ||
              getFieldError("agentpwd")
            }
          >
            <InputItem
              {...getFieldProps("account", {
                rules: [{ required: true, message: "请输入账号" }, {}],
                initialValue: account
              })}
              clear
              placeholder="最大长度11，支持字母、数字"
              key="2"
              editable={false}
            >
              <i className="red-star">*</i>
              账号
            </InputItem>
            <InputItem
              {...getFieldProps("name", {
                initialValue: name
              })}
              clear
              key="3"
              editable={false}
            >
              名字
            </InputItem>
            <InputItem
              {...getFieldProps("password", {
                rules: [{ required: true, message: "请输入IMEI号" }, {}],
                initialValue: password
              })}
              clear
              key="6"
              type="password"
              editable={false}
            >
              <i className="red-star">*</i>
              用户密码
            </InputItem>
            <InputItem
              {...getFieldProps("imei", {
                rules: [
                  {
                    validator: this.validateImei
                  }
                ],
                initialValue: imei
              })}
              clear
              key="4"
              maxLength="16"
            >
              <i className="red-star">*</i>
              IMEI号
            </InputItem>
            <InputItem
              {...getFieldProps("agentpwd", {
                rules: [{ required: true, message: "请输入经销商密码" }, {}],
                initialValue: ""
              })}
              clear
              key="5"
              labelNumber="7"
              type="password"
            >
              <i className="red-star">*</i>
              经销商密码
            </InputItem>
          </List>
          <WhiteSpace />
          <WhiteSpace />
          <WingBlank>
            <Button type="primary" onClick={() => this.remoteWrite()}>
              远程放号
            </Button>
            <WhiteSpace size="xl" />
          </WingBlank>
        </div>
      </div>
    );
  }
}

export default createForm()(RemoteWrite);
