import React, { Component } from "react";
import {
  Result,
  Icon,
  Toast,
  Button,
  WhiteSpace
} from "antd-mobile";
import "./bingWeiXin.scss";
import { connect } from "dva";
import axios from "../../service/axios";

export class BingWeiXin extends Component {
  state = {
    title: "正在处理",
    status: 0,
  };
  componentDidMount() {
    const userMsg =
      sessionStorage.getItem("loginMsg") &&
      JSON.parse(sessionStorage.getItem("loginMsg")).user;
    const code = this.getUrlParam('code');
    this.bingWeiXin(code, userMsg.id);
  }

  getUrlParam(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  }

  getImg(status) {
    if (status == 200) {
      return <Icon type="check-circle" className="spe" style={{ fill: '#1F90E6' }} />;
    }
    if (status == 500) {
      return <Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />;
    }
    return <img src="https://gw.alipayobjects.com/zos/rmsportal/HWuSTipkjJRfTWekgTUG.svg" className="spe am-icon am-icon-md" alt="" />;
  }

  bingWeiXin(code, adminId) {
    console.log('code adminid', code, adminId)
    axios.get("/api/oa/sysUser/bindingWeiXin?code=" + code + "&adminId=" + adminId).then(res => {
      if (res) {
        if (res.status == 1000) {
          this.setState({
            title: "订阅成功",
            status: 200
          })
          Toast.success(res.data, 1);
        } else {
          this.setState({
            title: "订阅失败",
            status: 500
          })
          Toast.fail(res.data, 1);
        }
      }
    });
  }

  goBackMenu() {
    this.props.history.push("/menu");
  }

  render() {
    return (
      <div className="bingWeiXin">
        <div className="sub-title">订阅公众号消息</div>
        <Result
          img={this.getImg(this.state.status)}
          title={this.state.title}
        />
        <WhiteSpace />
        <Button onClick={() => this.goBackMenu()}>返回</Button>
      </div>
    );
  }
}

export default BingWeiXin
