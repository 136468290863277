import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  Drawer,
  InputItem,
  Picker,
  Button,
  WhiteSpace,
  WingBlank,
  Pagination,
  Toast
} from "antd-mobile";
import axios from "../../service/axios";
import { createForm } from "rc-form";
import NoneDatas from "../../components/noneDatas";
import { formatTimeStamp } from "../../utils/common";
import "./currencyLedger.scss";

const Item = List.Item;
const Brief = Item.Brief;

export class CurrencyLedger extends Component {
  state = {
    currencyLedgerList: [],
    pageNo: 1,
    pageTotalNum: 0,
  };
  componentDidMount() {
    this.getCurrencyLedgerList(1);
  }
  getCurrencyLedgerList(e) {
    Toast.loading("加载中...", 0);
    axios
      .get("/api/oa/sysUser/currencyLedgerList", {
        params: {
          pageNo: e,
          pageSize: 10
        }
      })
      .then(res => {
        Toast.hide();
        if (res && res.status == 1000 && res.data) {
          this.setState({
            currencyLedgerList: res.data.list,
            pageNo: res.data.pageNo,
            pageTotalNum: Math.ceil(res.data.count / 10)
          });
        }
      })
      .catch(() => {
        Toast.hide();
      });
  }
  onPageChange(e) {
    console.log(e);
    this.getCurrencyLedgerList(e);
  }
  render() {
    const { getFieldProps, getFieldError } = this.props.form;
    const { currencyLedgerList, pageTotalNum, pageNo } = this.state;
    return (
      <div className="currencyLedgerlist">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
            rightContent={[]}
          >
            账单列表
          </NavBar>
        </div>

        <div className="sub-container">
          <List className="my-list">
            {currencyLedgerList.map((i, index) => (
              <Item
                key={index}
                arrow="horizontal"
                multipleLine
              >
                数量： {i.income}
                <Brief>
                  备注：
                  {i.remark}
                </Brief>
                <Brief>
                  创建时间：
                  {formatTimeStamp(i.ctime)}
                </Brief>
              </Item>
            ))}
          </List>
          {currencyLedgerList.length == 0 ? <NoneDatas /> : ""}
          <WhiteSpace size="xl" />
          <WingBlank>
            {pageTotalNum > 0 ? (
              <Pagination
                total={pageTotalNum}
                current={pageNo}
                locale={{
                  prevText: "上一页",
                  nextText: "下一页"
                }}
                onChange={e => this.onPageChange(e)}
              />
            ) : (
                ""
              )}
          </WingBlank>
        </div>
      </div>
    );
  }
}

export default createForm()(CurrencyLedger);
