import React, { Component } from 'react'
import {
  NavBar,
  Icon,
  List,
  Accordion,
  Checkbox,
  InputItem,
  Button,
  Toast,
  Badge,
  WhiteSpace,
  WingBlank,
  ActionSheet,
  Switch
} from "antd-mobile";
import { createForm } from "rc-form";
import { connect } from "dva";
import axios from "@/service/axios";
import http from "@/utils/request"

const CheckboxItem = Checkbox.CheckboxItem;
const loginAdminMsg =
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg"));


// @connect(({ user, app }) => ({ user, app }))
export class newUserFuncRenew extends Component {
  state = {
    funcGidArr: [],
    checkedFuncGidArr: [],
    totalPrice: "0",
    preValidateMonth: -1,
    type: this.props.match.params.type,
    validFlag: false,
  };

  async componentDidMount () {
    console.log('续费:',this.props)
    await this.props.getFuncList();
    this.getPreValidateMonth();
    console.log('???',loginAdminMsg)
    await this.props.getAdminCurrencyCount()
    await this.props.getAdminRenewSurlpusCount()
    // this.props.dispatch({
    //   type: "app/getCurrencyCountOfAdmin",
    //   payload: Array.of(loginAdminMsg.id),
    // });
    // this.props.dispatch({
    //   type: "app/getCurrencyRenewSurlpusCount",
    //   payload: { adminId: loginAdminMsg.id },
    // });

  }

  //获取上级管理员的时间
  getPreValidateMonth() {
    return new Promise((resolve, reject) => {
      http.get("/api/user/admin/preferentialMonths/get",{},'new').then(res => {
        if (res.status == 200 && res.data) {
          if (res.data >= 0) {
            this.setState({
              preValidateMonth: res.data,
            });
          }
        }
      });
    });
  }

  validateMonths = (rule, value, callback) => {
    if (value) {
      callback();
    } else {
      callback(new Error("请输入购买月数"));
    }
  };
  validateFgidList = (rule, value, callback) => {
    console.log(value)
    if (value) {
      if (value.length < 1) {
        callback(new Error("请选择功能组"));
      } else {
        callback();
      }
    } else {
      callback(new Error("请选择功能组"));
    }
  };
  render () {
    const { totalPrice, preValidateMonth, type, validFlag } = this.state;
    const { dispatch, form, app, funcGroupList } = this.props;
    const { currencyTotalCount, currencyUsedCount, renewSurlpusCount } = app;
    const { validateFields, getFieldProps, getFieldsValue, getFieldError, setFieldsValue } = form;

    const submit = () => {
      validateFields({ force: true }, (error, value) => {
        console.log(value);
        if (!error) {
          const BUTTONS = ["确定", "取消"];
          ActionSheet.showActionSheetWithOptions(
            {
              options: BUTTONS,
              message: "是否对选中的用户进行续费？",
              maskClosable: true,
              "data-seed": "logId",
            },
            async buttonIndex => {
              if (!buttonIndex) {
                const { months } = value;
                let m = months
                if (type == 0 && this.props.match.params.userType == 6) {
                  m = 36;
                }
                dispatch({
                  // type: type == 0 ? 'user/renewByCurrency' : 'user/renewByRenewCurrency',
                  type: 'user/renewByCurrency',
                  payload: {
                    targetUid: this.props.match.params.uid,
                    ...value,
                    months: m,
                    validFlag: validFlag,
                  },
                }).then(res => {
                  console.log(res)
                  if (res.status == 200) {
                    Toast.success(res.message);
                    setTimeout(() => window.history.go(-1), 1500);
                  } else {
                    Toast.fail(res.message);
                  }
                });
              }
            },
          );
        }
      });
    };

    const onChange = (e, id, item) => {
      console.log(e.target.checked, id);
      let arr = this.state.funcGidArr;
      if (e.target.checked) {
        arr.push(item);
      } else {
        arr.splice(arr.map(item => item.id).indexOf(id), 1);
      }

      console.log(arr);
      this.setState({
        checkedFuncGidArr: arr,
      }, () => {
        // this.setState执行完的回调
        sumTotalPrice();
      })
      setFieldsValue({
        fgids: arr.map(item => item.id),
      });
    };
    const sumTotalPrice = () => {
      let months = form.getFieldValue('months');
      months = months ? months : 0;

      if (preValidateMonth > 0) {
        months = 36 / preValidateMonth;
      }

      let sumTotalPrice = 0;
      this.state.checkedFuncGidArr.filter(item => {
        sumTotalPrice += item.pttAmount;
      });
      sumTotalPrice *= months
      this.setState({
        totalPrice: sumTotalPrice.toString(),
      })
    };
    const handleRenewMode = () => {
      this.setState({
        validFlag: !validFlag
      })
    }
    return (
      <div className="userFuncRenew">
        <NavBar
          icon={<Icon type="left" />}
          onLeftClick={() => this.props.history.goBack()}
        >
          续费
        </NavBar>
        <form>
          <div>
            <List
              renderFooter={() =>
                getFieldError("fgids") ||
                getFieldError("months")
              }
            >
              {/* <List.Item>
                {type == 0 ? (<span>
                  可用虚拟币：{currencyTotalCount - currencyUsedCount}
                </span>) : (<span>
                  可用续费币：{renewSurlpusCount}
                </span>)}
              </List.Item> */}
              <Accordion defaultActiveKey="0">
                <Accordion.Panel
                  {...getFieldProps("fgids", {
                    rules: [
                      {
                        validator: this
                          .validateFgidList,
                      },
                    ],
                  })}
                  header={
                    <div>
                      <span className="red-star">*</span>
                      功能组：
                    </div>
                  }
                >
                  <List className="my-list">
                    {funcGroupList.map(item => (
                      <CheckboxItem
                        key={item.id}
                        onChange={e =>
                          onChange(
                            e,
                            item.id,
                            item,
                          )
                        }
                      >
                        {item.name}
                      </CheckboxItem>
                    ))}
                  </List>
                </Accordion.Panel>
              </Accordion>
              <List.Item
                extra={<Switch
                  checked={this.state.validFlag}
                  onChange={() => {handleRenewMode()}}
                />}
              >
                从当前时间续费
              </List.Item>
              <InputItem
                {...getFieldProps("months", {
                  rules: [
                    {
                      validator: this
                        .validateMonths,
                    },
                  ],
                  initialValue:
                    preValidateMonth > 0
                      ? (this.props.match.params.userType == 6 ? 12 : preValidateMonth)
                      : 12,
                })}
                onBlur={sumTotalPrice}
                disabled={preValidateMonth > 0}
                type="number"
                maxLength="5"
                clear
              >
                <i className="red-star">*</i>
                购买月数：
              </InputItem>
              <List.Item>
                {type == 0 ? '虚拟' : '续费'}币总数：
                <Badge text={totalPrice}
                  style={{ padding: '0 3px', backgroundColor: '#fff', color: '#108ee9', borderRadius: 2, border: '1px solid #108ee9', }} />
              </List.Item>
            </List>
            <WhiteSpace size="lg" />
            <WingBlank>
              <Button onClick={submit} type="primary">
                确定
              </Button>
              <WhiteSpace size="lg" />
              <Button onClick={() => this.props.history.goBack()}>
                返回
              </Button>
            </WingBlank>
          </div>
        </form>
      </div>
    )
  }
}

const newUserFuncRenewForm = createForm()(newUserFuncRenew);
const mapStateToProps = state => {
  return {
    funcGroupList: state.app.funcGroupList,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getFuncList: () => {
      dispatch({
        type: "app/getFuncGroupList",
        payload: {},
      });
    },
    getAdminCurrencyCount: () => {
      dispatch({
        type: 'app/getCurrencyCountOfAdmin',
        payload: Array.of(loginAdminMsg.id)
      })
    },
    getAdminRenewSurlpusCount: () => {
      dispatch({
        type: 'app/getCurrencyRenewSurlpusCount',
        payload: { adminId: loginAdminMsg.id }
      })
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(newUserFuncRenewForm);
