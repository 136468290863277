export default {

  //获取用户的群组
  userPttGroups: "POST /api/user/ptt/groups",
  //查询功能组
  funcGroupList: "GET /api/func/group/list",
  //管理员虚拟币信息
  currencyCountOfAdmin: "POST /api/currency/countOfAdmin",
  //用户可用续费币数
  currencyRenewSurlpusCount: "/api/currency/renewSurlpusCount",
  //用户可用行业币数
  currencyTradeSurlpusCount: "/api/currency/tradeSurlpusCount",
  //用户虚拟币续费
  apiUserRenewByCurrency: "POST /api/user/ptt/renewByCurrency",
  //用户续费币续费
  apiUserRenewByRenewCurrency: "POST /api/user/ptt/renewByRenewCurrency",

}
