import React, { Component } from "react";
import {
  NavBar,
  Icon,
  List,
  Button,
  WhiteSpace,
  WingBlank,
  InputItem,
  Toast
} from "antd-mobile";
import axios from "../../../service/axios";
import { createForm } from "rc-form";

const Item = List.Item;
export class AdminCurrencyNum extends Component {
  state = {
    adminUser: {},
    type: 0,
    surplusCurrencyCount: 0
  };
  componentDidMount() {
    this.getAdminInfo();
    this.getSurplusCurrencyCount();
  }
  getAdminInfo() {
    Toast.loading("加载中...", 0);
    axios
      .get("/api/oa/sysUser/info", {
        params: {
          id: this.props.match.params.id
        }
      })
      .then(res => {
        Toast.hide();
        if (res && res.status == 1000 && res.data) {
          this.setState({
            adminUser: res.data.user
          });
        }
      });
  }
  getSurplusCurrencyCount() {
    axios
      .get("/api/oa/sysUser/surplusCurrencyCount", {
        params: {
          aid: this.props.match.params.id
        }
      })
      .then(res => {
        if (res && res.status == 1000 && res.data) {
          this.setState({
            surplusCurrencyCount: res.data
          });
        }
      });
  }

  submitBtn() {
    this.props.form.validateFields({ force: true }, error => {
      console.log(this.props.form.getFieldsValue());
      const { optNumber } = this.props.form.getFieldsValue();

      let params = {
        aid: this.props.match.params.id,
        income: optNumber,
        isRecycle: this.props.match.params.type == 0 ? false : true
      };
      console.log(params);
      if (!error) {
        Toast.loading("加载中...", 0);
        axios
          .post("/api/oa/sysUser/setCurrency", {
            ...params
          })
          .then(res => {
            Toast.hide();
            if (res) {
              if (res.status == 1000 && res.data) {
                Toast.success(res.data, 1, () => {
                  this.props.history.goBack();
                });
              } else {
                Toast.fail(res.data, 1);
              }
            }
          })
          .catch(res => {
            Toast.hide();
            Toast.fail("操作失败");
          });
      }
    });
  }
  validateOptNumber = (rule, value, callback) => {
    let typeName = this.props.match.params.type == 0 ? "下发" : "回收";
    if (value) {
      if (value < 1) {
        callback(new Error("请填写大于0的数字"));
      } else {
        callback();
      }
    } else {
      callback(new Error(`请填写${typeName}的值`));
    }
  };
  render() {
    const { getFieldProps, getFieldError } = this.props.form;
    const { adminUser, surplusCurrencyCount } = this.state;
    let typeName = this.props.match.params.type == 0 ? "下发" : "回收";
    return (
      <div>
        <div className="addAdmin">
          <div className="header-sticky header-navbar">
            <NavBar
              icon={<Icon type="left" />}
              onLeftClick={() => this.props.history.goBack()}
            >
              {typeName}续费币
            </NavBar>
          </div>
          <div className="sub-container">
            <List renderHeader={`管理员${adminUser.loginName}`}>
              <Item extra={surplusCurrencyCount}>
                续费币可用
              </Item>
            </List>
            <List
              renderHeader={typeName + "续费币"}
              renderFooter={() => getFieldError("optNumber")}
            >
              <Item extra={adminUser.loginName}>管理员 </Item>
              <InputItem
                {...getFieldProps("optNumber", {
                  rules: [{ validator: this.validateOptNumber }]
                })}
                clear
                type="number"
                placeholder={`请填写${typeName}的值`}
              >
                {typeName}续费币
              </InputItem>
            </List>
            <WhiteSpace size="xl" />
            <WingBlank>
              <Button type="primary" onClick={() => this.submitBtn()}>
                确定
              </Button>
              <WhiteSpace size="xl" />
              <Button onClick={() => this.props.history.goBack()}>返回</Button>
            </WingBlank>
          </div>
        </div>
      </div>
    );
  }
}

export default createForm()(AdminCurrencyNum);
