import React, { Component } from 'react'
import {
  NavBar,
  Icon,
  List,
  Button,
  Checkbox,
  WhiteSpace,
  WingBlank
} from 'antd-mobile'
const Item = List.Item
export class GroupDetails extends Component {
  render() {
    return (
      <div className="groupDetails">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            群组详情
          </NavBar>
        </div>
        <div className="sub-container">
          <List renderHeader="测试组1">
            <Item extra={'62346556'}>群组ID</Item>
            <Item extra={'测试组1'}>群组名字</Item>
            <Item arrow="horizontal">群组成员</Item>
          </List>
          <List renderHeader="备注">
            <Item wrap>afsad阿斯顿发生士大夫撒旦发射点afsad阿斯顿发生士大夫撒旦发射点afsad阿斯顿发生士大夫撒旦发射点afsad阿斯顿发生士大夫撒旦发射点</Item>
          </List>
          <WhiteSpace size="xl" />
          <WingBlank>
            <Button type="primary">成员优先级</Button>
          </WingBlank>
          <WhiteSpace size="xl" />
          <WingBlank>
            <Button type="warning">删除</Button>
          </WingBlank>
          <WhiteSpace size="xl" />
          <WingBlank>
            <Button>返回</Button>
          </WingBlank>
        </div>
      </div>
    )
  }
}

export default GroupDetails
