import React, { Component } from "react";
import {
  Grid,
  NavBar,
  WingBlank,
  WhiteSpace,
  Button,
  ActionSheet,
  Toast,
  NoticeBar,
  Card,
  Icon,
  Modal,
  Flex
} from "antd-mobile";
import { connect } from "dva";
import axios from "../../service/axios";
import http from '../../utils/request'
import "./menu.scss";
import menu_user_list from "../../assets/images/icon/menu_user_list.png";
import menu_add_user from "../../assets/images/icon/menu_user_add.png";
import menu_user_add from "../../assets/images/icon/menu_user_add.png";
import menu_saoyisao from '../../assets/images/icon/saoyisao.png'
import menu_group_list from "../../assets/images/icon/menu_group_list.png";
import menu_group_add from "../../assets/images/icon/menu_group_add.png";
import menu_group_combination from "../../assets/images/icon/menu_group_combination.png";
import menu_bill_record from "../../assets/images/icon/menu_bill_record.png";
import menu_bill_message from "../../assets/images/icon/menu_bill_message.png";
import menu_bill_exchange from "../../assets/images/icon/menu_bill_exchange.png";
import admin_list from "../../assets/images/icon/admin_list.png";
import admin_add from "../../assets/images/icon/admin_add.png";
import menu_sim_info from "../../assets/images/icon/menu_sim_info.png";
import site_icon from "../../assets/images/icon/site_icon.png";
const alert = Modal.alert;
const adminMsg = sessionStorage.getItem('adminType')
let userMsg = {}
{adminMsg == 0 ? 
  userMsg = sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg")).user
  :
  userMsg = sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg"))
}
const userData = [
  // {
  //   icon: menu_user_list,
  //   text: `我的用户`,
  //   type: "1-3"
  // },
  {
    icon: menu_user_list,
    text: `用户列表`,
    type: "1-1"
  },
  {
    icon: menu_add_user,
    text: `用户添加`,
    type: "1-2"
  },
  {
    icon: menu_bill_record,
    text: `ICCID放号`,
    type: "1-5"
  },
  {
    icon: site_icon,
    text: `定位`,
    type: "1-6"
  }
];
const NewUserData = [
  {
    icon: menu_user_list,
    text: `用户列表`,
    type: "1-1",
  },
  {
    icon: menu_add_user,
    text: `用户添加`,
    type: "1-2",
  },
  {
    icon: menu_saoyisao,
    text: `IMEI扫码`,
    type: '1-3'
  },
  {
    icon: menu_bill_record,
    text: `ICCID放号`,
    type: "1-5",
  },
  {
    icon: site_icon,
    text: `定位`,
    type: "1-6"
  }
]
const groupData = [
  // {
  //   icon: menu_group_list,
  //   text: `我的群组`,
  //   type: "2-4"
  // },
  {
    icon: menu_group_list,
    text: `群组列表`,
    type: "2-1"
  },
  {
    icon: menu_group_add,
    text: `群组添加`,
    type: "2-2"
  },
  {
    icon: menu_group_combination,
    text: `嵌套群组`,
    type: "2-3"
  }
];
const NewGroupData = [
  {
    icon: menu_group_list,
    text: `群组列表`,
    type: "2-1"
  },
  {
    icon: menu_group_add,
    text: `群组添加`,
    type: "2-2"
  },
  {
    icon: menu_group_combination,
    text: `嵌套群组`,
    type: "2-3"
  }
];

const billData = [
  // {
  //   icon: menu_bill_record,
  //   text: `我的账单`,
  //   type: "3-4"
  // },
  {
    icon: menu_bill_record,
    text: `账单列表`,
    type: "3-1"
  },
  {
    icon: menu_bill_message,
    text: `额度信息`,
    type: "3-2"
  },
  {
    icon: menu_bill_exchange,
    text: `账单置换`,
    type: "3-3"
  }
];

const adminData = [
  {
    icon: admin_list,
    text: `管理员列表`,
    type: "4-1"
  },
  {
    icon: admin_add,
    text: `添加管理员`,
    type: "4-2"
  }
];

const simData = [
  {
    icon: menu_sim_info,
    text: `卡号信息查询`,
    type: "5-1"
  }
];
const cardPoolData = [
  {
    icon: menu_sim_info,
    text: `卡号信息查询`,
    type: "5-1"
  },
  {
    icon: menu_sim_info,
    text: `卡池信息查询`,
    type: "6-1"
  }
];

if (!(userMsg && (userMsg.userType < 1 || userMsg.userType > 3))) {
  billData.pop();
  userData.pop();
  userData.pop();
  groupData.pop();
  groupData.pop();
}

// if (!(userMsg && (userMsg.userType != 1))) {
//   billData.pop();
//   userData.pop();
//   billData.shift();
//   userData.shift();
// }
// if (userMsg && (userMsg.userType == 4)) {
//   billData.shift();
//   userData.shift();
//   groupData.shift();
// }
// if (userMsg && (userMsg.userType == 3 || userMsg.userType == 2)) {
//   groupData.splice(1, 1);
//   billData.splice(1, 1);
// }
export class Menu extends Component {
  state = {
    notice: "",
    weiXinUser: {},
    adminCurrencyAll: {
      currency1: {surplusCount:0,totalCount:0,usedCount:0},  // 三年 可用/总数/已用
      currency2: {surplusCount:0,totalCount:0,usedCount:0},  // 优惠 三年拆分  
      currency3: {surplusCount:0,totalCount:0,usedCount:0},  // 行业
      currency5: {surplusCount:0,totalCount:0,usedCount:0},  // vip
      currency11: {surplusCount:0,totalCount:0,usedCount:0}, // 一年
      currency12: {surplusCount:0,totalCount:0,usedCount:0}, // 一年续
      currency32: {surplusCount:0,totalCount:0,usedCount:0}, // 三年续
    }
  };
  componentDidMount() {
    const adminType = sessionStorage.getItem('adminType')
    this.props.deleteSelectUserList();
    this.props.emptySelectGroup();
    if(adminType == 0) {
      this.getNotice();
      this.getBindingWeiXinUser();
    } else {
      this.getAdminCurrency()
    }
    sessionStorage.removeItem("userListPage");  
  }
  getNotice() {
    axios.get("/api/oa/sysUser/notifications").then(res => {
      if (res && res.status == 1000 && res.data) {
        this.setState({
          notice: res.data
        });
      }
    });
  }

  // 获取币数据
  getAdminCurrency() {
    http.get('/api/currency/countOfAdminAll',{
      id: userMsg.id
    },'new').then(res => {
      console.log('res~',res)
      if(res.status == 200){
        this.setState({
          adminCurrencyAll: res.data
        })
      }
    })
  }
  
  handleClick(e) {
    console.log(e, this.props);
    let url = "";
    switch (e.type) {
      case "1-1":
        url = "/userlist";
        break;
      case "1-2":
        url = "/adduser";
        break;
      case "1-3":
        url = "/myuserlist/1";
        break;
      case "1-5":
          url = "/bindingIccid";
          break;
      case "1-4":
        url = "/currencyledgerlist";
        break;
      case "1-6":
        url = "/locationMsg";
        break;
      case "1-10":
        return;
      case "2-1":
        url = "/grouplist";
        break;
      case "2-2":
        url = "/addgroup";
        break;
      case "2-3":
        url = "/groupcombination";
        break;
      case "2-4":
        url = "/mygrouplist/1";
        break;
      case "3-1":
        url = "/billlist";
        break;
      case "3-2":
        url = "/billmessage";
        break;
      case "3-3":
        url = "/billexchange";
        break;
      case "3-4":
        url = "/mybilllist/1";
        break;
      case "4-1":
        url = "/adminlist";
        break;
      case "4-2":
        url = "/addadmin";
        break;
      case "5-1":
        url = "/simInfo";
        break;
      case "6-1":
        url = "/simFlowPool";
        break;
      default:
        break;
    }
    this.props.history.push(url);
  }
  handleNewClick(e) {
    let url = "";
    switch (e.type) {
      case "1-1":
        url = "/newUserList";
        break;
      case "1-2":
        url = "/newAddUser";
        break;
      case "1-3":
        url = "/clockinsdk";
        break;
      case "1-5":
          url = "/newBindingIccid";
          break;
      case "1-4":
        url = "/currencyledgerlist";
        break;
      case "1-6":
        url = "/newLocationMsg";
        break;
      case "1-10":
        return;
      case "2-1":
        url = "/newGrouplist";
        break;
      case "2-2":
        url = "/newAddgroup";
        break;
      case "2-3":
        url = "/newGroupcombination";
        break;
      case "2-4":
        url = "/mygrouplist/1";
        break;
      case "3-1":
        url = "/billlist";
        break;
      case "3-2":
        url = "/billmessage";
        break;
      case "3-3":
        url = "/billexchange";
        break;
      case "3-4":
        url = "/mybilllist/1";
        break;
      case "4-1":
        url = "/newAdminList";
        break;
      case "4-2":
        url = "/newAddadmin";
        break;
      case "5-1":
        url = "/simInfo";
        break;
      case "6-1":
        url = "/simFlowPool";
        break;
      default:
        break;
    }
    this.props.history.push({
      pathname: url
    })
  }
  // 注销退出
  loginOut = () => {
    const adminMsg = sessionStorage.getItem('adminType')
    const BUTTONS = ["确定", "取消"];
    ActionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        cancelButtonIndex: 1,
        destructiveButtonIndex: 0,
        message: "是否注销登录？",
        maskClosable: true
      },
      buttonIndex => {
        if (buttonIndex == 0) {
          Toast.loading("退出登录中...", 1);
          setTimeout(() => {
            if (adminMsg == 0) {
              axios
              .get('/oa/loginOut', {
              })
              .then(res => {
                console.log(res)
                if (res) {
                  if (res.data && res.status == 1000) {
                    sessionStorage.removeItem("loginMsg");
                    sessionStorage.removeItem("adminType");
                    Toast.success("注销成功", 1, () => {
                      window.location.reload();
                    });
                  } else {
                    Toast.info(res.message, 2)
                  }
                }
              })
              .catch(res => {
                Toast.fail("注销失败", 1);
              });
            } else {
              http.get('/auth/logout', {},'new').then(res => {
                if(res){
                  if(res.data && res.status == 200) {
                    sessionStorage.removeItem("loginMsg");
                    sessionStorage.removeItem("adminType");
                    Toast.success("注销成功", 1, () => {
                      window.location.reload();
                    });
                  } else {
                    Toast.info(res.message, 2)
                  }
                }
              }).catch(res =>Toast.fail("注销失败", 1))
            }
          }, 1000);
        }
      }
    );
  };
  adminLevelName = () => {
    const adminMsg = sessionStorage.getItem('adminType')
    let userMsg = {}
    {adminMsg == 0 ? 
      userMsg = sessionStorage.getItem("loginMsg") &&
      JSON.parse(sessionStorage.getItem("loginMsg")).user
      :
      userMsg = sessionStorage.getItem("loginMsg") &&
      JSON.parse(sessionStorage.getItem("loginMsg"))
    }
    if (userMsg.userType == 0) {
      return "系统管理员";
    } else if (userMsg.userType == 1) {
      return "总管理员";
    } else if (userMsg.userType == 2) {
      return "高级管理员";
    } else if (userMsg.userType == 3) {
      return "一级管理员";
    } else if (userMsg.userType == 4) {
      return "二级管理员";
    }
  };

  bindingWeiXin = () => {
    // this.props.history.push("/bingWeiXin/?code=52232");
    // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxdab258112010d617&redirect_uri=http://temp.hawk-sight.com/bingWeiXin&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe8fc9d2dc554e9c1&redirect_uri=http://temp.hawk-sight.com/bingWeiXin&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
  };

  unbindingWeiXin = () => {
    axios.get("/api/oa/sysUser/unbindWeiXin?adminId=" + userMsg.id).then(res => {
      if (res) {
        if (res.status == 1000) {
          Toast.success(res.data, 1);
          window.location.reload();
        } else {
          Toast.fail(res.message, 1);
        }
      }
    });
  }

  getBindingWeiXinUser = () => {
    axios.get("/api/oa/sysUser/getWeiXinUserInfo?adminId=" + userMsg.id).then(res => {
      if (res && res.status == 1000) {
        this.setState({
          weiXinUser: res.data
        });
      }
    });
  };

  render() {
    const adminMsg = { adminType: sessionStorage.getItem('adminType')}
    let userMsg = {}
    {adminMsg.adminType == 0 ? 
      userMsg = sessionStorage.getItem("loginMsg") &&
      JSON.parse(sessionStorage.getItem("loginMsg")).user
      :
      userMsg = sessionStorage.getItem("loginMsg") &&
      JSON.parse(sessionStorage.getItem("loginMsg"))
    }
    const { currency1, currency2, currency3, currency5, currency11, currency12, currency32} = this.state.adminCurrencyAll
    console.log('??',this.state.adminCurrencyAll)
    return (
      <div className="menu" style={{ paddingBottom: 50 }}>
        <div className="header-sticky">
          <NavBar mode="dark">铁通集团对讲管理平台</NavBar>
        </div>
        <div className="sub-container">
          <WingBlank>
            <div>
              <WhiteSpace size="lg" />

              <Card>
                {adminMsg.adminType == 0 ? (
                  <Card.Header
                  title={userMsg.loginName}
                  thumb={admin_list}
                  extra={
                    <div>
                      <div
                        onClick={() =>
                          this.props.history.push(`/addadmin/${userMsg.id}/edit`)
                        }
                      >
                        
                        <Icon type="right" />
                      </div>
                      {adminMsg.adminType == 0 ? (''):(<div>{this.adminLevelName()}</div>)}
                    </div>
                    
                  }
                />
                ) : (
                  <Card.Header
                  title={userMsg.loginName}
                  thumb={admin_list}
                  extra={
                    <div>
                      <div
                        onClick={() =>
                          this.props.history.push(`/newAddadmin/${userMsg.id}/edit`)
                        }
                      >
                        
                        <Icon type="right" />
                      </div>
                      {adminMsg.adminType == 0 ? (''):(<div>{this.adminLevelName()}</div>)}
                    </div>
                    
                  }
                />
                )}
                <Card.Body>
                {adminMsg.adminType == 0 ? (<div>{this.adminLevelName()}</div>):(
                  <div>
                    <Flex style={{color:'#a7abb7'}}>
                      <Flex.Item style={{fontSize:'9px'}}>一年虚拟币: {currency11.totalCount}/{currency11.surplusCount}/{currency11.usedCount}</Flex.Item>
                      <Flex.Item style={{fontSize:'9px'}}>三年虚拟币: {currency1.totalCount}/{currency1.surplusCount}/{currency1.usedCount}</Flex.Item>
                    </Flex>
                    <WhiteSpace size="lg" />
                    <Flex style={{color:'#a7abb7'}}>
                      <Flex.Item style={{fontSize:'9px'}}>一年续费币: {currency12.totalCount}/{currency12.surplusCount}/{currency12.usedCount}</Flex.Item>
                      <Flex.Item style={{fontSize:'9px'}}>三年续费币: {currency32.totalCount}/{currency32.surplusCount}/{currency32.usedCount}</Flex.Item>
                    </Flex>
                    <WhiteSpace size="lg" />
                    <Flex style={{color:'#a7abb7'}}>
                      <Flex.Item style={{fontSize:'9px'}}>优惠账号续费币: {currency2.totalCount}/{currency2.surplusCount}/{currency2.usedCount}</Flex.Item>
                      <Flex.Item style={{fontSize:'9px'}}>vip续费币: {currency5.totalCount}/{currency5.surplusCount}/{currency5.usedCount}</Flex.Item>
                    </Flex>
                    <WhiteSpace size="lg" />
                    <Flex style={{color:'#a7abb7'}}>
                      <Flex.Item style={{fontSize:'9px'}}>行业币: {currency3.totalCount}/{currency3.surplusCount}/{currency3.usedCount}</Flex.Item>
                      <Flex.Item style={{fontSize:'9px',color:'red'}}>备注: 总数 / 可用 / 已用</Flex.Item>
                    </Flex>
                    <WhiteSpace size="lg" />
                  </div>
                )}


                </Card.Body>
                {adminMsg.adminType == 0 ? (
                  <Card.Footer
                    content={
                      userMsg.userType == 3 || userMsg.userType == 4 ? (
                        this.state.weiXinUser ? (
                          <a
                            onClick={() =>
                              alert('提示', '解除订阅后无法获取公众号推送账号过期信息，确定吗？', [
                                { text: '取消', onPress: () => console.log('cancel') },
                                { text: '确定', onPress: () => this.unbindingWeiXin() },
                              ])
                            }
                          >
                            订阅公众号：{this.state.weiXinUser.nickname}
                          </a>
                        ) : (
                            <a
                              onClick={() =>
                                alert('提示', '订阅后可接收公众号推送账号过期信息，确定吗？', [
                                  { text: '取消', onPress: () => console.log('cancel') },
                                  { text: '确定', onPress: () => this.bindingWeiXin() },
                                ])
                              }
                            >
                              公众号订阅
                            </a>)
                      ) : ('')
                    }
                    extra={
                      <span size="small" onClick={() => this.loginOut()}>
                        安全退出
                      </span>
                    }
                  />
                ):(
                  <Card.Footer
                    extra={
                      <span size="small" onClick={() => this.loginOut()}>
                        安全退出
                      </span>
                    }
                  />
                )}
              </Card>
            </div>
            <div className="list-item">
              <div className="title">用户管理</div>
              {adminMsg.adminType == 0 ? (
                <div>
                  <Grid
                    data={userData}
                    columnNum={userData.length}
                    square={false}
                    onClick={e => this.handleClick(e)}
                  />
                </div>
              ):(
                <div>
                  <Grid
                    data={NewUserData}
                    columnNum={NewUserData.length}
                    square={false}
                    onClick={e => this.handleNewClick(e)}
                  />
                </div>
              ) }
            </div>
            {/* {userMsg.userType <= 4 && userMsg.userType >= 2 ? ( */}
            {userMsg.userType == 4 && adminMsg.adminType == 0 ? (
              <div className="list-item">
                <div className="title">群组管理</div>
                <div>
                  <Grid
                    data={groupData}
                    columnNum={groupData.length}
                    square={false}
                    onClick={e => this.handleClick(e)}
                  />
                </div>
              </div>
            ) : userMsg.userType == 4 && adminMsg.adminType == 5 ? (
              <div className="list-item">
                <div className="title">群组管理</div>
                <div>
                  <Grid
                    data={NewGroupData}
                    columnNum={groupData.length}
                    square={false}
                    onClick={e => this.handleNewClick(e)}
                  />
                </div>
              </div>
            ):('')}

            {adminMsg.adminType == 0 ? (
              <div className="list-item">
                <div className="title">账单管理</div>
                <div>
                  <Grid
                    data={billData}
                    columnNum={billData.length}
                    square={false}
                    onClick={e => this.handleClick(e)}
                  />
                </div>
              </div>
            ): (
              // 虚拟币菜单
              <div className="list-item">
                {/* <div className='title'>虚拟币菜单</div> */}
                <div>
                  {/* <Grid
                    onClick={e => this.handleClick(e)}
                  /> */}
                </div>
              </div>
            )}
            
            {userMsg.userType == 4? (
              <div className="list-item">
                <div className="title">卡号管理</div>
                <div>
                  {console.log(simData)}
                  <Grid
                    data={simData}
                    columnNum={simData.length}
                    square={false}
                    onClick={e => this.handleClick(e)}
                  />
                </div>
              </div>
            ) : (
                ""
              )}
              {userMsg.userType == 0? (
              <div className="list-item">
                <div className="title">SIM卡管理</div>
                <div>
                  <Grid
                    data={cardPoolData}
                    columnNum={cardPoolData.length}
                    square={false}
                    onClick={e => this.handleClick(e)}
                  />
                </div>
              </div>
            ) : (
                ""
              )}

            {userMsg.userType < 4 && userMsg.userType >= 0 && adminMsg.adminType == 0? (
              <div className="list-item">
                <div className="title">管理员管理</div>
                <div>
                  <Grid
                    data={adminData}
                    columnNum={adminData.length}
                    square={false}
                    onClick={e => this.handleClick(e)}
                  />
                </div>
              </div>
            ) : userMsg.userType < 4 && userMsg.userType >= 0 && adminMsg.adminType == 5 ? (
              <div className="list-item">
                <div className="title">管理员管理</div>
                <div>
                  <Grid
                    data={adminData}
                    columnNum={adminData.length}
                    square={false}
                    onClick={e => this.handleNewClick(e)}
                  />
                </div>
              </div>
            ):('')}
            {userMsg.userType = 0 ? (
              <div>卡管理</div>
            ):("")}
            <WhiteSpace size="xl" />
          </WingBlank>
          <div className="header-sticky header-notice">
            {this.state.notice ? (
              <NoticeBar
                marqueeProps={{ loop: true, style: { padding: "0 7.5px" } }}
                mode="closable"
              >
                {this.state.notice}
              </NoticeBar>
            ) : (
                ""
              )}
          </div>
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    prop: state.prop
  };
};
const mapDispatchToProps = dispatch => {
  return {
    emptySelectGroup: () => {
      dispatch({
        type: "group/emptyGroups"
      });
    },
    deleteSelectUserList: () => {
      dispatch({
        type: "userSelectList/deleteSelectUserList"
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
