import React, { Component } from "react";
import {
  Icon,
  NavBar,
  List,
  Checkbox,
  WingBlank,
  WhiteSpace,
  Button,
  Radio,
  Toast
} from "antd-mobile";
import axios from "../../../../service/axios";
import http from '../../../../utils/request'
const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;
const RadioItem = Radio.RadioItem;
const selectedContainer = {
  position: "fixed",
  width: "100%",
  top: "45px",
  background: "#f5f5f5",
  zIndex: "11",
  transition: "left 0.4s",
  height: document.body.clientHeight - 45 + "px",
  overflowY: "scroll"
};
export class newGroupCombination extends Component {
  state = {
    drawerLeftA: document.body.clientWidth,
    drawerLeftB: document.body.clientWidth,
    groupDatas: {},
    parentGroups: [],
    pttGroup: [],
    outParentGroups: [],
    subGroups: [],
    parentGid: "",
    attrib: 1
  };

  async componentDidMount() {
    // this.getInitDatas();
    await this.getParentList();
  }
  onChangeAttrib(e) {
    this.setState({
      attrib: e.target.checked ? 1 : 0
    });
  }
  getParentList() {
    return new Promise(rev => {
      http.get("/api/group/parent/list", {
            pageNo: 1,
            pageSize: 3000,
        },'new')
        .then(res => {
          rev();
          if (res.status == 200) {
            this.setState({
              parentGroups: res.data.resultList,
            });
          }
        });
    });
  }
  getChildList(parentGid) {
    return new Promise((rev, rej) => {
      http.get("/api/group/no_parent/list", {
            parentGid,
            pageNo: 1,
            pageSize: 3000,
        },'new')
        .then(res => {
          rev();
          if (res.status == 200) {
            this.setState({
              outParentGroups: res.data.resultList,
            });
          }
        });
    });
  }
  getInitDatas(parentGid) {
    return new Promise((rev, rej) => {
      Toast.loading("加载中...", 0);
      http
        .get("/api/group/parent/list", {
          params: {
            parentGid
          }
        },'new')
        .then(res => {
          rev(res);
          console.log(res);
          if (res.status == 200 && res.data) {
            const {
              parentGroups,
              pttGroup,
              outParentGroups,
              subGroups
            } = res.data;
            let arr = [...outParentGroups, ...subGroups];
            this.setState({
              groupDatas: res.data,
              parentGroups,
              pttGroup,
              outParentGroups: arr,
              subGroups,
              attrib: pttGroup ? pttGroup.attrib : 0
            });
          }
        });
    });
  }
  onChangeRadio(parentGid) {
    this.setState({
      parentGid
    });
  }
  onChangeCheckox(e, item) {
    console.log(e, item);
    let arr = [...this.state.subGroups];
    console.log(arr);
    if (e.target.checked) {
      arr.push(item);
      this.setState({
        subGroups: arr
      });
    } else {
      arr.splice(arr.map(items => items.gid).indexOf(item.gid), 1);
      this.setState({
        subGroups: arr
      });
    }
    setTimeout(() => {
      console.log(this.state.subGroups);
    }, 0);
  }
  onSwitchGroup() {
    this.getChildList(this.state.parentGid).then(res => {
      this.setState({
        drawerLeftA: document.body.clientWidth,
        drawerLeftB: 0
      });
    });
  }
  saveNest() {
    const { pttGroup, subGroups, attrib } = this.state;
    let params = {
      gid: this.state.parentGid,
      gids: subGroups.map(item => item.gid),
      attrib
    };
    http.post("/api/group/parent_child/set", {
        ...params
      },'new')
      .then(res => {
        if (res && res.status == 200) {
          Toast.success("操作成功", 1);
        } else {
          Toast.fail("操作失败", 1);
        }
      });
  }
  render() {
    const {
      drawerLeftA,
      parentGroups,
      parentGid,
      drawerLeftB,
      outParentGroups,
      subGroups,
      pttGroup,
      attrib
    } = this.state;
    return (
      <div className="groupcombination" style={{ overflow: "hidden" }}>
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            嵌套群组1
          </NavBar>
        </div>
        <div className="sub-container">
          <List>
            <Item
              arrow="horizontal"
              extra={pttGroup.name ? pttGroup.name : "请选择群组"}
              onClick={() =>
                this.setState({
                  drawerLeftA: 0
                })
              }
            >
              选择群组
            </Item>
            <Item wrap>
              <span className="red-star" style={{ fontSize: 12 }}>
                *成为子群组的群组不能作为父群组添加子群组
              </span>
              <br />
              <span className="red-star" style={{ fontSize: 12 }}>
                *子组的成员不能同时是父组里的成员，否则用户无法构成父子关系
              </span>
            </Item>
            <Item
              arrow="horizontal"
              extra={
                subGroups.length == 0
                  ? "请选择子群组"
                  : `已选择${subGroups.length}个群组`
              }
              onClick={() =>
                this.setState({
                  drawerLeftB: 0
                })
              }
            >
              子群组
            </Item>
            {/* <CheckboxItem
              checked={attrib == 1}
              key="1"
              onChange={e => this.onChangeAttrib(e)}
            >
              允许子群组内互呼
            </CheckboxItem> */}
          </List>
          <WingBlank>
            <WhiteSpace size="lg" />
            <Button type="primary" onClick={() => this.saveNest()}>
              确定
            </Button>
            <WhiteSpace size="lg" />
            <Button onClick={this.props.history.goBack}>返回</Button>
          </WingBlank>
        </div>
        <div
          className=""
          style={{
            ...selectedContainer,
            left: drawerLeftA
          }}
        >
          <List>
            {parentGroups.map((item, index) => (
              <RadioItem
                key={index}
                checked={item.gid === parentGid}
                onChange={() => this.onChangeRadio(item.gid)}
              >
                {item.name}
              </RadioItem>
            ))}
            <Item>
              <Button type="primary" onClick={() => this.onSwitchGroup()}>
                确定
              </Button>
              <WhiteSpace size="lg" />
              <Button
                onClick={() =>
                  this.setState({
                    drawerLeftA: document.body.clientWidth
                  })
                }
              >
                返回
              </Button>
            </Item>
          </List>
        </div>

        <div
          className=""
          style={{
            ...selectedContainer,
            left: drawerLeftB
          }}
        >
          <List>
            {outParentGroups.map((item, index) => (
              <CheckboxItem
                checked={subGroups.map(items => items.gid).includes(item.gid)}
                key={index}
                onChange={e => this.onChangeCheckox(e, item)}
              >
                {item.name}
              </CheckboxItem>
            ))}
            <Item>
              <Button
                type="primary"
                onClick={() =>
                  this.setState({
                    drawerLeftB: document.body.clientWidth
                  })
                }
              >
                确定
              </Button>
              <WhiteSpace size="lg" />
              <Button
                onClick={() =>
                  this.setState({
                    drawerLeftB: document.body.clientWidth
                  })
                }
              >
                返回
              </Button>
            </Item>
          </List>
        </div>
      </div>
    );
  }
}

export default newGroupCombination;
