import React, { Component } from "react";
import {
  InputItem,
  WhiteSpace,
  List,
  WingBlank,
  ActionSheet,
  Button,
  Toast
} from "antd-mobile";
import axios from "../../service/axios";
const Item = List.Item;
const BUTTONS = [
  "账号",
  "用户ID",
  "名字",
  "IMIE号",
  "群组id",
  "默认群组",
  "(快)到期账号",
  "备注",
  "取消"
];
const paramsKey = [
  "account",
  "uid",
  "name",
  "imei",
  "gid",
  "defaultGidName",
  "expireAccount",
  "remarks"
];
const BUTTONS_GROUP = ["群组名", "取消"];
const paramsKeyGroup = ["name"];
export class Search extends Component {
  state = {
    sType: BUTTONS[0],
    paramsKey: paramsKey[0],
    sTypeGroup: BUTTONS_GROUP[0],
    paramsKeyGroup: paramsKeyGroup[0],
    keyWords: ""
  };
  showSearchTypeUser() {
    ActionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        cancelButtonIndex: BUTTONS.length - 1,
        message: "选择要搜索的类型",
        maskClosable: true
      },
      buttonIndex => {
        if (buttonIndex == BUTTONS.length - 1) return;
        this.setState({
          sType: BUTTONS[buttonIndex],
          paramsKey: paramsKey[buttonIndex]
        });
      }
    );
  }
  showSearchTypeGroup() {
    ActionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS_GROUP,
        cancelButtonIndex: BUTTONS_GROUP.length - 1,
        message: "选择要搜索的类型",
        maskClosable: true
      },
      buttonIndex => {
        if (buttonIndex == BUTTONS_GROUP.length - 1) return;
        this.setState({
          sTypeGroup: BUTTONS_GROUP[buttonIndex],
          paramsKeyGroup: paramsKeyGroup[buttonIndex]
        });
      }
    );
  }

  onSearch() {
    const { keyWords, paramsKey, paramsKeyGroup } = this.state;
    let o = {};
    if (this.props.type == "user") {
      if (paramsKey == "expiredAccount") {
        o[paramsKey] = keyWords ? keyWords : '';
      } else {
        o[paramsKey] = keyWords ? keyWords : "";
      }
    } else {
      o[paramsKeyGroup] = keyWords;
    }
    console.log('这是o',o)
    this.props.onSearchList(o);
  }
  onSearchAll() {
    this.props.onSearchList({});
  }
  onKeyWords = e => {
    console.log(e);
    this.setState({
      keyWords: e
    });
  };
  render() {
    const { sType, keyWords, sTypeGroup } = this.state;
    const { type } = this.props;
    return (
      <div>
        <div className="top">
          <WhiteSpace size="lg" />
          <List>
            {this.props.type == "user" ? (
              <Item
                arrow="horizontal"
                extra={sType}
                onClick={() => this.showSearchTypeUser()}
              >
                类型
              </Item>
            ) : (
              <Item
                arrow="horizontal"
                extra={sTypeGroup}
                onClick={() => this.showSearchTypeGroup()}
              >
                类型
              </Item>
            )}

            <InputItem
              onChange={this.onKeyWords}
              value={keyWords}
              placeholder="输入查询条件"
              type="text"
            />
            <Item>
              <Button type="primary" onClick={() => this.onSearch()}>
                搜索
              </Button>
              <WhiteSpace size="lg" />
              <Button type="primary" onClick={() => this.onSearchAll()}>
                全部
              </Button>
              <WhiteSpace size="lg" />
              <Button onClick={this.props.closeSearch}>返回</Button>
            </Item>
          </List>
        </div>
      </div>
    );
  }
}

export default Search;
