import React, { Component } from 'react'
import { Router, Route, Redirect, withRouter, Switch } from 'dva/router'
import createBrowserHistory from 'history/createBrowserHistory'
import { NoticeBar, WhiteSpace } from 'antd-mobile'
import RouterConfig from  './router'

let mainStlye = { width: '100%', height: '100%' }
const history = createBrowserHistory()

const AuthPage = withRouter(({ history }) => {
  return sessionStorage.getItem('loginMsg') ? (
    <Redirect to="/menu" />
  ) : (
    <Redirect to="/login" />
  )
})

export class Main extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="main" style={mainStlye}>
          <div className="header-sticky header-notice">
            <NoticeBar
              marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}
              mode="closable"
            >
              系统于今晚12点进行系统服务升级，届时服务会出现终端，请悉知。
            </NoticeBar>
          </div>
          <div className="container">
            <AuthPage />
            <div>
              <RouterConfig></RouterConfig>
            </div>
          </div>
          <WhiteSpace size="xl"> </WhiteSpace>
        </div>
      </Router>
    )
  }
}
export default Main
