
import React, { Component } from 'react'
import {
    NavBar,
    Icon,
    List,
    WhiteSpace,
    Toast,
    Pagination,
    SearchBar,
    WingBlank,
    Modal,
    PickerView
  } from 'antd-mobile'
import axios from '../../../service/axios'
import NoneDatas from "../../../components/noneDatas";
import ListItem from 'antd-mobile/lib/list/ListItem';
import './simFlowPool.scss'
const Item = List.Item;
const Brief = Item.Brief;
const season = [
  {
      label:'果壳',
      value:'01',
  },
  {
      label:'泉州电信',
      value:'02',
  },
  {
      label:'武汉电信',
      value:'03',
  },
];

export class SimFlowPool extends Component{
  state = {
    simDatas: [],
    simFlowPoolDatas: [],
    pageTotalNum: 0,
    pageNo: 1,
    pageSize: 10,
    listCount: 0,
    modalFlag:false,
    carId:'',
    selectData:['01'],
    carName:'',
    carMsg:{}
  }
  componentDidMount(){

  }
  submit(e){
    this.setState({
      modalFlag:true,
      carId:e
    })
  }
  onClose(){
    this.setState({
        modalFlag:false,
    })
  }
  affirm(){
    console.log(this.state.carId)
    console.log(this.state.selectData)
    this.onClose()

    let formdata = new FormData();
    formdata.append('dealerCode',this.state.selectData[0])
    formdata.append('groupId',this.state.carId)
    formdata.append("pageSize",this.state.pageSize)
    formdata.append( "pageNo",this.state.pageNo)
    // 查询卡池流量
    axios.post('/api/oa/card/groupQuata?iccid',formdata)
    .then(res => {
      if(res && res.status === 1000){
        console.log("pool",res)
        for(var i=0;i<season.length;i++){
          if(season[i].value==this.state.selectData[0]){
            this.setState({
              carName:season[i].label
            })
          }
        }
        this.setState({
          carMsg:res.data||{},
        })
      } else {
        this.setState({
          simDatas:[],
          pageTotalNum:0
        })
      }
    })
    // 查询卡池内的卡列表
    axios.post('/api/oa/card/groupFlowMonthly',formdata)
    .then(res=>{
      if(res && res.status === 1000){
        console.log('res值',res)
        this.setState({
          simDatas : res.data.list||[],
          listCount: res.data.count,
          pageTotalNum: Math.ceil(res.data.count/10)
        })  
        console.log('simDatas',this.state)
      }
      else{
        this.setState({
          simDatas:[],
          pageTotalNum:0
        })
        Toast.fail(res.message)
      }
    });
  }
  selectChange = (value) => {
    this.setState({
        selectData:value,
    });
  }
  onPageChange(e){
    console.log(e)
    this.setState({
      pageNo:e
    },()=>{
      this.affirm()
    })
  }
  render(){
    const {
      pageNo,
      pageTotalNum,
      pageSize,
      listCount,
      simDatas,
      simFlowPoolDatas
    } = this.state
    // 卡池信息
    const ItemInfo = ({item}) => {
      console.log(item)
      return (
        <div className="simflowpool-info">
          <div>
            <span>&ensp;&ensp;卡池:&ensp;{this.state.carId}</span>
            <span>卡商:&ensp;{this.state.carName}</span>
          </div>
          <div>
            <span>&ensp;&ensp;总流量:&ensp;{item.totalAmount}Mb</span>
            <span>卡数量:&ensp;{listCount}</span>
          </div>
          <div>
            <span>&ensp;&ensp;已使用:&ensp;{item.useAmount}Mb</span>
            <span>剩余:&ensp;{item.remainAmount}Mb</span>
          </div>
        </div>
      )
    }
    // 卡池关联卡号列表
    const ItemList = ({item}) => {
      return(
        <div className="simDatasList">
          <div>
            <span>
              卡号: {item.iccid}
            </span>
          </div>  
          <div className='status_box'>
            <span>
              已使用: {item.dataAmount} kb
            </span>
            <span>
              卡状态:&ensp;
              {item.status == 1 ? '待激活' : ''}
              {item.status == 2 ? '已激活' : ''}
              {item.status == 4 ? '已停机' : ''}
              {item.status == 6 ? '可测试' : ''}
              {item.status == 7 ? '库存' : ''}
              {item.status == 8 ? '预销户' : ''}
              {item.status == 9 ? '已销户' : ''}
            </span>
          </div>
        </div>
      )
    }
    return (
      <div className='simflowpool'>
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            onLeftClick={() => this.props.history.goBack()}
          >
            卡池信息
          </NavBar>
          <SearchBar
            onSubmit={value => this.submit(value)}
            placeholder="输入卡池id查询" 
            />
        </div>

        {/* 卡商选择-对话框 */}
        <Modal
            popup
            visible={this.state.modalFlag}
            onClose={this.onClose=this.onClose.bind(this)}
            animationType="slide-up"
            >
                <div style={{display:'flex',justifyContent:'space-between',height:'40px',alignItems:'center',padding:'0 10px'}}>
                    <div onClick={()=>{this.setState({modalFlag:false,mapDom:1000})}}>取消</div>
                    <div>选择卡商</div>
                    <div onClick={this.affirm=this.affirm.bind(this)}>确定</div>
                </div>
                <PickerView
                    value={this.state.selectData}
                    data={season}
                    onChange={this.selectChange}
                    cascade={false}
                />
        </Modal>

        {simDatas.length == 0 ? <div style={{paddingTop:'90px'}}><NoneDatas /></div> : null}

        <div className='sub-container'>
          {simDatas.length !=0 ? (
            <ItemInfo 
              item={this.state.carMsg} 
            />
            ) : null
            }
          <div style={{marginTop:'10px'}}>
          {simDatas.map((item, index) => {
            return(
              <ItemList key={index} item={item}/>
            )
          })}
          </div>
          <WhiteSpace />
          <WingBlank>
            {pageTotalNum > 0 ? (
              <Pagination
                total={pageTotalNum}
                current={pageNo}
                locale={{
                  prevText: "上一页",
                  nextText: "下一页"
                }}
                onChange={e => this.onPageChange(e)}
              />
            ) : (
                ""
              )}
          </WingBlank>
        </div>
      </div>
    )
  }
}
export default SimFlowPool
