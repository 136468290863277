import React, { Component } from 'react'
import { NavBar, Icon, List, Button } from 'antd-mobile'
import { connect } from 'dva'
const Item = List.Item
export class UserSelectList extends Component {
  test = i => {
    console.log(i)
    console.log(this.props)
  }
  render() {
    const data = []
    for (let i = 0; i < 100; i++) {
      data.push({
        value: i,
        label: 'zhaoyu' + i + '-' + i
      })
    }
    const {appSelectGroups} = this.props

    return (
      <div className="selectList">
        <div className="header-sticky">
          <NavBar
            icon={<Icon type="left" />}
            rightContent={[
              <Button key="0" inline size="small" type="primary" onClick={()=>this.props.history.goBack()}>
                确定
              </Button>
            ]}
            onLeftClick={() => this.props.history.goBack()}
          >
            已选择群组
          </NavBar>
        </div>
        <div className="sub-container">
          <List renderHeader={() => '请选择群组成员'}>
            {appSelectGroups.map((i,index) => (
              <Item
                key={index}
                extra={<Icon type="cross" onClick={() => this.test(i.gid)} />}
              >
                {i.name}
              </Item>
            ))}
          </List>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    appSelectGroups: state.app.selectGroups
  }
}
const mapDispatchToProps = dispatch => {
  return {
    deleteSelectGroup: gid => {
      dispatch({
        type: 'app/deleteSelectGroup',
        gid
      })
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSelectList)
