import React, { Component } from "react";
import {
  NavBar,
  Icon,
  InputItem,
  List,
  Picker,
  Button,
  WhiteSpace,
  WingBlank,
  Checkbox,
  TextareaItem,
  Toast
} from "antd-mobile";
import { createForm } from "rc-form";
import "./userAdd.scss";
import axios from "../../service/axios";
import { connect } from "dva";
const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;
const userMsg =
  sessionStorage.getItem("loginMsg") &&
  JSON.parse(sessionStorage.getItem("loginMsg")).user;
const GpsInterval = [
    {
      label: <div key="1">不开启</div>,
      value: 0
    },
    {
      label: <div key="2">1分钟</div>,
      value: 60
    },
    {
      label: <div key="3">5分钟</div>,
      value: 300
    },
    {
      label: <div key="4">10分钟</div>,
      value: 600
    }
  ];
  const Whether=[
    {
        label: <div key="1">是</div>,
        value: 0
    },
    {
        label: <div key="2">否</div>,
        value: 1
    },
  ]
export class BindingIccid extends Component {
  state = {
    userInfo: {
        gpsInterval: 0,
        whether:0
    },
    billList: [],
    groupDatas:[],
    voice: true, //会话权限 0,1,2,9
    switchs: true, //切组
    single: true, //单呼
    groupSingle: true, //跨组单呼
    radioNum:1,
    domArr:[{iccid:'',imei:''}]
  };
    //验证是否连号创建
    validateWhether = (rule, value, callback)=>{
        if (typeof value[0]) {
            callback();
        } else {
            callback(new Error("请选择是否连号创建"));
        }
    }
    //验证账单号
    validateBillId = (rule, value, callback) => {
        if (value[0]) {
        callback();
        } else {
        callback(new Error("请选择账单号"));
        }
    }
    validateAccount = (rule, value, callback) => {
        if (value) {
            if (value.trim()) {
                if (!/^[0-9a-zA-z\*]+$/.test(value)) {
                    callback(new Error("用户名只能是字母,数字和 * 组合"));
                } 
                else {
                    if (value.length < 2) {
                        callback(new Error("用户名长度不能少于2"));
                    } else {
                        callback();
                    }
                }
            } else {
                callback(new Error("请输入用户名"));
            }
        } else {
        callback(new Error("请输入用户名"));
        }
    };
    validatePassword = (rule, value, callback) => {
        if (value) {
            if (value.trim()) {
                if (!/^[0-9a-zA-z\*]+$/.test(value)) {
                callback(new Error("密码只能是字母,数字和 * 组合"));
                } else {
                if (value.length < 6) {
                    callback(new Error("密码长度不能少于6"));
                } else {
                    callback();
                }
                }
            } else {
                callback(new Error("请输入密码"));
            }
        } else {
        callback(new Error("请输入密码"));
        }
    };
    //验证gps
    validateGroup = (rule, value, callback) => {
        if (typeof value[0] == "number") {
        callback();
        } else {
        callback(new Error("请选择默认群组"));
        }
    };
    validateGpsinterval = (rule, value, callback) => {
        console.log(value[0])
        if (typeof value[0] == "number") {
        callback();
        } else {
        callback(new Error("请选择默认群组"));
        }
    };
    validateIccidstart1 = (rule, value, callback)=>{
        if(value){
            if (value.trim()) {
                if (!/^8986[a-zA-Z0-9]{8,9}[0-9]{6}$/.test(value)) {
                    callback(new Error("ICCID只能是8986开头，6位数字结尾格式"));
                } 
                else {
                    if (value.length <20) {
                        callback(new Error("ICCID长度不能少于20位"));
                    } else {
                        callback();
                    }
                }
            } else {
                callback(new Error("ICCID长度不能少于20位"));
            }
        }
        else{
            callback(new Error("请输入ICCID"));
        }
    }
    validateIccidstart2 = (rule, value, callback)=>{
        if(value){
            if (value.trim()) {
                if (!/^8986[a-zA-Z0-9]{8,9}[0-9]{6}$/.test(value)) {
                    callback(new Error("ICCID只能是8986开头，6位数字结尾格式"));
                } 
                else {
                    if (value.length <20) {
                        callback(new Error("ICCID长度不能少于20位"));
                    } else {
                        callback();
                    }
                }
            } else {
                callback(new Error("ICCID长度不能少于20位"));
            }
        }
        else{
            callback(new Error("请输入ICCID"));
        }
    }
    validateIccidstart3 = (rule, value, callback)=>{
        if(value){
            if (value.trim()) {
                if (!/^8986[a-zA-Z0-9]{8,9}[0-9]{6}$/.test(value)) {
                    callback(new Error("ICCID只能是8986开头，6位数字结尾格式"));
                } 
                else {
                    if (value.length <19) {
                        callback(new Error("ICCID长度不能少于19位"));
                    } else {
                        callback();
                    }
                }
            } else {
                callback(new Error("ICCID长度不能少于19位"));
            }
        }
        else{
            callback(new Error("请输入ICCID"));
        }
    }
    validateIccidstart4 = (rule, value, callback)=>{
        if(value){
            if (value.trim()) {
                if (!/^8986[a-zA-Z0-9]{8,9}[0-9]{6}$/.test(value)) {
                    callback(new Error("ICCID只能是8986开头，6位数字结尾格式"));
                } 
                else {
                    if (value.length <19) {
                        callback(new Error("ICCID长度不能少于19位"));
                    } else {
                        callback();
                    }
                }
            } else {
                callback(new Error("ICCID长度不能少于19位"));
            }
        }
        else{
            callback(new Error("请输入ICCID"));
        }
    }
    validateDealer = (rule, value, callback) => {
        if (value) {
        if (value.trim()) {
            callback();
        } else {
            callback(new Error("请输入经销商密码"));
        }
        } else {
        callback(new Error("请输入经销商密码"));
        }
    };
    validateCheckboxItem = (rule, value, callback) => {
        const { voice, switchs, single, groupSingle } = this.state;
        if (!voice && !switchs && !single && !groupSingle) {
        callback(new Error("请至少勾选一个权限"));
        } else {
        callback();
        }
    };
    onChangeCheckBox = (i, type) => {
        if (type == 1) {
        this.setState({
            voice: i.target.checked
        });
        } else if (type == 2) {
        this.setState({
            switchs: i.target.checked
        });
        } else if (type == 3) {
        this.setState({
            single: i.target.checked
        });
        } else {
        this.setState({
            groupSingle: i.target.checked
        });
        }
    };
    //获取账单列表
    getBillsByPttUser() {
        axios
        .get("/api/oa/pttBill/getBillsByPttUser", {
            params: {
            billid: this.state.userInfo.billid
            }
        })
        .then(res => {
            console.log(res);
            Toast.hide();
            if (res && res.status == 1000 && res.data) {
            let arr = [];
            res.data.map((item, index) => {
                arr.push({
                label: (
                    <div key={index} style={{ fontSize: "10px" }}>
                    {item.id +
                        `(${item.endDate.split(" ")[0]}到期,${
                        item.type == 0 ? "基础套餐" : ""
                        }${item.type == 1 ? "调度套餐" : ""}${
                        item.type == 2 ? "视频套餐" : ""
                        } ${item.leftnum}/${item.number})`}
                    </div>
                ),
                value: item.id
                });
            });
            console.log(arr);
            this.setState({
                billList: arr
            });
            } else {
            Toast.fail("获取账单号列表失败！");
            }
        });
    }
    //权限转换
  funcsListExchange() {
    const { voice, switchs, single, groupSingle } = this.state;
    let str = "";
    if (voice) {
      str = "0";
    }
    if (switchs) {
      str += ",1";
    }
    if (single) {
      str += ",2";
    }
    if (groupSingle) {
      str += ",9";
    }
    return str;
  }
    submitBtn(){
        this.props.form.validateFields({ force: true }, error => {
            if (!error) {
                var dataMSg = this.props.form.getFieldsValue();
                var formdata = new FormData();
                formdata.append('seriesType',dataMSg.whether[0])
                formdata.append('billid',dataMSg.billid[0])
                formdata.append('name',dataMSg.userName)
                formdata.append('password',dataMSg.password)
                formdata.append('gpsInterval',dataMSg.gpsInterval[0])
                formdata.append('defaultGid',dataMSg.group[0])
                formdata.append('agentpwd',dataMSg.dealer)
                formdata.append('funcsList',this.funcsListExchange())
                if(dataMSg.whether[0]==0){
                    if(this.state.radioNum==0){
                        formdata.append('siccid',dataMSg.iccidstart1)
                        formdata.append('eiccid',dataMSg.iccidstart2)
                    }
                    else{
                        formdata.append('siccid',dataMSg.iccidstart3)
                        formdata.append('eiccid',dataMSg.iccidstart4)
                    }
                }else{
                    if(this.state.radioNum==0){
                        for(var i=0;i<this.state.domArr.length;i++){
                            if(!/^8986[a-zA-Z0-9]{8,9}[0-9]{6}$/.test(this.state.domArr[i].iccid)){
                                Toast.fail("第'"+(i+1)+"'个ICCID只能是8986开头，6位数字结尾格式",1)
                                return;
                            }
                            if(this.state.domArr[i].iccid.length!=20){
                                Toast.fail("第'"+(i+1)+"'个ICCID长度不能少于20位",1)
                                return;
                            }
                            if(this.state.domArr[i].imei){
                                if(!/^[a-zA-Z0-9]{12,16}$/.test(this.state.domArr[i].imei)){
                                    Toast.fail("第'"+(i+1)+"'个IMEI号是12-16位的英文字母，数字",1)
                                    return;
                                }
                            }
                        }
                        formdata.append('iccids',JSON.stringify(this.state.domArr))
                    }else{
                        for(var i=0;i<this.state.domArr.length;i++){
                            if(!/^8986[a-zA-Z0-9]{8,9}[0-9]{6}$/.test(this.state.domArr[i].iccid)){
                                Toast.fail("第'"+(i+1)+"'个ICCID只能是8986开头，6位数字结尾格式",1)
                                return;
                            }
                            if(this.state.domArr[i].iccid.length!=19){
                                Toast.fail("第'"+(i+1)+"'个ICCID长度不能少于19位",1)
                                return;
                            }
                            if(this.state.domArr[i].imei){
                                if(!/^[a-zA-Z0-9]{12,16}$/.test(this.state.domArr[i].imei)){
                                    Toast.fail("第'"+(i+1)+"'个IMEI号是12-16位的英文字母，数字",1)
                                    return;
                                }
                            }
                        }
                        formdata.append('iccids',JSON.stringify(this.state.domArr))
                    }
                }
                if(!this.funcsListExchange()||this.funcsListExchange()==',9'){
                    Toast.fail("请勾选权限",1)
                    return;
                }

                axios.post("/api/oa/pttUser/saveSeriesIccid", formdata)
                .then(res => {
                    if (res) {
                        if (res.status == 1000 && res.message=='ok') {
                            // this.props.emptyGroups();
                            Toast.success('成功', 2, () => {
                                this.props.history.goBack();
                            });
                        } else {
                            Toast.fail(res.message,2,() => {
                                this.props.history.goBack();
                            });
                        }
                    }
                    else{
                        Toast.fail('失败', 2, () => {
                            this.props.history.goBack();
                        });
                    }
                });
            }
        })
    }
    changeWhether(val){
        let data = Object.assign({}, this.state.userInfo, { whether: val[0]});
        this.setState({
            userInfo:data,
            domArr:[
                {iccid:'',imei:''}
            ]
        })
    }
    changeInput(e){
        this.setState({
            radioNum:Number(e.target.value),
            domArr:[{iccid:'',imei:''}]
        })
    }
    getGroupList() {
        axios
          .get("/api/oa/group/list", {
            params: {
              pageSize: 99999,
              pageNo: 1,
              searchKey:'',
              searchMy: userMsg && userMsg.userType != 4 ? 1 : null
            }
          })
          .then(res => {
              console.log(res)
            if (res && res.status == 1000 && res.data) {
                var arr = new Array();
                for(var i=0;i<res.data.list.length;i++){
                    var obj = {
                        label: <div key={i+1}>{res.data.list[i].name}</div>,
                        value:res.data.list[i].gid
                    }
                    arr.push(obj)
                }
                console.log(arr)
                this.setState({
                    groupDatas:arr
                });
            }
          })
          .catch(res => {
          });
    }
    async componentDidMount() {
        await this.getBillsByPttUser();
        await this.getGroupList();
    }
    addDom(){
        var arr = this.state.domArr;
        arr.push({iccid:'',imei:''})
        this.setState({
            domArr:arr
        })
    }
    delDom(){
        var arr = this.state.domArr;
        arr.pop();
        this.setState({
            domArr:arr
        })
    }
    changeIccid=(key)=>(e)=>{
        const domArr  = [...this.state.domArr]
        this.setState({
            domArr:domArr.map((val,index)=>key==index?{...val,iccid:e}:val)
        },()=>{
            console.log(this.state.domArr)
        })
    }
    changeImei=(key)=>(e)=>{
        const domArr  = [...this.state.domArr]
        this.setState({
            domArr:domArr.map((val,index)=>key==index?{...val,imei:e}:val)
        },()=>{
            console.log(this.state.domArr)
        })
    }
  render() {
    const { getFieldProps, getFieldError } = this.props.form;
    const {
        billList,
        groupDatas
      } = this.state;
    const {
        history
      } = this.props;
      const {
        whether,
        billid,
        userName,
        password,
        gpsInterval,
        group,
        iccidstart1,
        iccidstart2,
        iccidstart3,
        iccidstart4,
        dealerPassword,
        checkboxItem
      } = this.state.userInfo;
    return (
        <div className="adduser">
            <div className="header-sticky">
                <NavBar
                    icon={<Icon type="left" />}
                    onLeftClick={() => history.goBack()}
                >
                    ICCID放号
                </NavBar>
            </div>
            <div className="sub-container">
                <form>
                    <List
                        renderFooter={() =>
                            getFieldError("whether")
                            ||getFieldError("billid") 
                            ||getFieldError("userName")
                            ||getFieldError("password")
                            ||getFieldError("gpsInterval")
                            ||getFieldError("iccidstart1")
                            ||getFieldError("iccidstart2")
                            ||getFieldError("iccidstart3")
                            ||getFieldError("iccidstart4")
                            ||getFieldError("dealer")
                            ||getFieldError("group")
                        }
                        >
                        <Picker
                            {...getFieldProps("whether", {
                                rules: [
                                  {
                                    validator: this.validateWhether
                                  }
                                ],
                                initialValue: [whether]
                              })}
                              onChange={this.changeWhether=this.changeWhether.bind(this)}
                              data={Whether}
                              cols={1}
                              key="1"
                              error={!!getFieldError("whether")}
                        >
                            <Item key="1" arrow="horizontal">
                            <i className="red-star">*</i>
                            是否连号创建
                            </Item>
                        </Picker>
                        <Picker
                            {...getFieldProps("billid", {
                                rules: [
                                    { required: true, message: "请选择账单号" },
                                    { validator: this.validateBillId }
                                ],
                                initialValue: [billid]
                            })}
                            data={billList}
                            cols={1}
                            key="2"
                            error={!!getFieldError("billid")}
                        >
                            <Item key="1" arrow="horizontal">
                            <i className="red-star">*</i>
                            选择账单号
                            </Item>
                        </Picker>
                        <InputItem
                            {...getFieldProps("userName", {
                                rules: [
                                  {
                                    validator: this.validateAccount
                                  }
                                ],
                                initialValue: userName
                              })}
                            clear
                            placeholder="请输入用户名前缀"
                            key="3"
                            maxLength="12"
                            type="text"
                            error={!!getFieldError("userName")}
                            >
                            <i className="red-star">*</i>
                            用户名
                        </InputItem>
                        <InputItem
                            {...getFieldProps("password", {
                                rules: [
                                  {
                                    validator: this.validatePassword
                                  }
                                ],
                                initialValue: password
                              })}
                            clear
                            placeholder="请输入密码"
                            key="4"
                            maxLength="12"
                            type="password"
                            error={!!getFieldError("password")}
                            >
                            <i className="red-star">*</i>
                            密码
                        </InputItem>
                        <Picker
                            {...getFieldProps("gpsInterval", {
                                rules: [
                                  {
                                    validator: this.validateGpsinterval
                                  }
                                ],
                                initialValue: [gpsInterval]
                              })}
                              data={GpsInterval}
                              cols={1}
                              key="5"
                              error={!!getFieldError("gpsInterval")}
                        >
                            <Item key="1" arrow="horizontal">
                            <i className="red-star">*</i>
                            GPS周期
                            </Item>
                        </Picker>
                        <Picker
                            {...getFieldProps("group", {
                                rules: [
                                    { required: true, message: "请选择默认群组" },
                                    { validator: this.validateGroup }
                                ],
                                initialValue: [group]
                            })}
                            cols={1}
                            key="6"
                            data={groupDatas}
                            placeholder="请选择默认群组"
                            error={!!getFieldError("group")}
                        >
                            <Item key="1" arrow="horizontal">
                            <i className="red-star">*</i>
                            默认群组
                            </Item>
                        </Picker>
                        <div className="radioBox">
                            <input checked={this.state.radioNum==1} onChange={this.changeInput=this.changeInput.bind(this)} id="name1" type="radio" value="1" name="name"/>
                            <label for="name1">联通/电信</label>
                            <input checked={this.state.radioNum==0} onChange={this.changeInput=this.changeInput.bind(this)} id="name" type="radio" value="0" name="name"/>
                            <label for="name">移动</label>
                        </div>
                        <div className="iccidBox">
                            {
                                this.state.userInfo.whether==0?(
                                    this.state.radioNum==0?(
                                        <div>
                                            <InputItem
                                                {...getFieldProps("iccidstart1", {
                                                    rules: [
                                                    {
                                                        validator: this.validateIccidstart1
                                                    }
                                                    ],
                                                    initialValue: iccidstart1
                                                })}
                                                error={!!getFieldError("iccidstart1")}
                                                clear
                                                placeholder="请输入20位ICCID起始号码"
                                                key="7"
                                                maxLength="20"
                                                >
                                                <i className="red-star">*</i>
                                                连号创建
                                            </InputItem>
                                            <InputItem
                                                {...getFieldProps("iccidstart2", {
                                                    rules: [
                                                    {
                                                        validator: this.validateIccidstart2
                                                    }
                                                    ],
                                                    initialValue: iccidstart2
                                                })}
                                                error={!!getFieldError("iccidstart2")}
                                                className="input1"
                                                clear
                                                placeholder="请输入20位ICCID结束号码"
                                                key="8"
                                                maxLength="20"
                                                >
                                            </InputItem>
                                        </div>
                                    ):(
                                        <div>
                                            <InputItem
                                                {...getFieldProps("iccidstart3", {
                                                    rules: [
                                                        {
                                                            validator: this.validateIccidstart3
                                                        }
                                                    ],
                                                    initialValue: iccidstart3
                                                })}
                                                error={!!getFieldError("iccidstart3")}
                                                clear
                                                placeholder="请输入19位ICCID起始号码"
                                                key="7"
                                                maxLength="19"
                                                >
                                                <i className="red-star">*</i>
                                                连号创建
                                            </InputItem>
                                            <InputItem
                                                {...getFieldProps("iccidstart4", {
                                                    rules: [
                                                    {
                                                        validator: this.validateIccidstart4
                                                    }
                                                    ],
                                                    initialValue: iccidstart4
                                                })}
                                                error={!!getFieldError("iccidstart4")}
                                                className="input1"
                                                clear
                                                placeholder="请输入19位ICCID结束号码"
                                                key="8"
                                                maxLength="19"
                                                >
                                            </InputItem>
                                        </div>
                                    )
                                ):(
                                    this.state.radioNum==0?(
                                        this.state.domArr.map((val,key)=>{
                                            return(
                                                <div key={key}>
                                                    <InputItem
                                                        value={val.iccid}
                                                        clear
                                                        placeholder="请输入20位ICCID号码"
                                                        onChange={this.changeIccid(key)}
                                                        key="9"
                                                        maxLength="20"
                                                        >
                                                        <i className="red-star">*</i>
                                                        非连号创建
                                                    </InputItem>
                                                    <InputItem
                                                        clear
                                                        value={val.imei}
                                                        onChange={this.changeImei(key)}
                                                        placeholder="请输入12-16位IMEI号"
                                                        key="10"
                                                        minLength="12"
                                                        maxLength="15"
                                                        >
                                                        绑定IMEI
                                                    </InputItem>
                                                </div>
                                            )
                                        })
                                    ):(
                                        this.state.domArr.map((val,key)=>{
                                            return(
                                                <div key={key}>
                                                    <InputItem
                                                        value={val.iccid}
                                                        clear
                                                        placeholder="请输入19位ICCID号码"
                                                        key="9"
                                                        onChange={this.changeIccid(key)}
                                                        maxLength="19"
                                                        >
                                                        <i className="red-star">*</i>
                                                        非连号创建
                                                    </InputItem>
                                                    <InputItem
                                                        clear
                                                        value={val.imei}
                                                        placeholder="请输入12-16位IMEI号"
                                                        key="10"
                                                        minLength="12"
                                                        maxLength="16"
                                                        onChange={this.changeImei(key)}
                                                        >
                                                        绑定IMEI
                                                    </InputItem>
                                                </div>
                                            )
                                        })
                                    )
                                )
                            }
                            {
                                this.state.userInfo.whether==1?(
                                    <div onClick={this.addDom=this.addDom.bind(this)} className="addDom">+</div>
                                ):null
                            }
                            {
                                this.state.domArr.length>1&&this.state.userInfo.whether==1?(
                                    <div onClick={this.delDom=this.delDom.bind(this)} className="delDom">-</div>
                                ):null
                            }
                        </div> 
                        <InputItem
                            {...getFieldProps("dealer", {
                                rules: [
                                  {
                                    validator: this.validateDealer
                                  }
                                ],
                                initialValue: dealerPassword
                              })}
                            clear
                            placeholder="请输入经销商密码"
                            key="11"
                            maxLength="12"
                            type="password"
                            error={!!getFieldError("dealer")}
                            >
                            <i className="red-star">*</i>
                            经销商密码
                        </InputItem>
                        <List renderHeader="权限">
                            <CheckboxItem
                            {...getFieldProps("defaultCheckboxItem", {
                                rules: [
                                    {
                                        validator: this.validateCheckboxItem
                                    }
                                ],
                                initialValue: [checkboxItem]
                            })}
                            onChange={i => this.onChangeCheckBox(i, 1)}
                            checked={this.state.voice}
                            >
                            会话
                            </CheckboxItem>
                            <CheckboxItem
                            onChange={i => this.onChangeCheckBox(i, 2)}
                            checked={this.state.switchs}
                            >
                            切组
                            </CheckboxItem>
                            <CheckboxItem
                            onChange={i => this.onChangeCheckBox(i, 3)}
                            checked={this.state.single}
                            >
                            单呼
                            </CheckboxItem>
                            <CheckboxItem
                            onChange={i => this.onChangeCheckBox(i, 4)}
                            disabled={!this.state.single}
                            checked={this.state.groupSingle && this.state.single}
                            >
                            跨组单呼
                            </CheckboxItem>
                        </List>
                        </List>
                    <WhiteSpace />
                    <WhiteSpace />
                    <WingBlank>
                        <Button type="primary" onClick={() => this.submitBtn()}>
                            确定
                        </Button>
                    </WingBlank>
                </form>
            </div>
        </div>
    );
  }
}

const BindingIccidFrom = createForm()(BindingIccid);


export default connect(

)(BindingIccidFrom);
